import {useState, useEffect} from 'react'
import Cards from "../components/Card/Cards";  
import CategoryDropdownL1 from "../components/CategoryDropdownL1";
import Breadcrumb from 'rsuite/Breadcrumb';
import {NavLink} from "react-router-dom";
import useAuth from '../../Users/hooks/useAuth';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Login from '../../Users/Login';
import Register from '../../Users/Register';




const SoundGroups = () => {
    const { auth, setAuth } = useAuth();
    const [L1Lang, setL1Lang] = useState('Other')
    const [L2Lang, setL2] = useState('Norwegian')
    const [Categories, setCategories] = useState([])
    const [L2Num, setL2Num] = useState('');
    const [L1Num, setL1Num] = useState('');
    const L2List =  ["Norwegian", "English", "Italian", "Greek"];
    const [L1L2List, setL1L2List] = useState([]);
    const [AllSounds, setAllSounds] = useState([]);
    const [userLogin, setLogin] = useState(false);
    const [userRegister, setRegister] = useState(false);

    useEffect(()=>{
        fetch(`${process.env.REACT_APP_API_URL}/list_sounds`)
            .then(response=> response.json())
            .then(json=>{    
            var products = [];
            if (json.result.length > 0) {
                for (const lang of json.result) {
                      products.push(lang.ipa.trim())
                }
            }
           
            setAllSounds(products);     
            })          
    },[]) 

    useEffect(()=>{
        fetch(`${process.env.REACT_APP_API_URL}/list_languages`)
            .then(response=>response.json())
            .then(json=>{
                
                const filtered = json.result.filter(a => a.name.trim() === L1Lang.trim())
                if (filtered.length  === 1 ) {
                    setL1Num(filtered[0].pk);
                }
                const filteredL2 = json.result.filter(a => a.name.trim() === L2Lang.trim())
                if (filteredL2.length  === 1 ) {
                    setL2Num(filteredL2[0].pk);
                }
                
            
            })          
    },[L1Lang,L2Lang]) 


    useEffect(()=>{
        fetch(`${process.env.REACT_APP_API_URL}/${L2Lang}/list_categories`)
            .then(response=>response.json())
            .then(json=>{
                
                setCategories(json)
                
            
            })          
    },[L2Lang]) 


    useEffect(()=>{
        fetch(`${process.env.REACT_APP_API_URL}/compare?language_1_id=${L1Num}&language_2_id=${L2Num}`)
            .then(response=>response.json())
            .then(json=>{
                if (L1Num === L2Num) 
                { 
                    setL1L2List([])
                }
                else 
                {
                var products = [];
               if (json.result.length > 0) {
                    for (const lang of json.result) {
                      products.push(lang.trim())
                      }
                }
                
                setL1L2List(products);
                }
            })          
    },[L1Num,L2Num])  

    let navigate = useNavigate();
    const logout = () =>{
        setAuth({})
           
    }


    return (
        <div className="w-screen">
            <div className="flex flex-col md:flex-row justify-between items-center font-mono mx-auto  gap-3 pb-5 shadow-sm">
                 <div className="flex flex-col p-4 text-md gap-8 ">
                    <div className='flex flex-col md:flex-row'>
                        <span className="border-2 border-amber-500 border rounded-xl p-1">
                            {auth?.username
                                ? `${auth?.username}`
                                : "Not logged in"}
                        </span>
                        <div className="flex-none">
                            <ul className="menu menu-horizontal p-0">
                            {!auth?.username && (
                                <li className="mx-1">
                                 <button   className=" "
                                            onClick={() => {setLogin(true)}}
                                >
                                    Login
                                </button>
                                </li>
                            )}
                            {!auth?.username && (
                                <li className="mx-1">
                                <button   className=" "
                                            onClick={() => {setRegister(true)}}
                                >
                                Register
                                </button>
                                </li>
                            )}
                            {auth?.username && (
                                <li className="mx-1">
                                <button   className=" btn-warning"
                                            onClick={logout}
                                >
                                    Logout 
                                </button>
                                </li>
                            )}
                            </ul>
                        </div>
                    </div>
    {/*                <Breadcrumb  separator={'>>'} >
                        <Breadcrumb.Item>
                            <NavLink  style={({ isActive }) => ({
                                        color: isActive ? 'blue' : 'black',
                                        background: isActive ? 'transparent' : 'transparent',

                                    })} to="/">Home</NavLink>
                        </Breadcrumb.Item >

                        <Breadcrumb.Item>
                            <NavLink style={({ isActive }) => ({
                                    color: isActive ? 'blue' : 'black',
                                    background: isActive ? 'transparent' : 'transparent',
                                    fontWeight: isActive ? "inherit" : "",

                                })} to="/soundgroups"  >Sound groups</NavLink>  
                        </Breadcrumb.Item>
                            </Breadcrumb>*/}
                </div>
            
                <div className="flex flex-col sm:flex-row pr-10  gap-4">
                   <div>
                        <label  className="font-bold font-mono text-md text-center my-2" 
                                htmlFor="Level"
                        >
                            Choose L1:
                        </label>
                            <CategoryDropdownL1 
                                selectHandler={(event) => {
                                    setL1Lang(event.target.value);
                                }}
                                elValue={L1Lang}
                            />
                    </div>
                    <div>
                        <label  className="font-bold font-mono text-md text-center my-2" 
                                htmlFor="Level"
                        >
                            Choose L2:
                        </label>
                            <select value={L2Lang} 
                                    name="l2" 
                                    id="l2" 
                                    className="mr-2 ml-2 text-left px-2 text-black-500 bg-white border rounded-md 
                                                shadow-sm outline-none appearance-none focus:border-gray-600" 
                                    onChange={(event) => {  
                                        setL2(event.target.value)
                                    }}
                            >
                        
                        
                                {L2List.map(
                                    (les,i) => 
                                        {
                                            return (
                                                <option value={les} key={les}>
                                                    {les}
                                                </option>
                                            );
                                        }
                                    )
                                }           
                            </select>
                    </div>
                </div>
            </div>
                
            {userLogin && <Login  successHandler={(state) =>
                            {
                                setLogin(state);
                            }
                    } />}
            
            {userRegister && <Register  successHandler={(state) =>
                            {
                                setRegister(state);
                            }
                    } />}
            
            
            <div className='flex flex-col gap-y-10 pb-5'> 
                {   Categories.map(
                    (category,i) => 
                        {
                            return (
                                <div    key={category} 
                                        className='shadow-md'
                                >                   
                                    <Cards  L1L2List={L1L2List} 
                                            L2Lang={L2Lang} 
                                            AllSounds={AllSounds} 
                                            category={category} 
                                    /> 
                                </div>
                            );
                        }
                    )
                }        
            </div>
            
            
        </div>
    )
}

export default SoundGroups;