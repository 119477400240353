import {useState, useEffect} from 'react'
import DisplayTrackMPNorwegian from './DisplayTrack/DisplayTrackMPNorwegian'
import Tabs from './PlayerTabs'
import Paginator from '../../../Paginator'
const CardPlayerNorwegian = ({id, l2, change}) => {
    const path = process.env.REACT_APP_API_URL +'/exercise_raw/Norwegian/' + id
    const [PlayList, setPlayList] = useState([])
    const [info, setInfo] = useState({})
    const [pageMP,setPageMP] = useState(1)
    const NumberPairs = 5
    const [chunkPlayListMP, setChunkPlayListMP]  = useState([])  
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [tabs, setTabs] = useState([])
    
    
    useEffect(()=>{
        const tabsData = [  
                            {label: 'Oslo'},
                            {label: 'Elverum'},
                            {label: 'Trondheim'},
                            {label: 'Malm'},
                            {label: 'Stavanger'},
                            {label: 'Bergen'},
                            {label: 'Sandnessjøen'},
                            {label: 'Tromsø'},
                        ];
        fetch(`${process.env.REACT_APP_API_URL}/norwegian/exercise/${id}`)
            .then(response=>response.json())
                .then(json=>{
                    let info_json = {"name": json.Exercise_name, "group": json.Group_lesson, "type": json.Type_description}
                    setInfo(info_json)
                    var dialects = [];
                    var tab_dialect = []
                    for ( const dia of tabsData) {
                        var dialect = []
                        for (const lang of json.MP_wordpairs) {
                            if (lang[0]['audio_'+dia.label].length > 0 && lang[1]['audio_'+dia.label].length > 0)
                            {

                            dialect.push({"pair_0":{"track_0":lang[0]['audio_'+dia.label], "word": lang[0].word, "translation": lang[0].Translation[lang[0].Translation_description.findIndex(x => x ==='en')] }, "pair_1":{"track_0":lang[1]['audio_'+dia.label],  "word": lang[1].word, "translation": lang[1].Translation[lang[1].Translation_description.findIndex(x => x ==='en')]}})
                            }
                        }

                        if (dialect.length > 4)
                        {
                            tab_dialect.push(dia)
                            dialects.push(dialect)
                        }
                        
                        
                    }
                    setPlayList(dialects)
                    setTabs(tab_dialect)    

                     })
                 
    },[id,l2]) 

    useEffect(()=> { 
        change(tabs[activeTabIndex])
        if (PlayList[activeTabIndex]){
            if(PlayList[activeTabIndex].length > NumberPairs) 
            {
                setChunkPlayListMP(PlayList[activeTabIndex].slice((pageMP-1)*NumberPairs,pageMP*NumberPairs))  
            }
            else 
            {
                setChunkPlayListMP(PlayList[activeTabIndex])
            }

    }

    
    },[pageMP, PlayList,activeTabIndex, tabs])


    
  return (
       


            chunkPlayListMP.length > 0 ? 
            <div className="flex flex-col flex-grow">  
                <div 
                    className='flex flex-col px-2  bg-FarmWhite rounded-md transition  items-center ease-in-out'
                >
                    <a  href={path} 
                        target="_blank" 
                        rel="noreferrer"
                    >
                        <div className="pb-2 font-bold text-lg text-blue-600 my-1"
                        >
                            {info.name}
                        </div>
                    </a>                     
                </div>
                
                <div className='flex flex-col-2 px-2'>
                    <div className="mr-3 py-10 scale-75 md:scale-100">
                        <Tabs 
                            clickHandler={(tab_index) =>
                                    {
                                        setPageMP(1);
                                        setActiveTabIndex(tab_index);
                                        change(tabs[tab_index])
                                    }
                            } 
                            tabsData={tabs} 
                        />
                    </div>

                    <div className="flex flex-col grow py-3 px-2 border-dotted border-2 border-indigo-600  
                                    shadow-md  bg-FarmWhite rounded-xl transition 
                                    items-center ease-in-out mt-2">
                            
                            

                        <div className="w-full"> 
                            {(() => {
                                
                                    return (                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
                                        <div className="bg-FarmWhite rounded-lg transition  items-center  
                                                        ease-in-out"
                                        >
                                            
                                            <div className="font-semibold text-sm text-center p-2  
                                                            font-serif:'Times New Roman' text-lg text-FarmNavy 
                                                            bg-FarmWhite rounded-lg"
                                            > 
                                                Minimal Pairs
                                            </div>

                                            {chunkPlayListMP.map(
                                                (item,i) => {
                                                    return (
                                                            <div key={item.pair_0.word+i+activeTabIndex}><DisplayTrackMPNorwegian  currentTrack={item} /> </div> 
                                                        
                                                            )                                                                                                                                                                                                    
                                                }
                                            )}
                                        </div>
                                    )
                            })()}                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
                        </div>

                            <Paginator  maxpage={Math.ceil(PlayList[activeTabIndex].length/NumberPairs)} 
                                        page={pageMP}
                                        clickHandler={
                                            (page) => {setPageMP(page);}
                                                    }
                            />
            
                    </div>
                </div>     
            </div>  
            :
            <div>loading...</div>
  
                                            
    )
}

export default CardPlayerNorwegian;
