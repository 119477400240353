import CategoryDropdownL2 from "../course/components/CategoryDropdownL2";
import ActivitiesInterface from "./ActivitiesInterface";
import Selector from "./Components/Selector";
import useAuth from "./hooks/useAuth";
import { useEffect, useState } from "react";
import NewAssignment from "./NewAssignment";

const TeacherInterface = ({successHandler, teacher = ""}) => {
    const {auth, setAuth} = useAuth();
    const [classes, setClasses] = useState([]);
    const [newTag, setTag] = useState("");
    const [newLang, setLang] = useState("Catalan (Central)");
    const [changeList, setChangeList] = useState([]);
    const [changedTags, setChangedTags] = useState([]);
    const [student, setStudent] = useState("");
    const [currentClass, setClass] = useState("");
    const [students, setStudents] = useState([]);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(0);
    const tabs = ['Class management', 'User activities'];
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [requests, setRequests] = useState([]);
    const [assignmentPage, setNewAssign] = useState(false);
    const [assignments, setAssignments] = useState([]);
    const [newClassError, setNewClasserror] = useState("");

    const fetchData = async (route, method) => {
        const token = localStorage.getItem("token");
        const url = `${process.env.REACT_APP_USER_API_URL}/teacher/${route}` + ((teacher !== "") ? `?teacher_name=${teacher}` : "")
        const response = await fetch(url, {
            method: method,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });
        if (response.status === 401) {
            localStorage.removeItem("token");
            setAuth({});
            return null;
        }
        if (response.ok) {
            const json = await response.json();
            return json;
        }
        return null
    }

    const createClass = async (tag, lang) => {
        await fetchData(`create-class/${tag}/${lang}`, "POST");
    }

    const getClasses = async () => {
        const json = await fetchData("taught-classes", "GET");
        if (json) {
            setClasses(json);
        }
    }

    const deleteClass = async (tag) => {
        await fetchData(`delete-class/${tag}`, "DELETE");
    }

    const updateTag = async (oldTag, newTag) => {
        await fetchData(`update-tag/${oldTag}/${newTag}`, "POST");
    }

    const addStudent = async (tag, name) => {
        await fetchData(`add-user/${tag}/${name}`, "POST");
    }

    const getStudents = async () => {
        if (currentClass !== "") {
            const json = await fetchData(`students/${currentClass}/${page}`);
            if (json) {
                setStudents(json);
                getPages();
            }
        }
    }

    const getPages = async () => {
        const json = await fetchData(`pages/${currentClass}`, "GET");
        if (json) {
            setPages(json.pages);
        }
    }

    const removeStudent = async (tag, name) => {
        await fetchData(`remove-user/${tag}/${name}`, "DELETE");
    }

    const getRequests = async () => {
        const json = await fetchData("requests", "GET");
        if (json) {
            setRequests(json);
        }
    }

    const acceptRequest = async (request) => {
        const json = await fetchData(`accept-request/${request.tag}/${request.username}`, "POST");
        if (json) {
            getRequests();
            getStudents();
        }
    }

    const denyRequest = async (request) => {
        const json = await fetchData(`deny-request/${request.tag}/${request.username}`, "POST");
        if (json) {
            getRequests();
        }
    }

    const getAssignments = async () => {
        if (currentClass !== "") {
            const json = await fetchData(`assignments/${currentClass}`, "GET");
            if (json) {
                setAssignments(json);
                console.log(json);
            }
        }
    }

    const deleteAssignment = async (tag, title) => {
        await fetchData(`assignment/${tag}/${title}`, "DELETE");
    }

    const commit = async () => {
        for (const change of changeList) {
            const splits = change.action.split(" ");
            switch (splits[0]) {
                case "DELETE":
                    if (splits.length === 1) {
                        await deleteClass(change.class);   
                    } else {
                        await deleteAssignment(change.class, splits[1]);
                    }
                    break;
                case "CREATE":
                    await createClass(change.class, splits.slice(1).join(" "));
                    break;
                case "RENAME":
                    await updateTag(change.class, splits[1]);
                    break;
                case "ADD":
                    await addStudent(change.class, splits[1]);
                    break;
                case "REMOVE":
                    await removeStudent(change.class, splits[1]);
                    break;
                
            }
        }
        setChangeList([]);
        setChangedTags([]);
        getClasses();
        getStudents();
        getAssignments();
    }

    const removeChange = (i) => {
        let array = [...changeList];
        array.splice(i, 1);
        setChangeList(array);
    }

    const addChange = (change) => {
        setChangeList([...changeList, change]);
    }

    const addCreateClass = (event) => {
        event.preventDefault();
        setNewClasserror("");
        if (classes.map(course => course.tag).includes(newTag)) {
            setNewClasserror("Class with this name already exists.");
        } else if (newTag === "") {
            setNewClasserror("Name for class required");
        } else {
            addChange({
                class: newTag,
                action: `CREATE ${newLang}`
            });
        }
        setTag("");
    }

    const addDeleteClass = (tag) => {
        addChange({
            class: tag,
            action: "DELETE"
        });
    }

    const changeTag = (i, tag) => {
        let arr = changedTags.filter((el) => el.index !== i);
        arr.push({ index: i, tag: tag });
        setChangedTags(arr);
    };

    const addUpdateTag = (event, i, oldTag) => {
        event.preventDefault();
        addChange({
            class: oldTag,
            action: `RENAME ${changedTags.find((el) => el.index === i).tag}`
        });
    }

    const addAddStudent = (event) => {
        event.preventDefault();
        addChange({
            class: currentClass,
            action: `ADD ${student}`
        });
        setStudent("");
    }

    const addRemoveStudent = (name) => {
        addChange({
            class: currentClass,
            action: `REMOVE ${name}`
        });
    }

    const addDeleteAssignment = (title) => {
        addChange({
            class: currentClass,
            action: `DELETE ${title}`
        });
    }

    useEffect(() => {getClasses()}, []);
    useEffect(() => {getRequests()}, []);
    useEffect(() => {getStudents()}, [currentClass, page]);
    useEffect(() => {getAssignments()}, [currentClass, assignmentPage]);

    if (assignmentPage) {
        return <NewAssignment successHandler={state => setNewAssign(state)} tag={currentClass} teacher={teacher} />
    }

    return (
        <div className="flex flex-col">

            <button className="border-0"
                onClick={()=> {successHandler(false)}}      
            ><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" /></svg>
            </button>
            <div>
                {teacher === "" &&
                    <Selector
                        clickHandler={(tab_index) =>
                            {
                                setActiveTabIndex(tab_index);
                            }
                        } 
                        tabsData={tabs} 
                        active = {activeTabIndex}
                    />
                }
            </div>
            
            {tabs[activeTabIndex] === "Class management" &&
                <div className="flex flex-row mx-auto p-5">
                    <div className="flex flex-col">
                        <p className="mx-auto font-semibold text-xl text-blue-400">Create new class</p>
                        <form className="flex flex-col border-2 border-gray-200 shadow mx-auto p-5 rounded-md ">
                            <div className="flex flex-row gap-4 items-center">
                                <p>Choose your language course:</p> 
                                <CategoryDropdownL2 selectHandler={e => setLang(e.target.value)} elValue={newLang} />
                        
                            </div>
                        
                            <div className="flex flex-row gap-4 mt-2 items-center">
                                <p>Name your class/group</p>
                                <div className="mx-auto border-2 border-gray-200">
                                    <input className="p-2" type="text" placeholder="Group/class/topic name" value={newTag} onChange={e => setTag(e.target.value)} />
                                </div>
                            </div>
                            {newClassError !== "" && newClassError}
                            {/* Button has to be in the form to prevent getting kicked off the page */}
                            <button className="p-2 mx-auto mt-4 rounded-md border-2 border-gray-400 hover:bg-green-100 bg-gray-100 text-blue-500" onClick={addCreateClass}>Create class</button>

                        </form>

                        {(changeList?.length>0)&&
                            <div className="flex flex-col mx-auto border-2 border-gray-200 bg-gray-50 p-5 my-5">
                                {changeList.map((change, i) => {
                                    return (<div className="grow flex flex-row gap-2 mx-auto">
                                        <div className="text-blue-400 text-xl">{change.class}</div>
                                        <div className="text-md text-gray-400">{change.action}</div>
                                        <button className="border-2 border-brown-500 bg-brown-100 text-red-400 font-semibold p-1" onClick={() => {removeChange(i)}}>Remove</button>
                                    </div>)
                                })}
                                <button onClick={commit}>Commit</button>
                            </div>
                        }

                        <div className="mx-auto text-blue-400 text-xl mt-2">List of classes</div>
                        <div className="grid  grid-cols-6 items-center border-2 border-gray-200 shadow-xl mx-auto my-2 p-2 gap-2">

                            {classes.map((course, i) => {
                                return (
                                    <> 
                                        <div>
                                            <form>
                                                <input className="border-2 border-gray-200 p-2" type="text" defaultValue={course.tag} onChange={(e) => changeTag(i, e.target.value)} />
                                            
                                            </form>
                                        </div>
                                        <div>/{course.lang}</div>
                                        {changedTags.map(change => change.index).includes(i) &&
                                            <button className="border-2 border-gray-200 bg-gray-100 text-sm " onClick={(e) => addUpdateTag(e, i, course.tag)}>Update name</button>
                                        }
                                        {!changedTags.map(change => change.index).includes(i) &&
                                            <div />
                                        }
                                        <button className="border-2 border-teal-500 bg-teal-50 rounded-md" onClick={() => setClass(course.tag)}>Info</button>
                                        <button className="border-2" onClick={() => {setClass(course.tag); setNewAssign(true);}}>New assignment</button>
                                        <button className="border-2 border-red-500 bg-red-100 text-sm font-semibold text-red-400" onClick={() => addDeleteClass(course.tag)}>Delete</button>
                                    </>
                                )
                            })}
                        </div>
                        {currentClass !== "" &&
                            <div className="flex flex-row justify-around">
                                <div className="flex flex-col border border-4">
                                    <b>{currentClass} students</b>
                                    <form className="flex flex-row">
                                        <input className="border" type="text" placeholder="Username/email" value={student} onChange={e => setStudent(e.target.value)}/>
                                        <button onClick={e => addAddStudent(e)}>Invite</button>
                                    </form>
                                    <div className="flex flex-col">
                                        <div className="flex flex-row">
                                            <input type="number" defaultValue={page} min={1} max={pages} onChange={(e) => setPage(e.target.value)}/>
                                            <div>/{pages}</div>
                                        </div>
                                        {students.map((student, _) => {
                                            return <div className="flex flex-row justify-between">
                                                <div>{student}</div>
                                                <button onClick={() => addRemoveStudent(student)}>Remove</button>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            
                                <div className="flex flex-col border border-4">
                                    <b>{currentClass} assignments</b>
                                    {assignments.map(assignment => {
                                        return <div className="flex flex-row">
                                            <div>{assignment}</div>
                                            <button onClick={() => addDeleteAssignment(assignment)}>Delete</button>
                                        </div>
                                    })}
                                </div>
                            </div>
                        }
                    </div>
                    {requests.length !== 0 &&
                        <div className="flex flex-col border border-4">
                            <b>Requests</b>
                            {requests.map(request => {
                                return <div className="flex flex-row justify-between">
                                    <div>{request.username}</div>
                                    <div>{request.tag}</div>
                                    <button onClick={() => acceptRequest(request)}>Accept</button>
                                    <button onClick={() => denyRequest(request)}>Deny</button>
                                </div>
                            })}
                        </div>
                    }
                </div>
            }
            {tabs[activeTabIndex] === "User activities" &&
                <ActivitiesInterface tabs={classes.map(course => course.tag)} />
            }
        </div>
    )
}

export default TeacherInterface;