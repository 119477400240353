import DisplayTrackMP from "./DisplayTrack/DisplayTrackMP"
import DisplayTrackNW from "./DisplayTrack/DisplayTrackNW"
import DisplayTrackLS from "./DisplayTrack/DisplayTrackLS"
import { useEffect } from "react"
import useAuth from "../../../../../Users/hooks/useAuth"
const Summary= ({ sum, backToExercises, info, parts }) => {
    console.log('JOY: summary, exercise, level 2')
    const {auth, setAuth} = useAuth();
    useEffect( 
        () =>{
                const onLastHit = async (data) => {
                        
                    
                            data["id"] = info.id
                        
                            data['parts'] = parts
                            console.log(data, "data")
                            let outdata = {"activity" : {[info.name]:data}}
                            if (auth) {outdata["email"] = auth.email}
                            const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/stats/${info.l2}/send`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(outdata),
                            });
                            
                            // if the login is successful - get the token and then get the remaining data from the /me route
                            if (response.ok) {
                                console.log("sent data")
                            
                                };
                        }
                        if (Object.keys(sum).length >0){
                            onLastHit(sum)}
                },[sum]
            )


return (
<div className="flex flex-col shadow-xl p-2">
    <p className="m-auto font-bold text-2xl">Results</p>

{(Object.keys(sum).length ===0)&&<div>No exercises completed</div>}
{(Object.keys(sum).length >0)&&Object.keys(sum).map(k => {
    return (
    <div className="flex flex-col">
    <div key={k} className="m-auto font-medium py-2"><p>{k}</p></div>

    {Object.keys(sum[k].results).map(el => {
        return(
            <>
            <div className={`flex flex-col rounded-xl ${(sum[k].results[el].eval === true || sum[k].results[el].eval === 'Good' )  ? "bg-green-400" : "bg-red-400"  } `}>
                
                {(k === "ListenAndClick")&&<DisplayTrackMP currentTrack={sum[k].results[el].choice} />}
                {(k === "ListenAndClickII")&&<DisplayTrackNW currentTrack={sum[k].results[el].target} />}
                {(k === "ListenAndSpeak")&&<DisplayTrackLS currentTrack={sum[k].results[el].choice} />}
                {(k === "ListenAndWrite")&&<div className="flex flex-col m-auto">
                        <div className="flex">
                             <p className="text-md">You typed: {sum[k].results[el].typed}</p>
                        </div>
                        <DisplayTrackLS currentTrack={sum[k].results[el].choice} />
                        </div>}
                
            </div>
            </>
        )
    }
    )
    }
    </div>
    )
    }
    

    )

}


<div className="flex flex-col py-2">
<button className="flex m-auto bg-gray-200 hover:bg-blue-200 rounded-xl px-2 py-2" onClick={() => {backToExercises(false);}}>back</button>
</div>
</div>
)
}


export default Summary;