
import { Tooltip} from "@material-tailwind/react";
import { useMemo, useRef } from "react";

const DisplayTrackNW = ({ currentTrack }) => {
  const frames = [ { color: "red" },
  { transform: "scale(1.05)", color: "red" },
  { transform: "translateY(-10%)", color: "red" }, 
  { transform: "translateY(-30%)" , color: "red"}, 
  { transform: "scale(1.2)" , color: "red"},
  { transform: "translateY(-10%)" , color: "red"},
  { transform: "translateY(0%)" , color: "red"}, 
  { transform: "scale(1.2)", color: "red" },
  { transform: "scale(1.0)" , color: "inherit"},
  ]
  const inputRef_0 = useRef(null);

  let audio_0 = new Audio(currentTrack.audio);
  const Playit_0 = () => {
    setTimeout(() => {
      inputRef_0.current.animate(  
        frames,{ duration: audio_0.duration*1000 , fill: "forwards", iterations: 1 } 
      );
      audio_0.play();
      }, 2)
    
  };

  
  
  return (
    <div className="w-full flex flex-cols-3">
      
      <Tooltip className="flex flex-col bg-indigo-300 font-medium text-white rounded-full px-3"
            animate={{
              mount: { scale: 1.1, y: 0 },
              unmount: { scale: 0, y: 25 },}} 
             content={currentTrack.translation}
             > 
         
       
         <button className="basis-2/5 bg-transparent hover:bg-indigo-100 hover:shadow-xl text-sm hover:border rounded-full font-serif:Times font-semibold text-gray-600 hover:text-blue-600" onClick={Playit_0}>
          <p ref={inputRef_0}>{currentTrack.word}</p>

        </button>
        
      </Tooltip>
      
      <button className="basis-1/5 bg-transparent text-gray-600 hover:text-red-600 font-bold py-3 text-sm" onClick={Playit_0}>
        <p>&#8883;</p>
      </button>
      <Tooltip className="flex flex-col bg-indigo-300 font-medium text-white rounded-full px-3"
            animate={{
              mount: { scale: 1.1, y: 0 },
              unmount: { scale: 0, y: 25 },}} 
             content={currentTrack.nonword_translation}
            > 
        
        <button className="basis-2/5 bg-transparent hover:bg-indigo-100 hover:shadow-xl  text-sm hover:border rounded-full font-serif:Times font-semibold text-gray-400 hover:text-blue-600">
         {currentTrack.nonword}
        </button>
        
        </Tooltip>
      
    </div>
  );
};
export default DisplayTrackNW;