import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import PrivacyPolicy from './course/pages/PrivacyPolicy';
import Review from './overview/pages/Review';
import Overview from './overview/pages/Overview';
import Entry from './course/pages/Entry';

import TabPlayer from './course/pages/NewTabExercise/TabPlayer';
import TabPlayerNorwegian from './course/pages/NewTabExercise/TabPlayerNorwegian';
import TabPlayerEnglish from './course/pages/NewTabExercise/TabPlayerEnglish';

import TargetSound from './soundgroupsv1/pages/TargetSound';
import SoundGroups from './soundgroupsv1/pages/SoundGroups';
import Lessons from './lessons/pages/Lessons';
import Course from './course/components/Course';


import Login from './Users/Login';
import Register from './Users/Register';
import Verify from './Users/Verify';
import ForgotPassword from './Users/ForgotPw';
import ResetPw from './Users/ResetPw';
import AccessInterface from './Users/AccessInterface';
import UserSettings from './Users/UserSettings';

import './index.css';

import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/AuthProvider';
import { ActivityProvider } from './context/ActivityProvider';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  
    <BrowserRouter>
      <AuthProvider>
        <ActivityProvider>
          <Routes>
            <Route path="/" element={<Entry />} />
            <Route path="overview" element={<Overview />} />
            <Route path="policy" element={<PrivacyPolicy/>}/>
            
            <Route path="soundgroups" element={<SoundGroups />} />
            <Route path="lessons" element={<Lessons />} />
            <Route path="course" element={<Course />} />
            
            <Route path="login" element={<Login/>} />
            <Route path="register/:teacher/:tag" element={<Register/>} /> 
            <Route path="verify/:id/:code" element={<Verify/>}/>
            <Route path="forgotpassword" element={<ForgotPassword/>}/>
            <Route path="resetpassword/:id/:code" element={<ResetPw />}/>
            <Route path="accessinterface" element={<AccessInterface />}/>
            <Route path="usersettings" element={<UserSettings />}/>
      
            <Route path="targetsound/:l2/:sound" element={<TargetSound />} />
            <Route path="review/:l2/:id" element={<Review />} />
            <Route path="player/:name/:l2/:id" element={<TabPlayer />} />
            <Route path="norwegianplayer/:id" element={<TabPlayerNorwegian />} />
            <Route path="englishplayer/:id" element={<TabPlayerEnglish />} />
            
            
          </Routes>
          </ActivityProvider>
      </AuthProvider>
    </BrowserRouter>    

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
