import React from 'react'
import { Tooltip} from "@material-tailwind/react";
import useActivity from "../../../../Users/hooks/useActivity";
import { CircularProgressbarWithChildren, CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useEffect, useState } from "react";



const CardSoundPositions = ({exercise, include, posExerciseHandler}) => {
  
  const {activity} = useActivity();
  
  const [percentage, setPercentage] = useState({}) 

  useEffect(()=>{
    let currentPer = {}
    exercise.map((el)=>{
    
      if (el.Exercise_name in activity)
          {currentPer[el.Exercise_name] = activity[el.Exercise_name]['fulfilled']}
      else
      {currentPer[el.Exercise_name] = 0}
      
      })
      setPercentage(currentPer)
       
    },[activity]
  )
 
  /* design of circles */

  const dummy = [...Array(9)]
  
  const positions = [3,4,5]

  const colorcodes = ["deepskyblue", "cornflowerblue", "aquamarine", "aliceblue", "azure", "darkturquoise","cadetblue", "dodgerblue" , "aqua","blueviolet", "fuchsia", "lavender", "lightsteelblue", "mediumpurple", "yellowgreen"]
  
   
  if ( exercise.length === 2 )
  {
    for (const item of exercise ) 
    {
      if (item.Position === 'final')
      {
        dummy[5] = item
      }
      else if (item.Position === 'initial')
      {
        dummy[3] = item
      }
      else
      {
        dummy[4] = item
      }
      
    }
  }

  if ( exercise.length === 3 )
  {
    for (const item of exercise ) 
    {
      if (item.Position === 'final')
      {
        dummy[5] = item
      }
      
      else if (item.Position === 'initial')
      {
        dummy[3] = item
      }
      else
      {

        dummy[4] = item
      }
     
    }
  }
 
 

    return(
            <div  className=" py-12 scale-75 2xl:scale-100 grid grid-cols-3 gap-2 bg-farmWhite">
              {dummy.map(
                (el, index)=>{ 
                  
                  return (
                     
                      el ? 
                              
                      <Tooltip  placement="bottom" 
                                className="flex flex-col bg-blue-700 font-medium text-white rounded-full px-3"
                                animate={{
                                mount: { scale: 1.4, y: 0 },
                                unmount: { scale: 0, y: 25 },}} 
                                content={String(el.MP_wordpair[0].word)+"-"+String(el.MP_wordpair[1].word)}
                      >
                        <div  className='basis-1/3' 
                              key={String(index) + String(el.Exercise_name)}  
                              onClick={() => {posExerciseHandler(el)} } 
                        >
                          <button>
                            <CircularProgressbarWithChildren  value={percentage[el.Exercise_name]}
                                                              background
                                                              backgroundPadding={6}
                                                              styles={buildStyles({
                                                                backgroundColor: include? String(colorcodes[positions.indexOf(index)])  : 'lightgray' ,
                                                                textColor: "darkgray",
                                                                pathColor: "greenyellow",
                                                                trailColor: "transparent",
                                                                textSize: "14px",
                                                              })}
                                                              
                  
                            > 
                              { el.Exercise_name }
                            </CircularProgressbarWithChildren>
                          </button>         
                        </div>
                      </Tooltip>

                              
                          : 
                              <div  key={String(index) + 'dummy'}  className='basis-1/3' >
                              <button className="bg-transparent border  border-transparent hover:border-transparent rounded-full">
                                  
                              </button>
                              </div>
                              
                              )
                            }
                
              )}
            </div>    
    ) 
}
export default CardSoundPositions;
