import { Tooltip} from "@material-tailwind/react";


const CardSoundGroupButtonNWAudio = ({currentTrack, include, color, title}) => {
    let choices =[`${process.env.REACT_APP_STATIC_URL}`+currentTrack.audio_0.sort(() => 0.5 - Math.random()).slice(0,1), `${process.env.REACT_APP_STATIC_URL}`+currentTrack.audio_1.sort(() => 0.5 - Math.random()).slice(0,1)]
    let choice = choices.sort(() => 0.5 - Math.random()).slice(0,1)[0]
    let audio_0 = new Audio(choice)
    
    const Playit_0 = () => {
        audio_0.play();
        
    };
    const Stopit_0 = () => {
        audio_0.pause();
        audio_0.currentTime = 0;
        
    };

    return (
        
            <Tooltip placement="bottom" className="flex flex-col bg-blue-700 font-medium text-white rounded-full px-3"
                animate={{
                mount: { scale: 1.4, y: 0 },
                unmount: { scale: 0, y: 25 },}} 
                content={
                String(currentTrack.word)+ "-*"+String(currentTrack.nonword) }>
                <button className="shadow-xl text-blue-700 font-medium text-2xl hover:text-3xl  py-2 px-4 border w-16 h-16 border-blue-500 hover:border-blue-600 rounded-full hover:bg-green-500" 
                style={ include ? {backgroundColor : color }:{backgroundColor : "lightgray"} }         
                onMouseEnter={Playit_0} onMouseLeave={Stopit_0}>
                {title} 
                </button>
            </Tooltip> 
    )
}
export default CardSoundGroupButtonNWAudio;