import { useMemo, useRef, useState, useEffect } from "react";
import 'animate.css'

const SoundAnimation = ({playState}) => {

const soundWaveStyle = `transform scale-y-40 h-2/3 w-[var(--boxSize)] bg-orange-800 rounded-lg ${playState? 'opacity-100 ' : 'opacity-50'} `

  return (
    <div>
      <div className="flex flex-row justify-between items-center h-14 w-16" style={{ '--boxSize': '8px', '--gutter': '4px' }}>
        <div className={`
          ${soundWaveStyle}
          ${playState? 'animate-quiet' : ''} `}></div>
        <div className={`
          ${soundWaveStyle}
          ${playState? 'animate-loud' : ''}`}></div>
        <div className={`
          ${soundWaveStyle}
          ${playState? 'animate-quiet' : ''} `}></div>
        <div className={`
          ${soundWaveStyle}
          ${playState? 'animate-loud' : ''} `}></div>
        <div className={`
          ${soundWaveStyle}
          ${playState? 'animate-quiet' : ''} `}></div>
      </div>
    </div>
  );
}

const ListenAndWrite= ({ tracks, showResultsHandler, resultsHandler, progress, setProgress, tab }) => {
  const [track, setTrack] = useState(1)
  const [buttonState, setButtonState] = useState(false) // false. pair buttons are disabled when false
  const [playState, setPlayState] = useState(false)
  const [results, setResults] = useState({'results': {}, 'value': []})
  const [start, setStart] = useState(false)
  const [compared, setCompared] = useState(false)

  const [registerHit, setRegisterHit] = useState(false)
  const [scores, setScores] = useState(progress[tab] ? progress[tab] : [])
  const [text, setText] = useState('')

  console.log(tracks, "tracks LW")

  const inputRef = useRef(); // For the input field
  

  //  For animating the pair buttons when playing
  const [animateRight, setAnimateRight] = useState(false)
  const [animateWrong, setAnimateWrong] = useState(false)
  let correct = `animate__animated animate__tada bg-green-500 border-green-700 shadow-lg text-white`	
  let wrong = `animate__animated animate__shakeX bg-red-500 border-red-700 shadow-lg text-white`

  console.log(results.value, "res.val")
 

  // Tracks and audios
  let choice = tracks[track-1]
  let word = choice.word;
  let audio_0 = useMemo(() => new Audio(choice.audio),[choice]);
 

  const handleSubmit = (event) => {
    event.preventDefault();
    setCompared(true)
    setRegisterHit(true)
    
    if (inputRef.current.value.toLowerCase() === word.toLowerCase()){
      if (!results.results[track]){
        results.results[track] = {"eval": true, "choice": tracks[track-1], "typed": inputRef.current.value }
        results.value.push(true)
       
        setResults(results)
        
        
      }

      setAnimateRight(true)
      setScores([...scores, 1])
    
    }
    else
    {
      if (inputRef.current) {
        if (!results.results[track]){
          results.results[track] = {"eval": false, "choice": tracks[track-1], "typed": inputRef.current.value }
          results.value.push(false)
         
          setResults(results)
        }
        
        setAnimateWrong(true)
        setScores([...scores, 0])
      }

    }


    // Automatic play next track
    setTimeout(() => {
      if (track < tracks.length)
        {
        setTrack(track+1);
        setButtonState(false);
        setRegisterHit(false);
        setPlayState(true);

        setAnimateRight(false);
        setAnimateWrong(false);
        setText('')
        
        }
        else
        {
          showResultsHandler(true)
          resultsHandler(results)
        }
      }, 3000)
  }

  // Tracks the scorers for the exercise
  useEffect(() => {
    setProgress(prevProgress => {
      const newProgress = [...prevProgress]
      newProgress[tab] = scores
      return newProgress
    })
  },[scores])


  

  useEffect(()=>{
    
    setCompared(false);
    if (inputRef.current){
      inputRef.current.value ="";
    
    }
    let mounted = true
    
    
    const handleCanPlayThrough = () => {
      var audio_duration = audio_0.duration ? audio_0.duration : 1
      audio_0.removeEventListener('canplaythrough', handleCanPlayThrough);
      console.log('Audio can be played through.');
      if (mounted)
      {
      setTimeout(() => {
        audio_0.play();
        
      }, audio_duration ) 
      }
      
    };

    if (playState === true ) {
      
      setTimeout(()=> {
        
        audio_0.addEventListener('canplaythrough', handleCanPlayThrough);
        audio_0.addEventListener("ended", ()=>{
          
            setButtonState(true); 
            setPlayState(false)
          })
        
        audio_0.currentTime = 0; 
        
        
      }  , 300);
    }

    else {
      if (inputRef.current){
        inputRef.current.focus()};
    }
    
    return () => {
      mounted = false
    
    }
  },[track,  playState])


 

  
  return (
    <div className="flex flex-col pt-10 items-center pb-20 ">
   
      {/* PLAY BUTTON */}
      <div className='mx-auto '>
          <button 
            disabled={playState || registerHit}
            className={`bg-pygblue rounded-3xl p-8 border-b-4 shadow-md
              ${playState || registerHit
                ? "opacity-40 cursor-default border-transparent" 
                : "hover:bg-blue-500  opacity-100 border-blue-700 active:translate-y-1 active:ease-in-out active:shadow-xl "}
              `}
            
            onClick={() => {setPlayState(true); setStart(true)}}
          >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.00 32.00" fill="white"
                className={`w-12 h-12 `}
                >
                  <path d="M16,6v20c0,1.1-0.772,1.537-1.715,0.971l-6.57-3.942C6.772,22.463,5.1,22,4,22H3c-1.1,0-2-0.9-2-2 v-8c0-1.1,0.9-2,2-2h1c1.1,0,2.772-0.463,3.715-1.029l6.57-3.942C15.228,4.463,16,4.9,16,6z M26.606,5.394 c-0.781-0.781-2.047-0.781-2.828,0s-0.781,2.047,0,2.828C25.855,10.3,27,13.062,27,16s-1.145,5.7-3.222,7.778 c-0.781,0.781-0.781,2.047,0,2.828c0.391,0.391,0.902,0.586,1.414,0.586s1.023-0.195,1.414-0.586C29.439,23.773,31,20.007,31,16 S29.439,8.227,26.606,5.394z M22.363,9.636c-0.781-0.781-2.047-0.781-2.828,0s-0.781,2.047,0,2.828C20.479,13.409,21,14.664,21,16 s-0.52,2.591-1.464,3.535c-0.781,0.781-0.781,2.047,0,2.828c0.391,0.391,0.902,0.586,1.414,0.586s1.023-0.195,1.414-0.586 C24.064,20.664,25,18.404,25,16S24.063,11.336,22.363,9.636z"></path>
              </svg>
          </button>
      </div>

      {/* Sound and input wrapper */}
      <div className={`
        flex flex-col justify-center items-center text-center 
        ${
          start
          ? "opacity-100"
          : "opacity-40"
        }
      `}>

        {/* If the answer is checked, show the right answer and its translation. */}
        <div className={`w-full h-32 flex flex-col items-center justify-center mb-3 `}>

          {compared ? (
            
            <div className="w-full flex flex-col justify-center items-center text-center">
              {/* Target word */}
              <p className={`m-auto px-5 pb-1 pt-3 mb-2 font-bold text-3xl text-pygblue
                `}>
              {word}
              </p>

              {/* Translation */}
              <p className="border-t-2 m-auto px-16 pt-1 text-gray-500">{choice.translation}</p>
            </div>

          ) : (

            // Else, show soundAnimation
            <SoundAnimation playState={playState} />


          )}
            
        </div>
          
        <div className="flex flex-col items-center justify-center h-full">
          <form className={`w-full max-w-md flex items-center border-b border-b-2 border-pygblue py-1
            ${ !playState && !registerHit? 'opacity-100' : 'opacity-50'} 
            
            
            `}
            onSubmit={handleSubmit}
            >
              
              <input  
                className={` appearance-none w-full mr-3 py-3 px-2 leading-tight focus:outline-none
                  rounded-full
                  ${ animateRight ? correct : '' }
                  ${ animateWrong ? wrong : '' }
                  ${ !animateRight && !animateWrong ? 'bg-transparent text-gray-700 border-none' : '' }
                  `}
                id="name" 
                type="text"  
                disabled={!buttonState || registerHit}
                ref={inputRef} 
                placeholder="Your answer here..."
                aria-label="Text"
                onChange={(e) => setText(e.target.value)}
              />
              


              <button 
                disabled={!text || playState || registerHit} 
                className={` flex items-center justify-center bg-pygblue text-white font-bold py-1 px-2 rounded
                  focus: outline-none shadow-md
                  ${!text || playState || registerHit? 'opacity-50 cursor-default' : 'hover:bg-blue-500 opacity-100'}
                  `}

                type="submit">

                <svg
                  className="w-6 h-6 text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h14M12 5l7 7-7 7" />
                </svg>

              </button>
              
            </form>


          </div>
        </div>
      </div>
    
  );
};
export default ListenAndWrite;