import Breadcrumb from "rsuite/esm/Breadcrumb";
import { NavLink } from "react-router-dom";
const LayoutSoundGroups = ({children}) => {

  
  return (
    
      
      
    <div className="flex scale-75 md:scale-100 justify-between  items-center font-mono mx-auto p-15 grid grid-cols-1 md:grid-cols-2 gap-3">
            
            
      <div className="p-4 text-md gap-8  rounded-lg">
        <Breadcrumb  separator={'>'} >
                <Breadcrumb.Item 
                >
                <NavLink  style={({ isActive }) => ({
                            color: isActive ? 'blue' : 'black',
                            background: isActive ? 'transparent' : 'transparent',

                        })} to="/">Home</NavLink>
                </Breadcrumb.Item >

                <Breadcrumb.Item 
                >
                    <NavLink style={({ isActive }) => ({
                            color: isActive ? 'blue' : 'black',
                            background: isActive ? 'transparent' : 'transparent',
                            fontWeight: isActive ? "inherit" : "",

                        })} to="/soundgroups"  >Sound groups</NavLink>  
                </Breadcrumb.Item>
                </Breadcrumb>
    </div>






   <div className="flex justify-between relative items-center mx-auto shadow-lg gap-2 rounded-lg p-2">

       

       
       

      

   
   </div>

   





                   
   <div className="flex justify-center shadow-lg mx-auto 2xl:py-48 mx-auto p-6 2xl:px-12 w-screen h-auto">
      
     {children}                                  
   </div>


   
       
       

       

</div>


)}


     
      
  

export default LayoutSoundGroups