import 'animate.css'

const Results= ({ results, nextTab, nextExercise }) => {

setTimeout(() => {
    nextTab(nextExercise)
}, 3000);

console.log(results, "results")

const result = results.map((score,id) => (
    <div className={`h-5 w-6 rounded-full shdow-sm opacity-90 ${score ? 'bg-green-400' : 'bg-red-400'} `}
        key={id}
    >
    </div>)
)



return (
    <div className="flex flex-col">
        <div className="flex flex-row m-auto p-10 gap-2">
        {result}
        </div>

        <div className="flex flex-col text-center  font-medium p-5 mx-auto ">
            <h1 className="text-2xl mb-7 text-gray-800">Moving on to </h1>
            <h1 className="text-4xl font-bold mb-20 animate__animated animate__zoomIn">{nextExercise}</h1> 
        </div>
        
    <div className="flex flex-row">
    
    </div>
    </div>
    );
};

export default Results;