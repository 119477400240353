import React from 'react'

import { Tooltip} from "@material-tailwind/react";
import { useState } from 'react';
import { Link } from 'react-router-dom';
import CardPlayer from './Player/CardPlayer';
import CardPlayerNorwegian from './Player/CardPlayerNorwegian';
import CardSoundGroupButtonMPAudio from './Button/CardSoundGroupButtonMPAudio';
import CardSoundGroupButtonNWAudio from './Button/CardSoundGroupButtonNWAudio';
import CardSoundGroupButtonNW from './Button/CardSoundGroupButtonNW';
import CardSoundGroupButtonMP from './Button/CardSoundGroupButtonMP';
import CardSoundGroupButtonMPAudioNorwegian from './Button/CardSoundGroupButtonMPAudioNorwegian';
import CardPlayerEnglish from './Player/CardPlayerEnglish';
import CardSoundGroupButtonMPArray from './Button/CardSoundGroupButtonMPArray';
const CardTargetSound = ({exercise, l2, include}) => {
  const group = exercise.sound
  const dummy = [...Array(33)]
  const positions = [2, 21,31, 16, 0, 28, 4, 12, 24, 29, 7, 19,26, 9, 18, 3, 15, 17 ]

 
  let colorcodes = ["deepskyblue", "cornflowerblue", "aquamarine", "aliceblue", "azure", "darkturquoise","cadetblue", "dodgerblue" , "aqua","blueviolet", "fuchsia", "lavender", "lightsteelblue", "mediumpurple", "yellowgreen"]
  const dummy_index=14
  positions.forEach((d,index) => dummy[d] = exercise.pairs[index] )
  dummy[dummy_index] = exercise.pairs[0]
  const [show, setShow] = useState(true);
  const [currentEx, setCurrentEx] = useState({});
  console.log(show, exercise,l2, "show")

    return (

            show ?
                    

            <Tooltip className="flex flex-col bg-indigo-300 font-medium text-white rounded-full "
            animate={{
              mount: { scale: 1.1, y: 0 },
              unmount: { scale: 0, y: 25 },}} 
             content={include? "Choose a sound pair" : "Familiar sounds" }
             placement="right-start"
             
             > 
                  
                    

                  
                    
                      <div className="scale-75 md:scale-100 grid grid-rows-6 grid-flow-col w-96 h-96 bg-farmWhite rounded-full shadow-2xl hover:scale-75 xl:hover:scale-110 duration-500 font-mono">
                        
                        {dummy.map(
                          (el, index)=>{ 
                              return (

                                el ? 
                                        index !== dummy_index 
                                        ?
                                          el.MP_wordpair ?
                                            l2 !=='Norwegian' ? 
                                              l2 === 'English' ?
                                                <div  onClick={() => {setShow(false); setCurrentEx(el)} } key={el.Exercise_name+l2+index}>
                                                  <CardSoundGroupButtonMP currentTrack={el.MP_wordpair} include={include} title={
                                                                              el.Second_MinimalPair_IPA ? 
                                                                                el.Second_MinimalPair_IPA === el.Group_lesson ?
                                                                                el.MinimalPair_IPA
                                                                                :
                                                                                el.Second_MinimalPair_IPA
                                                                              : 
                                                                                el.Exercise_name
                                                                            } 
                                                                          color={String(colorcodes[positions.indexOf(index)])} />
                                                </div>
                                              :
                                              <div  onClick={() => {setShow(false); setCurrentEx(el)} } key={el.Exercise_name+l2+index}>
                                                <CardSoundGroupButtonMPAudio currentTrack={el.MP_wordpair} include={include} title={el.Second_MinimalPair_IPA ? el.Second_MinimalPair_IPA : el.Exercise_name} color={String(colorcodes[positions.indexOf(index)])} />
                                              </div>  
                                            :

                                            
                                            <div  onClick={() => {setShow(false); setCurrentEx(el)} } key={el.Exercise_name+l2+index}>
                                              <CardSoundGroupButtonMPAudioNorwegian currentTrack={el} include={include} title={el.Second_MinimalPair_IPA ? el.Second_MinimalPair_IPA : el.Exercise_name} color={String(colorcodes[positions.indexOf(index)])} />
                                            </div>
                                          :
                                          l2 !=='Norwegian' ? 
                                              <div  onClick={() => {setShow(false); setCurrentEx(el)} } key={el.Exercise_name+l2+index}>
                                               <CardSoundGroupButtonNWAudio currentTrack={el.MP_nonword} title={ el.Second_MinimalPair_IPA ? el.Second_MinimalPair_IPA : el.Exercise_name} include={include} color={String(colorcodes[positions.indexOf(index)])} />
                                              </div>
                                              :
                                              <div  onClick={() => {setShow(false); setCurrentEx(el)} } key={el.Exercise_name+l2+index}>
                                               <CardSoundGroupButtonNW currentTrack={el.MP_nonword} title={ el.Second_MinimalPair_IPA ? el.Second_MinimalPair_IPA : el.Exercise_name} include={include} color={String(colorcodes[positions.indexOf(index)])} />
                                              </div>


                                        : 
                                          include ?
                                          <button key={group+l2} className={"row-span-2 col-span-2 shadow-xl bg-amber-300 hover:bg-green-500 text-blue-700 text-6xl font-medium truncate  hover:text-white py-2 px-4 border   border-yellow-800 hover:border-transparent rounded-full"}>
                                            {group}
                                          </button>
                                                  :
                                          <button key={group+l2} className={"row-span-2 col-span-2 shadow-xl bg-gray-300 hover:bg-grey-500 text-grey-700 text-6xl font-medium truncate  hover:text-white py-2 px-4 border w-32 h-32 border-gray-800 hover:border-transparent rounded-full"}>
                                            {group}
                                          </button>

                                    : 
                                        <button key={index+"noel"} className="bg-transparent border w-16 h-16  border-transparent hover:border-transparent rounded-full">
                                        
                                        </button>
                                        
                                        )
                                       }
                          
                        )}
                      </div>
                          
                
                </Tooltip>  
            :    (l2 == 'Norwegian' ?
                    <div className="h-auto scale-95"> 
                            <button className =" bg-white  hover:bg-gray-100 text-gray-800 font-semibold  text-sm py-2 px-4 border border-gray-400 rounded shadow"  onClick={() => {setShow(true);} } > Go back</button>
                            <Link  to={`/norwegianplayer/${currentEx.id}`} target="_blank"><button className ="md:absolute right-0 bg-white hover:bg-gray-100 text-gray-800 font-semibold text-sm py-2 px-4 border border-gray-400 rounded shadow">A new tab</button></Link>
                      <CardPlayerNorwegian exercise={currentEx} l2={l2}  />
                    </div>
                    :
                    l2 == 'English' ?
                    <div className="h-auto scale-95"> 
                                  
                            <button className =" bg-white  hover:bg-gray-100 text-gray-800 font-semibold  text-sm py-2 px-4 border border-gray-400 rounded shadow"  onClick={() => {setShow(true);} } > Go back</button>
                            <Link  to={`/englishplayer/${currentEx.id}`} target="_blank"><button className ="md:absolute right-0 bg-white hover:bg-gray-100 text-gray-800 font-semibold text-sm py-2 px-4 border border-gray-400 rounded shadow">A new tab</button></Link>
                        
                      <CardPlayerEnglish exercise={currentEx} l2={l2}  />
                    </div>
                      :
                                       
                    <div className="h-auto scale-95"> 
                                  
                                  <button className =" bg-white  hover:bg-gray-100 text-gray-800 font-semibold  text-sm py-2 px-4 border border-gray-400 rounded shadow"  onClick={() => {setShow(true);} } > Go back</button>
                            <Link  to={`/player/${l2}/${currentEx.id}`} target="_blank"><button className ="md:absolute right-0 bg-white hover:bg-gray-100 text-gray-800 font-semibold text-sm py-2 px-4 border border-gray-400 rounded shadow">A new tab</button></Link>
                        
                      <CardPlayer exercise={currentEx} l2={l2}  />
                    </div>)
                                      
    
    ) 
}
export default CardTargetSound;
