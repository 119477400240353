import React from "react";
import useSWR from "swr";
const fetcher = (...args) => fetch(...args).then((res) => res.json());

const CategoryDropdownL1 = ({ selectHandler, elValue}) => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_L1L2_API_URL}/l1-l2map/list_languages_service/?service_key=1234`,
    fetcher,  {
      
    }
    
  ); 
  
  if (error) return ( 
    <select className="mr-2 ml-2  text-black-500 bg-white border rounded-md shadow-lg outline-none appearance-none focus:border-gray-600">
   
                <option value="Other" key="newother">
                 
                 L1L2map is unavailable
                
                </option>
    </select>
              )

  if (!data) return ( 
    <select className="mr-2 ml-2  text-black-500 bg-white border rounded-md shadow-lg outline-none appearance-none focus:border-gray-600">
   
                <option value="Other" key="newother">
                 
                 Loading list...
                
                </option>
    </select>
              )


  return (
    <select className="w-24 mr-2 ml-2 text-clip  text-black-500  bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-gray-600"
      onChange={selectHandler}
      value={elValue}
    >
      {data.map((lang) => {
        return (
          <option value={lang.fields.name} key={lang.pk}>
            {lang.fields.name}
          </option>
        );
      })}
    </select>
  );
};


export default CategoryDropdownL1;
