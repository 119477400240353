import React from 'react'

import { useState, useEffect } from 'react';
import CardPlayerEnglish from '../../components/Card/Exercises/Player/CardPlayerEnglish';
import Selector from '../../components/Card/SoundGroups/Selector';
import ExercisePlayerEnglish from '../../components/Card/Exercises/Exercise/ExercisePlayerEnglish';
import {useParams} from "react-router-dom"
import useAuth from '../../../Users/hooks/useAuth';

const TabPlayerEnglish = () => {
  let {l2, id} = useParams()

  const { setAuth } = useAuth();

  const checkToken = async () => {
    const token = localStorage.getItem("token");
    if (token) {
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/users/me`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
        if (response.ok) {
            let userData = await response.json();
            console.log(userData);
            userData["token"] = token;
            setAuth(userData);
        }
    }
}

useEffect(() => {
    checkToken();
}, []);

  const tabsData = [  
    {label: 'Training'},
    {label: 'Test yourself'},
    
  ];
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabs, setTabs] = useState(tabsData)
   
 


    return (

          
          
             
      <div className="w-auto md:w-1/2 mx-auto border-2  rounded-md"> 
              
                {(tabs[activeTabIndex].label === 'Training')  &&
                <CardPlayerEnglish id={id} l2={l2}  />
                  }

                {(tabs[activeTabIndex].label === 'Test yourself')  &&
                  <ExercisePlayerEnglish id={id} l2={l2} />
                }

                <Selector 
                    clickHandler={(tab_index) =>
                            {
                                setActiveTabIndex(tab_index)
                            }
                    } 
                    tabsData={tabs} 
                />      
              </div>
            
                                      
    
    ) 
}
export default TabPlayerEnglish;
