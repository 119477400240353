import { Tooltip} from "@material-tailwind/react";
import { useMemo, useRef, useState,  useEffect } from "react";
import PaginatorExercise from "../PaginatorExercise";


const mimeType = "audio/webm";
const DisplayRecorder = ({ tracks, stream }) => {

  
 
  const [playNext, setPlayNext] = useState(true)
  const [track, setTrack] = useState(1)
  const [buttonState, setButtonState] = useState(true)
  const mediaRecorder = useRef(null);
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [audioChunks, setAudioChunks] = useState([]);
  const [audio, setAudio] = useState(null);
 
  const inputRef_0 = useRef(null);
  const recordRef = useRef(null);
   
  
  const frames = [ { color: "blue" },
  { transform: "scale(1.05)", color: "blue" },
  { transform: "translateY(-10%)", color: "blue" }, 
  { transform: "translateY(0%)" , color: "blue"}, 
  { transform: "scale(1.05)", color: "blue" },
  { transform: "scale(1.0)" , color: "inherit"},
  ]
  

  const progress_frames = [ { color: "inherit" },
  { transform: "scale(1.05)", color: "inherit" },
  { transform: "translateX(224px)", color: "inherit" }, 
 
  { transform: "scale(1.2)", color: "inherit" },
  { transform: "scale(1.0)" , color: "inherit"},
  ]

  const playback_frames = [ { color: "green" },
  { transform: "scale(1.05)", color: "green" },
  { transform: "translateX(224px)", color: "green" }, 
 
  { transform: "scale(1.2)", color: "green" },
  { transform: "scale(1.0)" , color: "inherit"},
  ]

 

  let choice = tracks[track-1]
  let audio_0 = useMemo(() => new Audio(choice.audio),[choice]);
  let audio_record = new Audio(audio)
 
  
  
  
  useEffect(() => {
    var audio_duration = audio_0.duration ? audio_0.duration : 1
    if (recordingStatus === 'recording') {
      const timer = setTimeout(() => {
        stopRecording()
      }, 3*audio_duration*1000)
      recordRef.current.animate(  
        progress_frames,{ duration: 3*audio_duration*1000 , fill: "forwards", iterations: 1 } 
      );
      return () => clearTimeout(timer);
    }
  }, [recordingStatus, audio_0])

  /*useEffect(()=>{console.log(audio, audio_record, buttonState, "recording")},[audio])*/
  
  const startRecording = async () => {
    
    setRecordingStatus("recording");
    //create new Media recorder instance using the stream
    const media = new MediaRecorder(stream, { type: mimeType });
    //set the MediaRecorder instance to the mediaRecorder ref
    mediaRecorder.current = media;
    //invokes the start method to start the recording process
    
    mediaRecorder.current.start();
    let localAudioChunks = [];
    
    mediaRecorder.current.ondataavailable = (event) => {
       if (typeof event.data === "undefined") return;
       if (event.data.size === 0) return;
       localAudioChunks.push(event.data);
    };
    setAudioChunks(localAudioChunks);
  };
  const stopRecording = () => {
   
   
    setRecordingStatus("inactive");
    setButtonState(false)
    //stops the recording instance
    mediaRecorder.current.stop();
    mediaRecorder.current.onstop = () => {
      //creates a blob file from the audiochunks data
       const audioBlob = new Blob(audioChunks, { type: mimeType });
      //creates a playable URL from the blob file.
       const audioUrl = URL.createObjectURL(audioBlob);
       setAudio(audioUrl);
       console.log(audioUrl, audioChunks, audioBlob)
       setAudioChunks([]);
    };
  };

  

  const FireUpRecording = () => {
    audio_0.removeEventListener("ended", FireUpRecording)
    setTimeout(startRecording,0) 
  }


  const Playit_0 = () => {
    setPlayNext(true);
    setButtonState(true)
   
    var audio_duration = audio_0.duration ? audio_0.duration : 1
    console.log("duration", inputRef_0.current, audio_duration, audio_0.duration)
    audio_0.addEventListener("ended", FireUpRecording)
    if (inputRef_0.current){
    inputRef_0.current.animate(  
      frames,{ duration: audio_duration*1000 , fill: "forwards", iterations: 1 } 
    );
    }
    audio_0.play();
  };

  const Play_record = () => {
    setPlayNext(false);

    
    setTimeout(()=> {
        
      audio_0.addEventListener('canplaythrough', handleCanPlayThrough);
      audio_0.addEventListener("ended", FireUp)
      
      audio_0.currentTime = 0; 
      audio_record.currentTime = 0;
      
      
    }  , 300);
    
    
  };

  const FireUp = () => {
    audio_0.removeEventListener("ended", FireUp)
    
    
    setTimeout(() => {
      
        
          
         
      audio_record.play();
        
    }, 300) 
  
  }

  const handleCanPlayThrough = () => {
    
    audio_0.removeEventListener('canplaythrough', handleCanPlayThrough);
    console.log('Audio can be played through.');
    
    setTimeout(() => {
      audio_0.play();
      
    }, 300 ) 
    
    
  };

  return (
    <div className="flex flex-col my-auto">
    
      
      
      <div className="w-full flex flex-cols-3 py-5">
      
        <Tooltip className="flex flex-col bg-indigo-300 font-medium text-white rounded-full px-3"
              animate={{
                mount: { scale: 1.2, y: 0 },
                unmount: { scale: 0, y: 25 },}} 
              content={choice.translation }
              
        >
          <button disabled={false} className="mx-auto py-1 px-2 bg-blue-100 border-2 border-blue-300 rounded-xl text-gray-600  font-serif:Times text-3xl font-semibold text-gray-600 hover:text-blue-600" onClick={Playit_0}>
                
            <p className="text-center"  ref={inputRef_0}  > {choice.word}  </p>
          
          </button>
        </Tooltip>
        </div>
        
        
        <div className="flex flex-col m-auto py-3">
            
                <div className="w-64 h-9 border-2 rounded-full border-blue-200 scale-75">
                  <button disabled={true} className=" rounded-full w-8 h-8 py-1 bg-blue-400" ref={recordRef}></button>
                </div>
        </div>        
          <div className="flex flex-col m-auto py-3">
        <button disabled={buttonState}  className={`m-auto px-3 py-2 rounded-xl  font-serif:Times text-xl font-semibold   ${
            buttonState === true
            ? " text-gray-500 border-2 border-gray-300"
            : "bg-blue-100 border-2 border-blue-300 text-black-600 hover:text-blue-600 hover:bg-indigo-100"
        }`}
        onClick={Play_record}>
          
            <p className="text-center">Compare</p>
            
          </button>
        </div>
        <div className='scale-75 py-5'>
          <PaginatorExercise maxpage={Math.ceil(tracks.length)} page={track} offState={playNext}
                  clickHandler={(page) => {
                      setTrack(page);
                      setButtonState(true);
                      setPlayNext(true);
                      
                  }}
          />
        </div>
      
        
      </div>
    
  );
};
export default DisplayRecorder;