import TwoStressOne from "./TwoStressOne";
import TwoStressTwo from "./TwoStressTwo";
const TwoStressFull = () => {
    
   
   

return (
        

   
    <div key="first" className='flex flex-row'>
    

    <TwoStressOne />
    <div className='flex h-16 items-center justify-center'><p className='text-center font-bold text-xl px-3'>or</p></div>
    <TwoStressTwo />
    </div>
     
    
           
    )
}
export default TwoStressFull;