import React from 'react'
import { Tooltip} from "@material-tailwind/react";
import { useState } from 'react';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import CardPlayerNorwegian from './Player/CardPlayerNorwegian';
import CardSoundPositionsNorwegian from './Positions/CardSoundPositionsNorwegian';
import { useRef } from 'react';
import sortingShuffle from '../../shuffle';
import CardSoundGroupButtonMPArray from './Button/CardSoundGroupButtonMPArray';
import Selector from './Selector';
import ExercisePlayerNorwegian from './Exercise/ExercisePlayerNorwegian';


const CardSoundGroupNorwegian = ({exercise, l2, include}) => {
  
  const  PlayBoth = async () => {
    handleFocus(audio_0.duration+audio_1.duration)
    
    setTimeout(() => {
        audio_0.play();
        }, 2) 
    
    audio_0.onended=function()
    {
        setTimeout(() => {
        audio_1.play();
        }, 200)   
    }
  };
  const  StopBoth = async () => {
      
      
          audio_0.pause();
          audio_0.currentTime=0;   
      
          
          audio_1.pause();
          audio_1.currentTime=0;   
      
  };

  function handleFocus(timer) {
    let pulse_duration = 1700;
    if (timer){
      pulse_duration = timer*1000+300;
    }
    
    inputRef.current && inputRef.current.animate(  
      [
        { transform: "scale(1.05)" },
        { transform: "translateY(-10%)" }, 
        { transform: "translateY(-30%)" }, 
        { transform: "scale(1.2)" },
        { transform: "translateY(-10%)" },
        { transform: "translateY(0%)" }, 
        { transform: "scale(1.2)" },
        { transform: "scale(1.0)" },
        ],
        { duration: pulse_duration, fill: "forwards", iterations: 1 } 
    
    
    )

  }

   /* define random audio pick and animation */
  const inputRef = useRef(null);
  var products = [];

  const keys = Object.keys(exercise.dictionary);
  keys.forEach((key, index) => {
    products.push(exercise.dictionary[key][0])
  });

      
  let AudioSample = [];
  let Sample = [];
  Sample = sortingShuffle(products)[0]
  AudioSample = Sample.MP_wordpair;
  const audio_tag ='audio_'+AudioSample[0].dialect 
 
  let choice = ['','']
  choice = [  `${process.env.REACT_APP_STATIC_URL}`+ sortingShuffle(AudioSample[0][audio_tag])[0],
              `${process.env.REACT_APP_STATIC_URL}`+ sortingShuffle(AudioSample[1][audio_tag])[0]
            ]

  let audio_0 = useMemo(() => new Audio(choice[0].replace('C:\\CalstFiles\\WordObjectContent','').replace('D:\\Workspace\\CalstFiles\\WordObjectContent','')),[choice])
  let audio_1 = useMemo(() => new Audio(choice[1].replace('C:\\CalstFiles\\WordObjectContent','').replace('D:\\Workspace\\CalstFiles\\WordObjectContent','')),[choice])

  /* design of circles */

  const group = exercise.sound
  const dummy = [...Array(33)]
  
  const positions = [2, 21,31, 16, 0, 28, 4, 12, 24, 29, 7, 19,26, 9, 18 ]

  let colorcodes = [
                      "deepskyblue", "cornflowerblue", "aquamarine", "aliceblue", "azure", "darkturquoise",
                      "cadetblue", "dodgerblue" , "aqua","blueviolet", "fuchsia", "lavender", "lightsteelblue", 
                      "mediumpurple", "yellowgreen"
                    ]
  const dummy_index=14
  const test = exercise.dictionary
  const names = Object.keys(test);
  names.forEach((key, index) => {
    dummy[positions[index]] = test[key]
  });
  dummy[dummy_index] = exercise.sound
  const [show, setShow] = useState(true);
  const [currentEx, setCurrentEx] = useState({});
  
  const tabsData = [  
    {label: 'Training'},
    {label: 'Test yourself'},
    
  ];
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabs, setTabs] = useState(tabsData)
    
  const [dia, setDia] = useState({label : 'Oslo'})

  function handleState(newValue) {
      setDia(newValue);
      console.log(newValue, "neval")
   }

    return (

          show 
            
          ?
                    

          <Tooltip 
              className="flex flex-col bg-indigo-300 font-medium text-white rounded-full "
              animate={{
                mount: { scale: 1.0, y: -15 },
                unmount: { scale: 0, y: 25 },}} 
              content={include? "Choose a sound pair" : "Already familiar sounds" }
           
           
           > 
              <div  onMouseEnter={PlayBoth} 
                    onMouseLeave={StopBoth} 
                    className=" scale-75 2xl:scale-100 
                                grid grid-rows-6 grid-flow-col 
                                w-96 h-96 bg-farmWhite rounded-full 
                                hover:bg-blue-100 shadow-2xl duration-500 font-mono"
              >
                    
                {dummy.map(
                  (el, index)=>{ 
                      
                      return (
                        
                        el ? 
                                
                                index !== dummy_index 

                                ?
                                
                                <div  ref={ el[0].Exercise_name === Sample.Exercise_name ? inputRef : undefined}   
                                      key={'host'+String(index) + String(el[0].Exercise_name)}  
                                      onClick={() => {setShow(false); setActiveTabIndex(0);setCurrentEx(el);} } 
                                >
                                  <CardSoundGroupButtonMPArray 
                                        currentEx={el} 
                                        include={include} 
                                        color={String(colorcodes[positions.indexOf(index)])} 
                                  />
                                </div>

                                : 
                                <button key={String(index) + String(el[0].Exercise_name)}  
                                      to={`/targetsound/${l2}/${group.replace('/','_')}`} 
                                      target="_blank" 
                                      rel="noreferrer" 
                                      className={`row-span-2 col-span-2 flex justify-center items-center  shadow-xl 
                                                truncate hover:text-white font-medium hover:border-transparent rounded-full 
                                                text-5xl 
                                                ${ include  ? 
                                                            "bg-amber-300 hover:bg-green-500 text-blue-700 border border-yellow-800" 
                                                            : 
                                                            "bg-gray-300 hover:bg-grey-500 text-grey-700 border border-gray-600"}
                                                `}
                                >
                                  {group}
                                </button>

                            : 
                                <button key={index} 
                                        className="bg-transparent border w-16 h-16  border-transparent hover:border-transparent 
                                                    rounded-full"
                                >
                              
                                </button>
                                
                                )
                                }
                  
                )}
              </div>
                  
                  
            </Tooltip>  
          :
            currentEx.length === 1 ?
              <div onMouseEnter={StopBoth} 
              className="flex flex-col  bg-FarmWhite border-b-2 border-l-2 border-r-2 rounded-md 
              transition ease-in-out"
              > 
                <div className='flex flex-row'>
                  <button className ="bg-white  hover:bg-gray-200 text-gray-800 font-semibold 
                                  text-sm py-2 px-4 border border-gray-400 rounded shadow"  
                          onClick={() => {setShow(true);} } 
                  >
                    Go back
                  </button>

                  <div className='grow border-b-2'>

                  </div>
                    <Link to={`/norwegianplayer/${currentEx[0].id}`} 
                          target="_blank"
                    >
                      <button className ="right-0 bg-white hover:bg-gray-100 text-gray-800 font-semibold 
                                          text-sm py-2 px-4 border border-gray-400 rounded shadow"
                      >
                        A new tab
                      </button>
                    </Link> 
                  
                </div>
                    {(tabs[activeTabIndex].label === 'Training')  &&
                      <CardPlayerNorwegian change={handleState} id={currentEx[0].id} l2={l2}  />
                    }
                    {(tabs[activeTabIndex].label === 'Test yourself')  &&
                      <ExercisePlayerNorwegian dialect={dia} id={currentEx[0].id} l2={l2} />
                    }
                    
                    <Selector 
                    clickHandler={(tab_index) =>
                            {
                                setActiveTabIndex(tab_index)
                            }
                    } 
                    tabsData={tabs} 
                />      
                </div>
              
            :
            currentEx.length > 1 &&  
              <div onMouseEnter={StopBoth} 
                  className="h-auto scale-95 shadow-lg flex flex-col  bg-FarmWhite rounded-xl 
                  transition ease-in-out"
              >
                <button className ="bg-gray-100 hover:bg-gray-200 text-gray-800 font-semibold  
                                    text-sm py-2 px-4 border border-gray-400 rounded shadow"  
                        onClick={() => {setShow(true);} } 
                > 
                  Go back
                </button>
                
                
                <CardSoundPositionsNorwegian  include = {true}   
                          exercise = {currentEx} 
                          l2={l2} 
                /> 
              </div>
                                
    
    ) 
}
export default CardSoundGroupNorwegian
