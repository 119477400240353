import { useState } from "react";

const Tabs =  ({tabsData, clickHandler }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
   
    <div className="grid grid-col-1 gap-y-5 md:gap-1  rounded-md">
    
    {tabsData.map((tab, idx) => {
    return (
        <button
        key={idx}
        className={`py-2 border-b-2 border-dotted text-left text-xs md:text-sm transition-colors duration-300 ${
            idx === activeTabIndex
            ? "border-indigo-500 text-indigo-600 font-semibold"
            : "border-transparent hover:border-gray-200"
        }`}
        /* condition classname */
        onClick={() => {
          clickHandler(idx)
          setActiveTabIndex(idx)
        }
        }>
        {tab.label}
        </button>
    );
    })}
</div>

  );
}
export default Tabs;