import {useState, useEffect} from 'react'
import sortingShuffle from '../../../shuffle';
import ListenAndClick from '../ListenAnd/ListenAndClick';
import Selector from './Selector';
import ListenAndClickII from '../ListenAnd/ListenAndClickII';
import ListenAndWrite from '../ListenAnd/ListenAndWrite';
import ListenAndSpeak from '../ListenAnd/ListenAndSpeak';
import { Link } from 'react-router-dom';
import Summary from '../Display/Summary';
import 'animate.css';

//  TODO: make footer with phonotics and liter-ate icons
const ExerciseFooter = () => {
    return (
        <div className='flex flex-col text-center border-t text-gray opacity-20 font-bold'>
            Here lies the footer. Should place icons for phonsplain and liter-ate here.
        </div>
    )

}

const ExercisePlayer = ({id, l2, exerciseHandler}) => {
    const [results, setResults] = useState({})
    const [showResults, setShowResults] = useState(null)
    const [status, setStatus] = useState(null);
    const [PlayTracks, setPlayTracks] = useState([])
    const [PlayTracksNW, setPlayTracksNW] = useState([])
    const [PlayTracksNW_LS, setPlayTracksNW_LS] = useState([])
    const [PlayTracksNW_LW, setPlayTracksNW_LW] = useState([])
    const [info, setInfo] = useState({})
    const [NumOfParts, setNumOfParts] = useState(4)
    const [hideTitle, setHideTitle] = useState(false)
    const [activeTabIndex, setActiveTabIndex] = useState(0);   /*  Exercise playing */
    const [nextExercise, setNextExercise] = useState(null)

    // For progress bars
    const [nrExercises, setNrExercises] = useState([]) // Structure example [10,10,10,10]
    const [progress, setProgress] = useState([]) // Structure example[[1,0,1],[0],[0],[0]]

    // console.log('JOY: exercise,  level 1, block with tabs -- listen and click, listen and write etc ')

    const tabsData = [  
        {label: 'Listen & Click I', instructions:'In this listening exercise you hear two very similar words, followed by a repetition of one of these words. Can you hear which of the two words was repeated? This is called a discrimination task.'},
        {label: 'Listen & Click II', instructions: 'In this listening exercise you hear only one word. Can you hear which of the two words was said?'},
        {label: 'Listen & Speak', instructions: 'In this exercise, you first hear the tutor’s pronunciation of the word. When the bar under the recordings bar starts to run, you speak the word into the microphone. Compare your own pronunciation with that of the tutor. Not happy with your pronunciation? Press the loudspeaker button and give it another try.'},
        {label: 'Listen & Write', instructions: 'It can be hard to know how words that you hear are written. Can you write the word you hear correctly?'},
      ];

    const tabsDataNW = [  
        {label: 'Listen & Click II', instructions: 'In this listening exercise you hear only one word. Can you hear which of the two words was said?'},
        {label: 'Listen & Speak', instructions: 'In this exercise, you first hear the tutor’s pronunciation of the word. When the bar under the recordings bar starts to run, you speak the word into the microphone. Compare your own pronunciation with that of the tutor. Not happy with your pronunciation? Press the loudspeaker button and give it another try.'},
        {label: 'Listen & Write', instructions: 'It can be hard to know how words that you hear are written. Can you write the word you hear correctly?'},
    ];

    
    /*  state of tab names -- two cases. [{},{}] or NW [{},{}]*/
    const [tabs, setTabs] = useState(tabsData)
    

    // Returns label of the next exercise to be played, when when the current exercise is completed
    const findNextExercise = (progress, activeTabIdx) => {
        const length = tabs.length

        // Check from activeTabIndex to the end
        for (let idx = activeTabIdx +1 ; idx < length; idx++) {
            console.log(idx, "idx first", length, "length")
            if (progress[idx] === undefined || (progress[idx] && progress[idx].length < nrExercises[idx])) {
                setNextExercise(tabs[idx].label) 
                console.log(tabs[idx].label, "nextExercise")
                return
            }
        }

        // If no such index is found, return -1 for now. TODO?: handle this case
        return -1;
    }

    useEffect(() => {

        if (activeTabIndex !== tabsData.length-1 
            && progress[activeTabIndex] 
            && progress[activeTabIndex].length === nrExercises[activeTabIndex]) {
            
            setTimeout(() => {
                setHideTitle(true)
                
            }, 100);
            findNextExercise(progress, activeTabIndex)
        }
        else {
            setHideTitle(false)
        }

    }, [progress])


    useEffect(() => {

        // Sets tabs and nrExercises based on PlayTracks
        if (PlayTracks.length === 0) {
            setTabs(tabsDataNW); 
            setNumOfParts(3)
            setNrExercises([PlayTracksNW.length, PlayTracksNW.length, PlayTracksNW.length])

        }
        else if (PlayTracks.length > 0) {
            setTabs(tabsData);
            setNumOfParts(4);
            setNrExercises([PlayTracks.length, PlayTracks.length, PlayTracks.length, PlayTracks.length]);
        }
        else{
            setNrExercises([])
        }
    }, [PlayTracks])


    /* fetch the data -- word-word pairs (MP), and wod-nonwod pairs -- NW*/ 
    let data_source =  `${process.env.REACT_APP_API_URL}/exercise/${l2}/${id}`
    useEffect(()=>{
        const fetchAssets = async () => {
            setStatus('IN_PROGRESS');
            try { 
                await fetch(data_source)
                .then(response=>response.json())
                    .then(json=>{
            
                        var tracks = [];
                        for (const lang of json.MP_wordpairs) {                       
                            var deal = sortingShuffle(
                                [
                                    [   {audio : `${process.env.REACT_APP_STATIC_URL}`+ sortingShuffle(lang[0].audio_0)[0], word : lang[0].word, translation : lang[0].Translation  },
                                        {audio : `${process.env.REACT_APP_STATIC_URL}`+ sortingShuffle(lang[1].audio_0)[0], word : lang[1].word, translation : lang[1].Translation  }
                                    ], 
                                    [   {audio : `${process.env.REACT_APP_STATIC_URL}`+sortingShuffle(lang[1].audio_1)[0], word : lang[1].word, translation : lang[1].Translation   },
                                        {audio : `${process.env.REACT_APP_STATIC_URL}`+sortingShuffle(lang[0].audio_1)[0], word : lang[0].word, translation : lang[0].Translation   }
                                    ]
                                ]
                                )
                            tracks.push([sortingShuffle(deal[0]), sortingShuffle(deal[1])[0]])

                        }
                        setPlayTracks(sortingShuffle(tracks))
                        
                        console.log(PlayTracks, "PlayTracks")
                        
                        
                        var nwproducts = [];
                        for (const lang of json.MP_nonwords) {
                            let deal = sortingShuffle([true, false])[0]
                            var deal_nw = sortingShuffle(
                                [   {audio : `${process.env.REACT_APP_STATIC_URL}`+ sortingShuffle(lang.audio_0)[0], word : lang.word, translation : lang.Translation, nonword : lang.nonword, nonword_translation: lang.PairWordTranslation, NumberOfSyllable : lang.NumberOfSyllable, MainStressSyllable : lang.MainStressSyllable, first : deal},
                                    {audio : `${process.env.REACT_APP_STATIC_URL}`+ sortingShuffle(lang.audio_1)[0], word : lang.word, translation : lang.Translation, nonword : lang.nonword, nonword_translation: lang.PairWordTranslation, NumberOfSyllable : lang.NumberOfSyllable, MainStressSyllable : lang.MainStressSyllable, first : deal} 
                                ]
                                );
                            nwproducts.push(deal_nw[0])
                        }
                        
                       
                        setPlayTracksNW(sortingShuffle(nwproducts))
                        setPlayTracksNW_LS(sortingShuffle(nwproducts))
                        setPlayTracksNW_LW(sortingShuffle(nwproducts))
                        let info_json = {"name": json.Exercise_name, "group": json.Group_lesson, "type": json.Type_description, 'id': id, "l2" : l2}
                        setInfo(info_json)
                        setStatus('SUCCESS')
                      
                    })  
                }
            catch (e)
                {
                    setStatus('FAILURE')
                 
                }       
                    
        }
        fetchAssets();
            
    },[id,l2]) 



  return (

    (status === "SUCCESS") ?

            <div className={`flex flex-col flex-grow 
                `}>
             
                
                
                {showResults ?                  
                <div>
                    <Summary 
                    sum={results}
                    parts={NumOfParts} 
                    info={info}
                    backToExercises={(state) => {
                        setShowResults(state);
                        setResults({})
                        setActiveTabIndex(0)
                        
                        
                    }}
                    
                    />
                </div>
               :
                <div>
                    <Selector 
                        clickHandler={(tab_index) =>
                                {
                                    

                                    setActiveTabIndex(tab_index);
                                }
                        } 
                        tabsData={tabs} 
                        active = {activeTabIndex}

                        exerciseHandler={exerciseHandler} 
                        progress={progress}
                        setProgress={setProgress} 
                        nrExercises={nrExercises}
                    
                    />

                    
                     {/* Title */}
                    <div className='flex flex-col items-center p-5 md:block hidden'>
                        <div className={`text-center text-3xl animate__animated
                            ${
                                hideTitle ? 'hidden' : ''
                            }`}>
                            {tabs[activeTabIndex].label.startsWith('Listen & Click') ? 'Listen & Click' : tabs[activeTabIndex].label}
                        </div>                      
                    </div>

                    {/* Exercises */}
                    <div className='flex flex-col grow'>
                    
                        {(tabs[activeTabIndex].label === 'Listen & Click I' && PlayTracks.length > 0 )&&     
                                
                            <ListenAndClick 
                            tracks={PlayTracks} 
                            info={info} 
                            resultsHandler={(state) =>
                                {
                                    if (results)
                                        { 
                                            results['ListenAndClick'] = state
                                            setResults(results);
                                        }
                                    else
                                        {
                                            setResults({'ListenAndClick': state});
                                        };
                                } 
                            }
                            nextTab = {(tab) =>
                                {
                                    setActiveTabIndex(tabs.findIndex((element) => element.label === tab))
                                }
                            }
                            nextExercise = {nextExercise}
                            
                            // for progress bar
                            progress={progress} 
                            setProgress={setProgress} 
                            tab={activeTabIndex} 
                            />
                                
                        }
                            
                        {(tabs[activeTabIndex].label === 'Listen & Click II' && PlayTracksNW.length > 0 ) &&
                                
                            
                            <ListenAndClickII   
                            tracks={PlayTracksNW} 
                            resultsHandler={(state) =>
                                {
                                    if (results)
                                        { 
                                            
                                            results['ListenAndClickII'] = state
                                            setResults(results);
                                        }
                                    else
                                        {
                                            setResults({'ListenAndClickII': state});
                                        };
                                } 
                            } 
                            nextTab = {(tab) =>
                                {
                                    setActiveTabIndex(tabs.findIndex((element) => element.label === tab))
                                }
                            }
                            nextExercise = {nextExercise}

                            // for progress bar
                            progress={progress} 
                            setProgress={setProgress} 
                            tab={activeTabIndex} 
                                />
                                
                        }
    
    
                                    
                        
                        
                        {(tabs[activeTabIndex].label === 'Listen & Speak' && PlayTracksNW.length > 0)&&    
                                
                            <ListenAndSpeak 
                            tracks={PlayTracksNW_LS} 
                            resultsHandler={(state) =>
                                {
                                    if (results)
                                        { 
                                        
                                            results['ListenAndSpeak'] = state
                                            setResults(results);
                                        }
                                    else
                                        {
                                            setResults({'ListenAndSpeak': state});
                                        };
                                } 
                            } 
                            nextTab = {(tab) =>
                                {
                                    setActiveTabIndex(tabs.findIndex((element) => element.label === tab))
                                }
                            }
                            nextExercise = {nextExercise}

                            // for the progress bar
                            progress={progress} 
                            setProgress={setProgress} 
                            tab={activeTabIndex} 
                            
                            
                            />
                                
                        }

                        {(tabs[activeTabIndex].label === 'Listen & Write' && PlayTracksNW.length > 0)&&    
                                
                            <ListenAndWrite 
                            tracks={PlayTracksNW_LW}  
                            resultsHandler={(state) =>
                            {
                                if (results)
                                    { 
                                        results['ListenAndWrite'] = state
                                        setResults(results);
                                    }
                                else
                                    {
                                        setResults({'ListenAndWrite': state});
                                    };
                                } 
                            } 
                            showResultsHandler = {(state) => 
                                {
                                    setShowResults(state);
                                }
                            }

                            // for progress bar
                            progress={progress} 
                            setProgress={setProgress} 
                            tab={activeTabIndex} 
                                />
                                    
                        }
                            
                    </div>

                    {/* <ExerciseFooter/> Uncomment to implement */} 
                    
                    <button
                    className={`py-2 bg-inherit text-gray-400 font-extrabold md:hidden w-full tracking-wide bottom-0 fixed  border-t-2
                    ${showResults ? 'hidden' : ''}
                    `}
                    onClick={() => 
                        activeTabIndex + 1 === tabs.length ? 
                        setShowResults(true)  
                        :setActiveTabIndex(activeTabIndex + 1)
                    }
                    >
                        S K I P <p className='font-normal tracking-normal'>{tabs[activeTabIndex].label}</p>
                    </button>
                </div>
                }

                
            </div>
            :    
                 
             
            (status === "FAILURE") ? 
                <div className='border-2 flex flex-col'>
                    <div    className='mx-auto text-xl text-red-500'>Error with data catching, check</div>
                    <Link   className='border-2  rounded-md p-2 mx-auto text-red-500 text-2xl' 
                            to={`${process.env.REACT_APP_API_URL}/exercise_raw/${l2}/${id}`} 
                            target="_blank"
                    >
                        the raw data
                    </Link>
                </div>
            : 
                <div className='flex flex-col mx-auto text-md'>Loading...</div>
              
    )
}


export default ExercisePlayer;
