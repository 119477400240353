import React from "react";

const CategoryDropdownL2 = ({ selectHandler, elValue}) => {
  const L2List =  ["Catalan (Central)", "Spanish (Castilian)", "Italian", "Greek", "Norwegian", "English"];
  
  return (
    
    <select value={elValue} 
    name="l2" 
    id="l2" 
    className="mr-2 ml-2 text-left py-2 px-4 text-lg   border-4  border-teal-400 text-black-500 bg-white border rounded-md 
                shadow-sm outline-none appearance-none focus:border-teal-600" 
    onChange={selectHandler}
>


{L2List.map(
    (les,i) => 
        {
            return (
                <option value={les} key={les}>
                    {les}
                </option>
            );
        }
    )
}           
</select>
    
    
    
    
    
   
  );
};


export default CategoryDropdownL2;
