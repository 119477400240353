import React, { Component } from 'react';
import { loadCaptchaEnginge, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

const CAPTCHA_CHARS = 6;

class Captcha extends Component {


    componentDidMount () {
        loadCaptchaEnginge(CAPTCHA_CHARS, "white", "black", "lower");
    }

    onSubmit = () => {
        let user_captcha = document.getElementById("user_captcha_input").value;
        if (validateCaptcha(user_captcha)) {
            this.props.func();
        } else {
            alert("Captcha mismatch");
            document.getElementById('user_captcha_input').value = "";
        }
    }

    render() {
        return (
            <div className="mx-auto w-1/2 p-10 rounded-lg shadow-2xl">
                <div className="flex flex-col justify-center items-center">
                    <LoadCanvasTemplateNoReload />
                </div>
                <div className="flex flex-col justify-center items-center">
                    <input
                        className="input input-bordered input-accent w-full max-w-xs m-3"
                        id="user_captcha_input"
                        type="text"
                        placeholder="Type what you see"
                    />
                </div>
                <div className="flex flex-col justify-center items-center">
                    <button className="btn btn-outline btn-accent m-3 btn-block" onClick={() => this.onSubmit()}>Submit</button>
                </div>
            </div>
        )
    }
}
export default Captcha;