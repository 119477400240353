import useAuth from "./hooks/useAuth";
import { useEffect, useState } from "react";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Select from 'react-select';
import Paginator from "../course/components/Paginator";
import Selector from "./Components/Selector";
import ActivitiesInterface from "./ActivitiesInterface";
import TeacherInterface from "./TeacherInterface";

const AccessInterface = ({successHandler}) => {
    const {setAuth} = useAuth();
    const [users, setUsers] = useState([]);
    const [requests, setRequests] = useState([]);
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [subscribed, setSubscribed] = useState(false);
    const [accessSearch, setAccessSearch] = useState("");
    const [requestSearch, setRequestSearch] = useState("");
    const [accessPage, setAccessPage] = useState(1);
    const [requestPage, setRequestPage] = useState(1);
    const [accessPages, setAccessPages] = useState(0);
    const [requestPages, setRequestPages] = useState(0);
    const [role, setRole] = useState({label: "All", value: "All"});
    const [changeList, setChangeList] = useState([]);
    const tabs = ['User management', 'User activities']
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [teacher, setTeacher] = useState("");
    
    const updateAccessList = async (page = 1) => {
        const token = localStorage.getItem("token");
        let query = `?page=${page}`;
        if (accessSearch !== "") {
            query += `&search=${accessSearch}`;
        }
        if (role.value !== "All") {
            query += `&role=${role.value}`;
        }
        
        fetch(`${process.env.REACT_APP_USER_API_URL}/admin/access-list${query}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status === 401) {
                localStorage.removeItem("token");
                setAuth({});
            }
        }).then(json => {
            if (json) {
                let xs = [];
                for (const user of json) {
                    xs.push(user);
                }
                setUsers(xs);
        }
        });
        await getAccessPages();
    }

    const searchUsers = async (event) => {
        event.preventDefault();
        setAccessPage(1);
        await updateAccessList();
    }

    const searchRequests = async (event) => {
        event.preventDefault();
        setRequestPage(1);
        await updateRequestList();
    }

    const updateRequestList = async (page = 1) => {
        const token = localStorage.getItem("token");
        fetch(`${process.env.REACT_APP_USER_API_URL}/admin/request-list?page=${page}${requestSearch !== "" ? `&search=${requestSearch}` : ""}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status === 401) {
                localStorage.removeItem("token");
                setAuth({});
            }
        }).then(json => {
            if (json) {
                let xs = [];
                for (const request of json) {
                    xs.push(request);
                }
                setRequests(xs);
            }
        });
        await getRequestPages();
    }

    const changeAccess = async (username, role) => {
        const token = localStorage.getItem("token");
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/admin/change-access/${username}/${role}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        });
        if (response.status === 401) {
            localStorage.removeItem("token");
            setAuth({});
        }
    }

    const denyAccess = async (username, role) => {
        const token = localStorage.getItem("token");
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/admin/deny-request/${username}/${role}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });
        if (response.status === 401) {
            localStorage.removeItem("token");
            setAuth({});
        }
    }

    const sendEmail = async (event) => {
        event.preventDefault();
        setEmail("");
        const token = localStorage.getItem("token");
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/admin/invite/${email}`,{
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });
        if (response.ok) {
            setEmailError("Email sendt");
        }
        if (response.status === 401) {
            localStorage.removeItem("token");
            setAuth({});
        }
        if (response.status === 409) {
            setEmailError("User with this email already exists");
        }
    }

    const handleCheck = async () => {
        const newState = !subscribed;
        setSubscribed(newState);
        const token = localStorage.getItem("token");
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/admin/subscribe/${newState}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        });
        if (response.status === 401) {
            localStorage.removeItem("token");
            setAuth({});
        }
    }

    const getAccessPages = async () => {
        const token = localStorage.getItem("token");
        let query = `?`
        if (accessSearch !== "") {
            query += `search=${accessSearch}`;
            if (role !== "All") {
                query += "&";
            }
        }
        if (role.value !== "All") {
            query += `role=${role.value}`;
        }
        if (query === "?") {
            query = "";
        } 
        fetch(`${process.env.REACT_APP_USER_API_URL}/admin/access-pages${query}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status === 401) {
                localStorage.removeItem("token");
                setAuth({});
            }
        }).then(json => {
            if (json) {
                setAccessPages(json["pages"]);
            }
        });
    }

    const getRequestPages = async () => {
        const token = localStorage.getItem("token");
        fetch(`${process.env.REACT_APP_USER_API_URL}/admin/request-pages${requestSearch !== "" ? `?search=${requestSearch}` : ""}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status === 401) {
                localStorage.removeItem("token");
                setAuth({});
            }
        }).then(json => {
            if (json) {
                setRequestPages(json["pages"]);
            }
        })
    }

    const deleteUser = async (username) => {
        const token = localStorage.getItem("token");
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/admin/delete-user/${username}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });
        if (response.status === 401) {
            localStorage.removeItem("token");
            setAuth({});
        }
    }

    const commit = async () => {
        for(const change of changeList) {
            if (change["action"] === "DELETE") {
                await deleteUser(change["username"]);
            } else if (change["action"].split(" ")[0] === "DENY") {
                await denyAccess(change["username"], change["action"].split("->")[1]);
            } else {
                await changeAccess(change["username"], change["action"].split("->")[1]);
            }
        }
        setChangeList([]);
        updateAccessList();
        updateRequestList();
        getAccessPages();
        getRequestPages();
    }

    const addChange = (change) => {
        setChangeList([...changeList, change]);
    }

    const addChangeAccess = (username, oldRole, newRole) => {
        addChange({"username": username, "action": `${oldRole}->${newRole}`});        
    }

    const addDenyAccess = (username, oldRole, newRole) => {
        addChange({"username": username, "action": `DENY ${oldRole}->${newRole}`});
    }

    const addDeleteUser = (username) => {
        addChange({"username": username, "action": "DELETE"});
    }

    const removeChange = (i) => {
        let array = [...changeList];
        array.splice(i, 1);
        setChangeList(array);
    }

    useEffect(() => {updateAccessList()}, []);
    useEffect(() => {updateAccessList(accessPage)}, [accessPage]);
    useEffect(() => {updateRequestList()}, []);
    useEffect(() => {updateRequestList(requestPage)}, [requestPage]);

    useEffect(() => {
        const token = localStorage.getItem("token");
        fetch(`${process.env.REACT_APP_USER_API_URL}/admin/subscribed`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status === 401) {
                localStorage.removeItem("token");
                setAuth({});
            }
        }).then(json => {
            if (json) {
                setSubscribed(json["subscribed"]);
            }
    })}, []);

    useEffect(() => {setEmailError("")}, [email]);

    if (users == []) {
        return <div>Loading...</div>
    }

    const options = ["USER", "TEACHER", "ADMIN"]
    const searchOptions = [
        {label: "All", value: "All"},
        {label: "USER", value: "USER"},
        {label: "TEACHER", value: "TEACHER"},
        {label: "ADMIN", value: "ADMIN"}
    ]
    
    if (teacher !== "") {
        return <TeacherInterface
            successHandler={(state) => {
                if (!state) {
                    setTeacher("");
                }
            }}
            teacher={teacher}
        />
    }

    return (

        <div className="flex flex-col mx-auto lg:mx-20 lg:p-10 p-5 rounded-lg shadow-2xl">

            <div className="flex flex-row justify-between">
                <button className=""
                    onClick={()=> {successHandler(false)}}        
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 scale-100 hover:scale-150 ease-in duration-100" viewBox="0 0 24 24" stroke="gray"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M6 18L18 6M6 6l12 12" /></svg>
                </button>
                <div className=" text-2xl text-primary text-center font-bold p-10 mx-auto">
                    Admin interface 
                </div>
            </div>
    
            <Selector 
                clickHandler={(tab_index) =>
                        {
                            setActiveTabIndex(tab_index);
                        }
                } 
                tabsData={tabs} 
                active = {activeTabIndex}

                
            
            />

            {(tabs[activeTabIndex] === 'User management')&&
                <div className="border-t-0 border-b-2 border-r-2 border-l-2 border-gray-200 rounded-b-xl shadow-xl lg:p-5 bg-white">
                    <div className="grid grid-cols-1 lg:grid-cols-2 pt-10">
                
                        <div className="flex flex-row mx-auto px-5 lg:border-2 lg:border-gray-300 lg:rounded-2xl lg:p-5 bg-gray-50">
                            
                          
                            {(users.length>0) ?
                                <div className="mt-5">
                                    <Paginator maxpage={accessPages} 
                                        page={accessPage}
                                        clickHandler={
                                            (page) => {setAccessPage(page);}
                                        }
                                    />
                    
                                    <div className="grid grid-cols-4 border-4 border-emerald-600 bg-gray-100 rounded-md p-2 gap-4 ">
                                        
                                        <p className="text-xl font-semibold text-blue-500 text-center">User</p>
                                        <p className="text-xl font-semibold text-blue-500 text-center">Role</p>
                                        <p className="text-xl font-semibold text-blue-500 text-center">Teacher</p>
                                        <p className=" text-center text-xl font-semibold text-blue-500">Action</p>
                                        
                                        {users.map((user, i) => {
                                            return (
                                                <>
                                                    <div key={user} className="text-xl text-indigo-500 bg-indigo-100 text-center border-2 border-indigo-800 rounded-md p-2 truncate">{user["username"]}</div>
                                                    
                                                <Dropdown options={options} onChange={(option) => addChangeAccess(user["username"], user["role"], option.label)} value={user["role"]} />
                                                    
                                         {user.role === "TEACHER" &&
                                            <button onClick={() => setTeacher(user.username)}>Teacher dashboard</button>
                                        }
                                        {/*To make sure four elements to make a grid row*/user.role !== "TEACHER" && <div />}
                                     
                                        <button className="text-red-800 font-semibold bg-red-300 border-2 border-red-700" onClick={() => addDeleteUser(user["username"])}>Delete user</button>    
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>
                            :
                                <div className="border-4 border-green-800 rounded-md p-2 gap-4 mt-5 ">
                                    <p className="text-xl text-center mx-auto">No results satisfying search criteria</p>
                                </div>
                            }
                            

                        </div>
                    
                    
                    
                        <div className="flex flex-col m-auto gap-5">

                            {(changeList.length > 0)&&
                                <div className="flex flex-col border-4 rounded-md bg-amber-50 border-amber-600 mt-5 p-2">
                                    <p>Your changes</p>
                                    <div className="grid grid-cols-3">
                                        {changeList.map((change, i) => {
                                            return (
                                                <>
                                                    <div key={change} className="border-2 border-indigo-800 bg-indigo-200 text-indigo-600 font-semibold rounded-md my-5 text-xl mx-auto p-2">{change["username"]}</div>
                                                    <div className="border-orange-800 bg-orange-100 border-2 mx-auto my-5 p-2">{change["action"]}</div>
                                                    <button className="border-red-800 bg-red-100 border-2 text-xl mx-auto my-5 p-2" onClick={() => removeChange(i)}>Remove</button>
                                                </>
                                            )
                                        })}
                                    </div>
                                    
                                    <button className="border-2 border-green-400 bg-green-100 rounded-md my-5 mx-auto p-2" onClick={commit}>Commit changes</button>
                                    
                                </div>
                            }
                            <div className="flex flex-col border-2 border-sky-800 bg-sky-100 p-2 rounded ">
                                <p className="font-semibold mx-auto text-blue-500 text-xl">Access requests</p>
                                <div className="flex flex-row">
                                    <form>
                                        <input className="input input-bordered input-accent m-3" type="text" onChange={(e) => setRequestSearch(e.target.value)} />
                                        <button  className="border-2 border-sky-800 bg-sky-200 rounded-md p-2 font-semibold" onClick={searchRequests}>Search</button>
                                    </form>
                                </div>
                            

                                
                            
                                {(requests.length > 0)?
                                    <>
                                        <Paginator
                                            maxpage={requestPages} 
                                            page={requestPage}
                                            clickHandler={
                                                (page) => {setRequestPage(page);}
                                            }
                                        />
                                        <div className="flex flex-col border-4 border-green-800 rounded-xl p-2 bg-white">
                                            {requests.map((request, _) => {
                                                return (
                                                    <div className="flex flex-row justify-between">
                                                        <div key={request["username"]} className="text-xl text-indigo-500 bg-indigo-100 text-center border-2 border-indigo-800 rounded-md p-2">{request["username"]}</div>
                                                        <div className=" text-center border-2 border-gray-400 p-2 rounded-md">{request["newRole"]}</div>
                                                        {request["newRole"].indexOf("/") === -1 &&
                                                            <div className="flex flex-row">
                                                                <button className="text-green-600" onClick={() => addChangeAccess(request["username"], request["oldRole"], request["newRole"])}>Accept</button>
                                                                <button className="text-red-600" onClick={() => addDenyAccess(request["username"], request["oldRole"], request["newRole"])}>Deny</button>
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </>
                                :
                                    <div className="flex flex-col border-2 border-sky-800 bg-sky-100 p-2 rounded ">
                                    <p className="mx-auto">No requests</p>
                                    </div>
                                }
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={subscribed}
                                        onChange={handleCheck}
                                    />
                                    Subscribe to requests
                                </label>
                            </div>
                            
                            <form className="border-2 border-teal-500 bg-teal-100 rounded-md flex flex-col items-center mb-5">
                                <div>Send an invitation link</div>
                                <input className="input input-bordered input-accent m-3" type="email" placeholder="example@example.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                                {emailError}
                                <button className="mb-2 rounded-md border-2 text-sm font-semibold border-teal-600 bg-teal-200 p-2" onClick={sendEmail}>Send</button>
                            </form>
                        </div>
                        
                
                </div>
                </div>
            }

            {(tabs[activeTabIndex] === 'User activities')&&
            <div className="flex flex-col border-t-0 border-b-2 border-r-2 border-l-2 border-gray-200 rounded-b-xl shadow-xl p-5 bg-white">
                <ActivitiesInterface />
            </div>
            }
        </div>
            
        
        
    )   
}

export default AccessInterface;