import { useEffect, useState } from "react";
import useAuth from "./hooks/useAuth";
//import Overview from "../course/components/Overview";

const NewAssignment = ({successHandler, tag, teacher = ""}) => {
    const {auth, setAuth} = useAuth();
    const [levels, setLevels] = useState([]);
    const [level, setLevel] = useState("");
    const [exercises, setExercises] = useState([]);
    const [addedExercises, setAddedExercises] = useState([]);
    const [title, setTitle] = useState("");

    const fetchData = async (route, method, body = null) => {

        const token = localStorage.getItem("token");
        const url = `${process.env.REACT_APP_USER_API_URL}/teacher/${route}` + ((teacher !== "") ? `?teacher_name=${teacher}` : "")
        let meta = {
            method: method,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }
        if (body) {
            meta.body = JSON.stringify(body);
        }
        const response = await fetch(url, meta);
        if (response.status === 401) {
            localStorage.removeItem("token");
            setAuth({});
            return null;
        }
        if (response.ok) {
            const json = await response.json();
            return json;
        }
        return null
    }

    const getLevels = async () => {
        const json = await fetchData(`exercise-levels/${tag}`, "GET");
        if (json) {
            setLevels(json);
        }
    }

    const getExercises = async () => {
        if (level !== "") {
            const json = await fetchData(`exercises/${tag}/${level}`, "GET");
            if (json) {
                setExercises(json);
            }
        }
    }
    
    const addExercise = (exercise) => {
        setAddedExercises([...addedExercises, exercise]);
    }

    const removeExercise = (exercise) => {
        let array = [...addedExercises];
        const i = addedExercises.indexOf(exercise);
        array.splice(i, 1);
        setAddedExercises(array);
    }

    const postAssignment = async (event) => {
        event.preventDefault();
        await fetchData(`assignment/${tag}`, "POST", {
            title: title,
            exercises: addedExercises
        });
        successHandler(false);
    }

    useEffect(() => {getLevels()}, []);
    useEffect(() => {getExercises()}, [level]);

    return (
        <div>
            <button className="btn btn-circle btn-outline"
                onClick={()=> {successHandler(false)}}      
            ><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" /></svg>
            </button>
            <div className="flex flex-row justify-around">
                {level === "" &&
                    <div className="flex flex-col">
                        <b>Levels</b>
                        <div className="flex flex-col border border-4">
                            {levels.map(level => {
                                return <button onClick={() => {setLevel(level)}}>{level}</button>
                            })}
                        </div>
                    </div>
                }
                {level !== "" &&
                    <div className="flex flex-col">
                        <button className="border" onClick={() => setLevel("")}>Back</button>
                        <b>{level} exercises:</b>
                        <div className="flex flex-col border border-4">
                            {exercises.map(exercise => {
                                return <div className="flex flex-row justify-between">
                                    <div>{exercise}</div>
                                    <button onClick={() => addExercise(exercise)}>Add</button>
                                    {addedExercises.includes(exercise) &&
                                        <button onClick={() => removeExercise(exercise)}>Remove</button>
                                    }
                                </div>
                            })}
                        </div>
                    </div>
                }
                <div className="flex flex-col">
                    <div>Added exercises:</div>
                    <div className="flex flex-col border border-4">
                        {addedExercises.map(exercise => {
                            return <div className="flex flex-row">
                                <div>{exercise}</div>
                                <button onClick={() => removeExercise(exercise)}>Remove</button>
                            </div>
                        })}
                    </div>
                    <form className="flex flex-col">
                    <div>Title:</div>
                    <input className="border" type="text" value={title} onChange={e => setTitle(e.target.value)} />
                    <button onClick={e => postAssignment(e)}>Submit</button>
                    </form>
                </div>
            </div>

            {/*<Overview inL1="Other" inL2="English" />*/}
        </div>
    )
}

export default NewAssignment;