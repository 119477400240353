import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const Verify = () => {
    let {id, code} = useParams();
    
    const [verified, setVerified] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_USER_API_URL}/users/verify/${id}/${code}`, {method: "POST"}).then((response) => {
                return response.json();
            }).then((data) => {
                setVerified(data.verified);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <div>Loading...</div>
    }

    return(
        <div>
            {
                verified ?
                "Verification complete" :
                "Invalid verification code"
            }
        </div>
    );
};

export default Verify;
