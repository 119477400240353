import React from "react";

const CategoryDropdownStructure = ({ selectHandler, elValue}) => {
    const CourseStructures = ["Category", "Lesson"]
    
return (
    
    <select value={elValue} 
    name="category" 
    id="category" 
    className="mr-2 ml-2 text-left py-2 px-4 text-lg  bg-cyan-100  border-4  border-teal-400 text-black-500 bg-white border rounded-md 
                shadow-sm outline-none appearance-none focus:border-teal-600" 
    onChange={selectHandler}
>


{CourseStructures.map(
    (les,i) => 
        {
            return (
                <option value={les} key={les}>
                    {les}
                </option>
            );
        }
    )
}           
</select>
    
    
    
    
    
   
  );
};


export default CategoryDropdownStructure;
