/* Hooks */
import {useState, useEffect, useRef} from 'react'
import useAuth from '../../Users/hooks/useAuth';
import useActivity from '../../Users/hooks/useActivity';

/* Components */
/* Top */
import CategoryDropdownL1 from "./CategoryDropdownL1"; /* Switch between mother tongues*/
import CategoryDropdownL2 from './CategoryDropdownL2';


/* Two major views */

/* List of cards */
import Cards from "./Card/Cards";  
/* Exercise view, CardExercise is general for all languages except for Norwegian and English */
import CardExercise from './Card/Exercises/CardExercise';
import CardExerciseNorwegianTestOnly from './Card/Exercises/CardExerciseNorwegianTestOnly';
import CardExerciseEnglish from './Card/Exercises/CardExerciseEnglish';
import AccessInterface from '../../Users/AccessInterface';
import UserSettings from '../../Users/UserSettings';
import CategoryDropdownStructure from './CategoryDropdownStructure';
import TeacherInterface from '../../Users/TeacherInterface';
import SelectClass from '../../Users/SelectClass';




const Course = ({inL1, inL2}) => {
    const { auth, setAuth } = useAuth();
    const {activity, setActivity } = useActivity();
    const [L1Lang, setL1Lang] = useState(inL1)
    const [L2Lang, setL2Lang] = useState(inL2)
    const [Categories, setCategories] = useState([])
    const [L2Num, setL2Num] = useState('');
    const [L1Num, setL1Num] = useState('');
    const [CourseStructure, setCourseStructure] = useState('Category')
    const [L1L2List, setL1L2List] = useState([]);
    const [AllSounds, setAllSounds] = useState([]);
    const [adminPage, setAdmin] = useState(false);
    const [teacherPage, setTeacher] = useState(false);
    const [userSettings, setSettings] = useState(false);
    const [showExercise, setShowExercise] = useState(null);
    const [lastShownExercise, setLastShownExercise] = useState(null);
    const [message, setMessage] = useState(null)
    const [classesPage, setClasses] = useState(false);
    
    const itemsRef = useRef(null);
  
    //console.log('JOY: front page, level 0')

    function scrollToId(itemId) {
        
        const frames = [ 
            { transform: "translateX(2em)"},
            { transform: "translateX(-2em)"},
            { transform: "translateX(0)"},
      
        ]    
        const map = getMap();
        const node = map.get(itemId);
        
        if (node) {
        
        node.scrollIntoView({
            behavior: 'auto',
            block: 'start',
            inline: 'nearest'
        });

        node.animate(  
            frames ,{ duration: 1500 , fill: "forwards", iterations: 1 } 
              );
            }
       
        }
  
    function getMap() {
      if (!itemsRef.current) {
        // Initialize the Map on first usage.
        itemsRef.current = new Map();
      }
      return itemsRef.current;
    }

    const changeL1 = async (lang) => {
        const token = localStorage.getItem("token");
        setL1Lang(lang);
        if (auth?.email !== "guest") {
            const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/users/update-l1/${lang}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            if (response.status === 401) {
                logout();
            }
        }
    }

    const changeL2 = async (lang) => {
        const token = localStorage.getItem("token");
        setL2Lang(lang);
        if (auth?.email !== "guest") {
            const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/users/update-l2/${lang}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            if (response.status === 401) {
                logout();
            }
        }
    }

    

    useEffect(()=>{
        fetch(`${process.env.REACT_APP_API_URL}/list_sounds`)
            .then(response=> response.json())
            .then(json=>{    
            var products = [];
            if (json.result.length > 0) {
                for (const lang of json.result) {
                      products.push(lang.ipa.trim())
                }
            }
           
            setAllSounds(products);     
            })          
    },[]) 

    useEffect(()=>{
        fetch(`${process.env.REACT_APP_API_URL}/list_languages`)
            .then(response=>response.json())
            .then(json=>{
                
                const filtered = json.result.filter(a => a.name.trim() === L1Lang.trim())
                if (filtered.length  === 1 ) {
                    setL1Num(filtered[0].pk);
                }
                const filteredL2 = json.result.filter(a => a.name.trim() === L2Lang.trim())
                if (filteredL2.length  === 1 ) {
                    setL2Num(filteredL2[0].pk);
                }
                
            
            })          
    },[L1Lang,L2Lang]) 

    useEffect(()=>{
        
        if (auth?.email) {
        const token = localStorage.getItem("token");
        fetch(`${process.env.REACT_APP_USER_API_URL}/stats/haloinfo/${L2Lang}/${auth.email}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        })
            .then(response=>response.json())
            .then(json=>{
                var act = json
                setActivity(act)
               
               
            })     
        } 
         
      
    },[auth, L2Lang, showExercise]) 


    useEffect(()=>{
        fetch(`${process.env.REACT_APP_API_URL}/list_units/${L2Lang}/${CourseStructure}`)
            .then(response=>response.json())
            .then(json=>{
                setCategories(json)
            })          
    },[L2Lang, CourseStructure]) 


    useEffect(()=>{
        fetch(`${process.env.REACT_APP_API_URL}/compare?language_1_id=${L1Num}&language_2_id=${L2Num}`)
            .then(response=>response.json())
            .then(json=>{
                if (L1Num === L2Num) 
                { 
                    setL1L2List([])
                }
                else 
                {
                var products = [];
               if (json.result.length > 0) {
                    for (const lang of json.result) {
                      products.push(lang.trim())
                      }
                }
                
                setL1L2List(products);
                }
            })          
    },[L1Num,L2Num])  

    useEffect(() => {
        if (auth?.email !== "guest") {
            const token = localStorage.getItem("token");
            fetch(`${process.env.REACT_APP_USER_API_URL}/users/get-l1`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                if (response.ok) {
                    return response.json();
                }
                if (response.status === 401) {
                    logout();
                }
            }).then(json => {
                setL1Lang(json["l1"]);
            });
        }
    });

    useEffect(() => {
        if (auth?.email !== "guest") {
            const token = localStorage.getItem("token");
            fetch(`${process.env.REACT_APP_USER_API_URL}/users/get-l2`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                if (response.ok) {
                    return response.json();
                }
                if (response.status === 401) {
                    logout();
                }
            }).then(json => {
                if (json) {
                    setL2Lang(json["l2"]);
                }
            });
        }
    });

    const logout = () =>{
        localStorage.removeItem("token");
        setAuth({});
    }

    if (classesPage) {
        return <SelectClass successHandler={(state) => setClasses(state)} />
    }

    if (userSettings) {
        return <UserSettings successHandler={(state) => setSettings(state)} />
    }

    if (adminPage) {
        return <AccessInterface successHandler={(state) => setAdmin(state)} />
    }

    if (teacherPage) {
        return <TeacherInterface successHandler={(state) => setTeacher(state)} />
    }

    return (
        
        showExercise ?
                        <div>
                        {L2Lang === 'Norwegian' ?
                        <CardExerciseNorwegianTestOnly 
                                            exercise={showExercise} 
                                            l2={L2Lang} 
                                            exerciseHandler={(state) =>
                                                            {
                                                                setShowExercise(state);
                                                            } }  
                        />
                        :
                            L2Lang === 'English' ?
                            <CardExerciseEnglish 
                                exercise={showExercise} 
                                l2={L2Lang} 
                                exerciseHandler={(state) =>
                                    {
                                        setShowExercise(state);
                                    } }  
                            /> 
                                :
                        
                                    <CardExercise exercise={showExercise} l2={L2Lang} exerciseHandler={(state) =>
                                                                        {
                                                                            setShowExercise(state);
                                                                        } }  /> }
                        </div>
        :
                        <div className="flex flex-col overflow-hidden md:overflow-auto">

                            {/* top */}                                                
                            <div className="w-full grid grid-cols-1 lg:grid-cols-2 content-stretch font-mono sm:mx-auto bg-cyan-50  p-2 shadow-md">
                               
                                {/* login user block */}   
                                
                                    <div className='flex flex-col md:flex-row p-2 gap-2 items-center md:items-start'>
                                        
                                       
                                            
                                            
                                            
                                            {auth?.username && (
                                                
                                                <button   className="text-xl border-amber-500 border-2 rounded-md bg-amber-300 p-2"
                                                            onClick={logout}
                                                >
                                                    Logout 
                                                </button>
                                                
                                            )}
                                        
                                        

                                        
                                            {auth?.username &&
                                                 <button   className='border-2 border-amber-500 border rounded-md p-2 text-xl' 
                                                            onClick={() => 
                                                                    {
                                                                        if (auth.username !== 'guest')
                                                                            {setSettings(true)}
                                                                        else
                                                                            {setMessage('Please register!')
                                                                                setTimeout(() => {setMessage(null)},1000)
                                                                            }
                                                                    }
                                                                    
                                                                    }>
                                                    {message ? <p className='text-red-500 font-bold text-xl'>{message}</p> : auth?.username}
                                                    </button>
                                                }
                                        

                                        
                                       
                                            {auth?.role === "ADMIN" &&
                                                
                                                    
                                                        <button className="text-xl border-indigo-500 border-2 rounded-md bg-indigo-300 p-2" onClick={() => setAdmin(true)}>Admin</button>
                                                    
                                            }

                                            {/*["TEACHER", "ADMIN"].includes(auth?.role) &&
                                                <button className="text-xl border-indigo-500 border-2 rounded-md bg-indigo-300 p-2" onClick={() => setTeacher(true)}>Teacher</button>
                                            */}

                                            {/* auth?.email !== "guest" &&
                                                <button onClick={() => setClasses(true)}>Classes</button>
                                            */}
                                    </div>
                                
                            
                                <div className="scale-75 sm:scale-100 flex flex-col lg:flex-row  gap-1  bg-inherit">
                             


                                    <div className='flex flex-col'>

                                        
                                        <CategoryDropdownL2 selectHandler={(event) => {
                                                                            changeL2(event.target.value);
                                                                            }}
                                                            elValue={L2Lang}/>
                                        <p className='sm:mx-auto text-md font-mono font-semibold text-blue-400'>
                                            Language course
                                        </p>
                                    </div>

                                    <div className='flex flex-col'>
                                        <CategoryDropdownL1 
                                            selectHandler={(event) => {
                                                changeL1(event.target.value);
                                            }}
                                            elValue={L1Lang}
                                        />
                                        <p className='sm:mx-auto text-md font-mono font-semibold text-blue-400'>
                                            Native language
                                        </p>
                                    </div>
                                    <div className='flex flex-col'>
                                        
                                        <CategoryDropdownStructure selectHandler={(event) => {
                                                setCourseStructure(event.target.value);
                                            }}
                                            elValue={CourseStructure}/>
                                         <p className='sm:mx-auto text-md font-mono font-semibold text-blue-400'>Course structure</p>

                                    </div>
                                </div>
                            </div>

                            <div className='flex flex-col'>
                                
                                <div className='flex flex-col lg:flex-row'>
                                    <div className='md:basis-1/5 invisible h-5 md:h-full md:visible md:pl-10'>
                                    
                                            <div className='mt-10 p-2 flex flex-col border-2 rounded-xl border-purple-300'>
                                                <p className='text-2xl font-semibold text-center border-2 border-purple-400 bg-purple-100 rounded-xl p-2 text-blue-600'>Sections </p>
                                                <div className='px-2 text-left pt-5'>
                                                    
                                                    {Categories.map(
                                                            (category,i) => 

                                                                {   
                                                            
                                                                let cat = category.split('_')[0]
                                                            
                                                                let key_value = 'index'+category
                                                                    return (
                                                                        <div    className='py-3'
                                                                                key={key_value}
                                                                        >
                                                                        <button className='hover:text-blue-400 text-xl text-center font-medium'
                                                                                onClick={() => {scrollToId(category)}}
                                                                        >
                                                                        {cat} 
                                                                        </button>
                                                                        </div>

                                                                    )
                                                                    }
                                                    )
                                                    }
                                                </div>
                                            </div>
                                    
                                    </div>
                                    <div className='w-full px-2 md:px-0 md:basis-4/5 md:pr-10'>
                                        <div className='h-full w-full  md:h-screen flex flex-col md:overflow-y-scroll mx-auto  gap-40 mt-10 pb-10'> 
                                        
                                        
                                        
                                            
                                        
                                            {Categories.map(
                                                (category,i) => 
                                                    {   
                                                        
                                                        return (
                                                            <div    key={category} 
                                                                    ref={(node) => {
                                                                        const map = getMap();
                                                                        if (node) {
                                                                        map.set(category, node);
                                                                        } else {
                                                                        map.delete(category);
                                                                        }
                                                                    }}
                                                            >                   
                                                                
                                                                
                                                                    
                                                                    <Cards  L1L2List={L1L2List} 
                                                                            L2Lang={L2Lang} 
                                                                            AllSounds={AllSounds} 
                                                                            unit={category} 
                                                                            units={CourseStructure}
                                                                            exerciseHandler={(state) =>
                                                                                {
                                                                                    setShowExercise(state);
                                                                                    setLastShownExercise(state);
                                                                                } }
                                                                            focusId={lastShownExercise ? 
                                                                                (lastShownExercise[CourseStructure] === category) ? category+'_'+lastShownExercise.Group_lesson : null
                                                                                : null  
                                                                        
                                                                            }
                                                                    /> 
                                                                
                                                            </div>
                                                        );
                                                    }
                                                )
                                            }        
                                        </div>
                                    </div>                
                                </div>
                            </div>
                        </div>
    )
}

export default Course;