import React from "react";
import useSWR from "swr";
const fetcher = (...args) => fetch(...args).then((res) => res.json());

const CategoryDropdownL1 = ({ selectHandler, elValue}) => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API_URL}/list_languages`,
    fetcher,   {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
    
  ); 
  
  if (error) return ( 
    <select className="mr-2 ml-2 px-2 text-black-500 bg-white border rounded-md shadow-lg outline-none appearance-none focus:border-gray-600">
   
                <option value="Other" key="newother">
                 
                 L1L2map is unavailable
                
                </option>
    </select>
              )

  if (!data) return ( 
    <select className="mr-2 ml-2 px-2 text-black-500 bg-white border rounded-md shadow-lg outline-none appearance-none focus:border-gray-600">
   
                <option value="Other" key="newother2">
                 
                 Loading list...
                
                </option>
    </select>
              )


  return (
    <select className="w-24 mr-2 ml-2 px-2 text-clip  text-black-500  bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-gray-600"
      onChange={selectHandler}
      value={elValue}
    >
     
     {data.result&&data.result.map((lang,i) => {
        const key_value =  String(lang.pk)+String(i)
  
        return (
          <option value={lang.name} key={key_value}>
            {lang.name}
          </option>
        );
      })}
    </select>
  );
};


export default CategoryDropdownL1;
