import { Tooltip} from "@material-tailwind/react";
import { useMemo, useRef, useState, useCallback, useEffect } from "react";
import PaginatorExercise from "../PaginatorExercise";


const DisplayWord= ({ tracks }) => {
  
  const [playNext, setPlayNext] = useState(true)
  const [track, setTrack] = useState(1)
  const [buttonState, setButtonState] = useState(true)
  const [playState, setPlayState] = useState(true)
  const [playnow, setPlayNow] = useState(false)
  const [wordbefore, setWordBefore] = useState("Write the word")
  
  const no_frames = [ { color: "red" },
  { transform: "scale(1.05)", color: "red" },
  { transform: "translateX(-5%)", color: "red" }, 
  { transform: "translateX(5%)", color: "red" }, 
  { transform: "scale(1.05)", color: "red" },
  { transform: "scale(1.0)" , color: "inherit"},
  ]

  const yes_frames = [ { color: "green" },
  { transform: "scale(1.05)", color: "green" },
  { transform: "rotate(0.02turn)" },
  { transform: "rotate(-0.02turn)" },
  { transform: "scale(1.05)", color: "green" },
  { transform: "scale(1.0)" , color: "inherit"},
  ]
  const inputRef_0 = useRef(null);
 

  let choice = tracks[track-1]
  let wordToCompare = choice.word;
  let audio_0 = useMemo(() => new Audio(choice.audio),[choice]);
 
  
  const nameRef = useRef();
  const [compared, setCompared] = useState(false)
  
  

  const handleSubmit = (event) => {
    event.preventDefault();
    setCompared(true)
    setPlayNext(false)
    if (nameRef.current.value === wordToCompare){
      nameRef.current.animate(  
        yes_frames,{ duration: 900 , fill: "forwards", iterations: 1 } 
      );
    
    
    }
    else
    {
    
      nameRef.current.animate(  
        no_frames,{ duration: 900 , fill: "forwards", iterations: 1 } 
      );
    
    
    }
  }



  const Playit_0 = () => {
    
    if (nameRef.current){nameRef.current.focus()}
    
    var audio_duration = audio_0.duration ? audio_0.duration : 1
    console.log("duration", audio_duration, audio_0.duration)
    
    inputRef_0.current.animate(  
      frames,{ duration: audio_duration*1000 , fill: "forwards", iterations: 1 } 
    );
    
    audio_0.play();
  };

  const frames = [ { color: "blue" },
  { transform: "scale(1.05)", color: "blue" },
  { transform: "translateY(-10%)", color: "blue" }, 
  { transform: "translateY(0%)" , color: "blue"}, 
  { transform: "scale(1.05)", color: "blue" },
  { transform: "scale(1.0)" , color: "inherit"},
  ]
 

  

  useEffect(()=>{
    
    setCompared(false);
    if (nameRef.current){
      nameRef.current.value ="";
    
    }
    let mounted = true
   
    
    
    
    
    const handleCanPlayThrough = () => {
      var audio_duration = audio_0.duration ? audio_0.duration : 1
      audio_0.removeEventListener('canplaythrough', handleCanPlayThrough);
      console.log('Audio can be played through.');
      if (mounted)
      {
      setTimeout(() => {
        audio_0.play();
        if (inputRef_0.current){
        inputRef_0.current.animate(  
          frames,{ duration: audio_duration*1000 , fill: "forwards", iterations: 1 } 
        );
        }
        
        
      }, audio_duration ) 
      }
      
    };

    if (playnow === true ) {
      
      setTimeout(()=> {
        
        audio_0.addEventListener('canplaythrough', handleCanPlayThrough);
        audio_0.addEventListener("ended", ()=>{setButtonState(false); if (nameRef.current){nameRef.current.focus()} })
        
        audio_0.currentTime = 0; 
        
        
      }  , 300);
    }
    else
    {
      setPlayState(false)
   

    }
    return () => {
      mounted = false
    
    }
  },[track, audio_0, playnow, playState])


 

  
  return (
    <div className="flex flex-col py-5 ">
   
      <div className="flex py-1 mx-auto">
      <button disabled={playState ? true : false} 
        className={`bg-blue-100 border-2 border-blue-300 rounded-xl text-gray-600 hover:text-red-600 font-bold py-1 px-2 text-xl ${
          playState
          ? "opacity-0"
          : "opacity-100"
      }`}
        onClick={()=>{setPlayNow(true); setPlayState(true)}}
      >
        Start 
      </button>
      </div>
      
    
      <div className={`w-full flex py-5 flex-cols-3 ${
        playState
        ? "opacity-100"
        : "opacity-50"
    }`}
      >
      
        <Tooltip className="flex flex-col bg-indigo-300 font-medium text-white rounded-full px-3"
              animate={{
                mount: { scale: 1.2, y: 0 },
                unmount: { scale: 0, y: 25 },}} 
              content={playNext === false ? choice.translation : null}
              
        >
          <button disabled={buttonState} 
          className={` mx-auto 
          ${ compared ?
          "bg-transparent hover:bg-indigo-100 hover:border rounded-full hover:shadow-xl font-serif:Times text-3xl font-semibold text-gray-600 hover:text-blue-600"
        : "py-1 px-2 bg-blue-100 border-2 border-blue-300 rounded-xl text-gray-600  font-serif:Times text-3xl font-semibold hover:text-blue-600"}`}

          onClick={Playit_0}>
          
            <p className="text-center" ref={inputRef_0} >{compared ? choice.word : wordbefore } </p>
          
          </button>
        </Tooltip>
        </div>
        
        <div>
        
        <form className='flex flex-row py-5 md:gap-x-2 mx-auto scale-75'
              onSubmit={handleSubmit}
        >
              
          <input  className={`font-medium md:text-3xl text-xl rounded-md text-center border-2 
          ${ buttonState ? 'bg-gray-100 border-gray-300 text-blue-500' : 'bg-blue-100 border-blue-300 text-gray-600'} `}
          
          id="name" type="text"  
          
          
          ref={nameRef} />
          
          <button disabled={buttonState} 
            className={`border-2 border-blue-500 m-auto rounded-md text-lg font-medium text-gray-600 p-2 ${ buttonState ? '' : 'bg-blue-100'}`}
          
          
          
            type="submit"> Check </button>
          
        </form>
        
      </div>
        <div className='scale-75'>
          <PaginatorExercise maxpage={Math.ceil(tracks.length)} page={track} offState={playNext}
                  clickHandler={(page) => {
                      setTrack(page);
                      setButtonState(true);
                      setPlayState(true);
                      setPlayNext(true);
                      
                  }}
          />
        </div>
      
        
      </div>
    
  );
};
export default DisplayWord;