import {useState, useEffect} from 'react'
import DisplayTrackMP from './DisplayTrack/DisplayTrackMP';
import DisplayTrackNW from './DisplayTrack/DisplayTrackNW';
import Paginator from '../../../Paginator';

import { Link } from 'react-router-dom';
const CardPlayer = ({id, l2}) => {
    const [PlayList, setPlayList] = useState([])
    const [PlayListNW, setPlayListNW] = useState([])
    const [info, setInfo] = useState({})
    const [pageMP,setPageMP] = useState(1)
    const [pageNW,setPageNW] = useState(1)
    const NumberPairs = 5                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
    const [chunkPlayListNW, setChunkPlayListNW]  = useState([])  
    const [chunkPlayListMP, setChunkPlayListMP]  = useState([])  
  

    let data_source =  `${process.env.REACT_APP_API_URL}/exercise/${l2}/${id}`             
    
  
    const [status, setStatus] = useState(null);
    useEffect(()=>{
        const fetchAssets = async () => {
            setStatus('IN_PROGRESS');
            try { await fetch(data_source)
                .then(response=>response.json())
                    .then(json=>{
                        var products = [];
                        for (const lang of json.MP_wordpairs) {
                            products.push({"pair_0":{"track_0":lang[0].audio_0, "track_1":lang[0].audio_1, "word": lang[0].word, "translation": lang[0].Translation }, "pair_1":{"track_0":lang[1].audio_0, "track_1":lang[1].audio_1, "word": lang[1].word, "translation": lang[1].Translation}})
                        }
                        setPlayList(products)

                        var nwproducts = [];
                        for (const lang of json.MP_nonwords) {
                            nwproducts.push({"track_0":lang.audio_0, "track_1":lang.audio_1, "word": lang.word, "translation": lang.Translation, "nonword": lang.nonword, "nonword_translation": lang.PairWordTranslation})
                        }
                    
                        setPlayListNW(nwproducts)
                        let info_json = {"name": json.Exercise_name, "group": json.Group_lesson, "type": json.Type_description}
                        setInfo(info_json)
                        setStatus('SUCCESS')
                    })
                    
                }
                catch (e) {
                    setStatus('FAILURE')
                    
                }

        }
        fetchAssets();
        
        },[id,l2]) 
   
    useEffect(()=> { setChunkPlayListMP(PlayList.slice((pageMP-1)*NumberPairs,pageMP*NumberPairs))  },[pageMP, PlayList])

    useEffect(()=> { setChunkPlayListNW(PlayListNW.slice((pageNW-1)*NumberPairs,pageNW*NumberPairs))  },[pageNW, PlayListNW])

    

   
    
  return (
       
       
        
       (status === "SUCCESS") ?
            <div className="flex flex-col flex-grow bg-FarmWhite  transition items-center ease-in-out">
                <div className='flex flex-col  bg-FarmWhite transition  items-center ease-in-out'>
                    <div className="pb-2 font-bold text-lg text-blue-600 my-1">{info.name}</div> 
                </div>        
                <div className="grow grid grid-cols-1 md:grid-cols-2 gap-2 px-2"> 
                                
                    {(() => {
                        if (chunkPlayListMP.length > 0){
                            return (                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
                                <div className="border-dotted border-2 border-indigo-600 rounded-xl px-2 bg-FarmWhite transition  items-center  ease-in-out">
                                    
                                    <div className="font-semibold text-center p-2 shadow-md font-serif:'Times New Roman' text-md text-FarmNavy  bg-FarmWhite rounded-lg"> 
                                        Minimal Pairs
                                    </div>

                                    {chunkPlayListMP.map((item,i) => {
                                        return <div key={i}><DisplayTrackMP  currentTrack={item} /> </div>;                                                                                                                                                                                                     
                                    })}

                                    <div className='scale-50'>
                                        <Paginator maxpage={Math.ceil(PlayList.length/NumberPairs)} page={pageMP}
                                                clickHandler={(page) => {
                                                    setPageMP(page)
                                                }}
                                        />
                                    </div>
                                </div>
                            )
                        }
                        
                        return null;
                    })()}    

                    {(() => {
                        if (chunkPlayListNW.length > 0){
                            return (
                                <div className="border-dotted border-2 border-indigo-600 rounded-xl px-2  bg-FarmWhite transition  items-center  ease-in-out">
                                        
                                    <div className="font-semibold text-center p-2 shadow-md font-serif:'Times New Roman' text-md text-FarmNavy  bg-FarmWhite rounded-lg">
                                        Minimal Pairs NW
                                    </div>

                                    {chunkPlayListNW.map((item,i) => {
                                            return  <div key={i}><DisplayTrackNW  currentTrack={item} /></div>;
                                        })}
                                    <div className='scale-50'>
                                        <Paginator maxpage={Math.ceil(PlayListNW.length/NumberPairs)} 
                                                    page={pageNW}
                                                    clickHandler={(page) => {
                                                        setPageNW(page)
                                                    }}
                                        />  
                                    </div>
                                </div>
                            )
                        }
                        
                        return null;
                    })()}     
                </div>

            </div>
        :    
        
        (status === "FAILURE")?  
            <div className='border-2 flex flex-col'>
                <div className='mx-auto text-xl text-red-500'>Error with data catching, check</div>
                <Link   className='border-2  rounded-md p-2 mx-auto text-red-500 text-2xl' 
                        to={`${process.env.REACT_APP_API_URL}/exercise_raw/${l2}/${id}`} 
                        target="_blank"
                >
                   the raw data
                </Link>
            </div>
        : 
            <div className='flex flex-col mx-auto text-md'>Loading...</div> 

        
        

  
  
   
    )
}


export default CardPlayer