import React from "react";
import useSWR from "swr";
const fetcher = (...args) => fetch(...args).then((res) => res.json());


const CategoryDropdownSound = ({ selectHandler, elValue, course, level, l1l2map }) => {
  
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API_URL}/distinct_field?field=Group_lesson&course=${course}&level=${level}`,
    fetcher,  {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  ); 
  
  if (error) return <div>`failed to load {process.env.REACT_APP_API_URL}`</div>;
  if (!data) return <div>loading...</div>;

  return (
    <select className="mr-2 ml-2  text-black-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-gray-600"
      onChange={selectHandler}
      value={elValue}
    >
      <option value="">All sounds</option>
      {data.filter(val => l1l2map.includes(val.trim())).length !== 0 ? data.filter(val => l1l2map.includes(val.trim())).map((lang) => {
        return (
          <option value={lang} key={lang}>
            {lang}
          </option>
        );
      }): <option value="" key="">No sounds </option>  }
    </select>
  );
};

CategoryDropdownSound.defaultProps = {
  elValue: "",
  course : "",
  level: "",
  l1l2map: [],
  

};
export default CategoryDropdownSound;
