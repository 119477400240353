import DisplayRecorder from "./DisplayRecorder";

import { useEffect, useState } from "react";



const ListenAndSpeak = ({tracks, resultsHandler, nextTab, nextExercise, progress, setProgress, tab}) => {
    const [isgranted, setIsGranted] = useState(false)
    const [media, setMedia] = useState()

    const getMicrophonePermission = async () => {
        if ("MediaRecorder" in window) {
            try {
                const streamData = await navigator.mediaDevices.getUserMedia({
                    audio: true,
                    video: false,
                });
                setIsGranted(true);
                setMedia(streamData)
                
                
            } catch (err) {
                alert(err.message);
            }
        } else {
            alert("The MediaRecorder API is not supported in your browser.");
        }
    };
    // console.log(tracks, "tracks LS")
   

    useEffect(() => {
        if (navigator) {
        navigator.permissions
            .query({ name: "microphone" })
            .then(async function (result) {
            if (result.state === "granted") {

                //If granted then you can directly call your function here
            
                if ("MediaRecorder" in window) {
                    try {
                        const streamData = await navigator.mediaDevices.getUserMedia({
                            audio: true,
                            video: false,
                        });
                        setIsGranted(true);
                        setMedia(streamData)
                        
                        
                    } catch (err) {
                        alert(err.message);
                    }
                } else {
                    alert("The MediaRecorder API is not supported in your browser.");
                }
            } else if (result.state === "prompt") {
                //If prompt then the user will be asked to give permission
                console.log(prompt)
               
            } else if (result.state === "denied") {
                //If denied then you have to show instructions to enable location
            }
            });
        } else {
        console.log("navigator is not supported by this browser.");
        }
    }, []);

    
    
    return (
        
        <>
        { isgranted ?
        <DisplayRecorder    stream={media} 
                            tracks={tracks} 
                            resHandler={(state) =>
                                {
                                resultsHandler(state)
                                    

                                
                                } 
                            } 
                            nextTab = {(state) =>
                            {
                                nextTab(state)
                            }}
                            nextExercise = {nextExercise}

                            progress={progress}
                            setProgress={setProgress}
                            tab={tab}
                            
        />
        :
        <div className="flex flex-col m-auto p-7">     

            <p>Enable your micropohone for this exercise.</p>

            <button className="bg-pygblue shadow-md text-white m-auto p-3 mt-3 rounded-full hover:bg-blue-500" onClick={getMicrophonePermission} type="button">
            Get Microphone
            </button>

        </div>

        }
        </>
    )

}

export default ListenAndSpeak;