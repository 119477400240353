
const sortingShuffle = (arr) => {
    let newArr = arr.slice()
    const randomInt = (k) => Math.floor((k + 1) * Math.random());
    for (let i = newArr.length - 1; i > 0; i--) {
      const j = randomInt(i);
      [newArr[i], newArr[j]] = [newArr[j], newArr[i]];
    }
    return newArr;
  };
export default sortingShuffle;