import React from 'react'
import TwoStressOne from '../Exercises/StressButton/TwoStressOne';
import TwoStressTwo from '../Exercises/StressButton/TwoStressTwo';
import TwoStressFull from '../Exercises/StressButton/TwoStressFull';
import ThreeStressFull from '../Exercises/StressButton/ThreeStressFull';


const CardOtherContrastsGroup = ({exercise, exHandler}) => {
  // console.log('JOY: other types of exercises, front page, level 2, exercise name: ', exercise.Group_name)
  const test = exercise.dictionary
  const names = Object.keys(test);
  let dum =  []
  names.forEach((key, index) => {
    dum.push(test[key])
  });
 
  
    return (
          <div className='flex flex-grow flex-col gap-y-5'>
                         
            <div  
                  className="flex flex-col   
                              mx-auto py-10 
                               rounded-md 
                               duration-300 font-mono gap-y-7"
            >
            
            {dum.map(
              (el, index)=>{ 
               
                  return (
                              el[0].MP_wordpair ?
                                <div  key={String(index) + String(el[0].Exercise_name)}  className='flex flex-row  flex-grow  justify-center'>
                                  <div  className="tooltip tooltip-right tooltip-accent" 
                                        data-tip={`${ el[0].Exercise_name === "first-second-third" ?  String(el[0].MP_wordpair[0].word) + String('-') + String(el[0].MP_wordpair[1].word) + String('-') + String(el[0].MP_wordpair[2].word) :  String(el[0].MP_wordpair[0].word) + String('-') + String(el[0].MP_wordpair[1].word)} `} 
                                  >
                                    <div  
                                          
                                          onClick={() => {exHandler(el[0]);} } 
                                    >
                                      
                                      {el[0].Category.split('_')[0] === 'Word stress' ?
                                      
                                      <>
                                      {el[0].Exercise_name === "first-second" ?
                                       <button className='hover:bg-blue-100 bg-blue-200 p-5 rounded-xl shadow-xl'>
                                         <TwoStressFull />
                                      </button>
                                      : 
                                      el[0].Exercise_name === "first-second-third" ?
                                      <button className='hover:bg-blue-100 bg-blue-200 p-5 rounded-xl shadow-xl'>
                                        <ThreeStressFull />
                                      </button>
                                      :
                                      <div></div>
                                      
                                      }
                                      </>



                                      :
                                       <button  className="shadow-xl text-blue-700 font-medium text-2xl hover:text-3xl  py-2 px-4 border  border-blue-500 hover:border-blue-600 rounded-full hover:bg-green-500" 
                                                style={{backgroundColor : "lightskyblue" }}
                                      >
                                        { el[0].Exercise_name}   
                                      </button>
                                      
                                      
                                      
                                      }
                                    </div>
                                  </div>
                                </div>
                              :
                              <div key={String(index) + String(el[0].Exercise_name)}  className='flex flex-row flex-grow justify-center'>
                                <div  className="tooltip tooltip-right tooltip-accent" 
                                      data-tip={`${el[0].MP_nonword.word} - *${el[0].MP_nonword.word} `} 
                                >
                                  <div  
                                        onClick={() => {exHandler(el[0]); }} 
                                  >
                                    <button  className="shadow-xl text-blue-700 font-medium text-2xl hover:text-3xl  
                                                        py-2 px-4 border  border-blue-500 hover:border-blue-600 
                                                        rounded-full hover:bg-green-500" 
                                             style={  {backgroundColor : "lightskyblue" } }         
                                    >
                                      {el[0].Exercise_name} 
                                    </button>
                                  </div>
                                </div>
                              </div>

                            )
                            }
              
            )}
          </div>    
          </div>
         
    ) 
}
export default CardOtherContrastsGroup;
