import { Tooltip} from "@material-tailwind/react";
import { useRef } from "react";

const CardSoundGroupButtonMP = ({currentTrack, include, color, title}) => {
    
   
   /* let choices =[[`${process.env.REACT_APP_STATIC_URL}`+currentTrack[0].audio_0.sort(() => 0.5 - Math.random()).slice(0,1), `${process.env.REACT_APP_STATIC_URL}`+currentTrack[1].audio_1.sort(() => 0.5 - Math.random()).slice(0,1)],
    [`${process.env.REACT_APP_STATIC_URL}`+currentTrack[0].audio_1.sort(() => 0.5 - Math.random()).slice(0,1),`${process.env.REACT_APP_STATIC_URL}`+currentTrack[1].audio_0.sort(() => 0.5 - Math.random()).slice(0,1) ]]
    let choice = choices.sort(() => 0.5 - Math.random()).slice(0,1)[0]
    

    const audio_0 = new Audio(choice[0])
    const audio_1 = new Audio (choice[1])
    
    console.log(audio_0, audio_1,audio_0.duration, audio_1.duration, "audio")
    const  PlayBoth = async () => {
        
        
        setTimeout(() => {
            audio_0.play();
            }, 2) 
        
        audio_0.onended=function()
        {
            setTimeout(() => {
            audio_1.play();
            }, 200)   
        }
    };*/

return (
        

            <Tooltip placement="bottom" className="flex flex-col bg-blue-700 font-medium text-white rounded-full px-3"
                animate={{
                mount: { scale: 1.4, y: 0 },
                unmount: { scale: 0, y: 25 },}} 
                content={String(currentTrack[0].word)+"-"+String(currentTrack[1].word)} >
               
                <button  className="shadow-xl text-blue-700 font-medium text-2xl hover:text-3xl  py-2 px-4 border w-16 h-16 border-blue-500 hover:border-blue-600 rounded-full hover:bg-green-500" 
                style={ include ? {backgroundColor : color }:{backgroundColor : "lightgray"} }
                /* onMouseOver={PlayBoth}*/>
                { title } 
                </button>
            </Tooltip> 
    )
}
export default CardSoundGroupButtonMP;