import {useState, useEffect} from 'react'

import sortingShuffle from '../../../shuffle';
import ListenAndClick from './ListenAnd/ListenAndClick';
import Selector from './ExerciseSelector';
import ListenAndClickII from './ListenAnd/ListenAndClickII';
import ListenAndWrite from './ListenAnd/ListenAndWrite';
import ListenAndSpeak from './ListenAnd/ListenAndSpeak';

const ExercisePlayerEnglish = ({id, l2}) => {
   
    const [PlayTracks, setPlayTracks] = useState([])
    const [PlayTracksNW, setPlayTracksNW] = useState([])
    const [info, setInfo] = useState({})
    const tabsData = [  
        {label: 'Listen&Click I'},
        {label: 'Listen&Click II'},
        {label: 'Listen&Speak'},
        {label: 'Listen&Write'},
        
      ];
 
    
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [tabs, setTabs] = useState(tabsData)
    useEffect(() => {
        const tabsData = [  
            {label: 'Listen&Click I'},
            {label: 'Listen&Click II'},
            {label: 'Listen&Speak'},
            {label: 'Listen&Write'},
            
          ];
          const tabsDataNW = [  
            {label: 'Listen&Click II'},
            {label: 'Listen&Speak'},
            {label: 'Listen&Write'},
            
          ];

        if (PlayTracks.length === 0) {setTabs(tabsDataNW)}
                    else {setTabs(tabsData)}
                    
    }, [PlayTracks])
    useEffect(()=>{
        fetch(`${process.env.REACT_APP_API_URL}/english/exercise/${id}`)
            .then(response=>response.json())
                .then(json=>{
        
                    var tracks = [];
                    var nwproducts = [];
                    for (const lang of json.MP_wordpairs) { 
                        console.log(lang, "english lang")     
                        if (lang[0].audio.length > 0 && lang[1].audio.length > 0) {                 
                            var deal_mp = 
                                [
                                    [   {audio : `${process.env.REACT_APP_STATIC_URL}`+ sortingShuffle(lang[0].audio)[0].replace('C:\\CalstFiles\\WordObjectContent','').replace('D:\\Workspace\\CalstFiles\\WordObjectContent',''), word : lang[0].word, translation : lang[0].Translation[lang[0].Translation_description.findIndex(x => x ==='en')]  },
                                        {audio : `${process.env.REACT_APP_STATIC_URL}`+ sortingShuffle(lang[1].audio)[0].replace('C:\\CalstFiles\\WordObjectContent','').replace('D:\\Workspace\\CalstFiles\\WordObjectContent',''), word : lang[1].word, translation : lang[1].Translation[lang[1].Translation_description.findIndex(x => x ==='en')] }
                                    ], 
                                    [   {audio : `${process.env.REACT_APP_STATIC_URL}`+ sortingShuffle(lang[0].audio)[0].replace('C:\\CalstFiles\\WordObjectContent','').replace('D:\\Workspace\\CalstFiles\\WordObjectContent',''), word : lang[0].word, translation : lang[0].Translation[lang[0].Translation_description.findIndex(x => x ==='en')]  },
                                    {audio : `${process.env.REACT_APP_STATIC_URL}`+ sortingShuffle(lang[1].audio)[0].replace('C:\\CalstFiles\\WordObjectContent','').replace('D:\\Workspace\\CalstFiles\\WordObjectContent',''), word : lang[1].word, translation : lang[1].Translation[lang[1].Translation_description.findIndex(x => x ==='en')] }
                                    ]
                                ]
                                
                            tracks.push([sortingShuffle(deal_mp[0]), sortingShuffle(deal_mp[1])[0]])
                       
                            let deal = sortingShuffle([true, false])[0]
                            var deal_nw = sortingShuffle(
                                [   {audio : `${process.env.REACT_APP_STATIC_URL}`+ sortingShuffle(lang[0].audio)[0].replace('C:\\CalstFiles\\WordObjectContent','').replace('D:\\Workspace\\CalstFiles\\WordObjectContent',''), word : lang[0].word, translation : lang[0].Translation, nonword : lang[1].word, nonword_translation: lang[1].Translation[lang[1].Translation_description.findIndex(x => x ==='en')]  , first : deal},
                                    {audio : `${process.env.REACT_APP_STATIC_URL}`+ sortingShuffle(lang[1].audio)[0].replace('C:\\CalstFiles\\WordObjectContent','').replace('D:\\Workspace\\CalstFiles\\WordObjectContent',''), word : lang[1].word, translation : lang[1].Translation, nonword : lang[0].word, nonword_translation: lang[0].Translation[lang[0].Translation_description.findIndex(x => x ==='en')], first : deal}
                                ]
                                );
                            nwproducts.push(deal_nw[0])
                            console.log(deal_mp[0], deal_nw[0], lang[0],lang[1], "dealsmp and nw")
                        }



                    }
                    setPlayTracks(sortingShuffle(tracks))
                    setPlayTracksNW(nwproducts)
                    console.log(PlayTracksNW, "playtracksnw")
                    console.log(nwproducts, "nwproducts")
                    
                    let info_json = {"name": json.Exercise_name, "group": json.Group_lesson, "type": json.Type_description}
                    setInfo(info_json)
                  })          
                 },[id,l2]) 

    
  return (
       
       

    info && 
            <div className="flex flex-col flex-grow px-2 bg-FarmWhite">
                <div className='flex flex-col bg-FarmWhite transition  items-center ease-in-out'>
                    <div className="pb-2 font-bold text-lg text-blue-600 my-1">
                        {info.name}
                    </div>                      
                </div>
                
                <Selector 
                    clickHandler={(tab_index) =>
                            {
                                setActiveTabIndex(tab_index);
                            }
                    } 
                    tabsData={tabs} 
                />
                
                <div className='flex flex-col grow  border-gray-300 border-r-2 border-l-2 border-b-2 '>
                
                    {(tabs[activeTabIndex].label === 'Listen&Click I' && PlayTracks.length > 0 )&&     
                            
                        <ListenAndClick inputtracks={PlayTracks} />
                            
                    }
                        
                    {(tabs[activeTabIndex].label === 'Listen&Click II' && PlayTracksNW.length > 0 ) &&
                                
                            
                            <ListenAndClickII inputtracks={PlayTracksNW} />
                            
                    }


                            
                    {(tabs[activeTabIndex].label === 'Listen&Write' && PlayTracksNW.length > 0)&&    
                            
                            <ListenAndWrite inputtracks={sortingShuffle(PlayTracksNW)} />
                            
                    }
                
                    {(tabs[activeTabIndex].label === 'Listen&Speak' && PlayTracksNW.length > 0)&&    
                            
                            <ListenAndSpeak inputtracks={sortingShuffle(PlayTracksNW)} />
                            
                    }
                        
                </div>
            
    
            </div>
              
    )
}


export default ExercisePlayerEnglish;