import React from 'react'
import { useState } from 'react';
import ExercisePlayerEnglish from './Exercise/ExercisePlayerEnglish';

const CardExerciseEnglish = ({exercise, l2, exerciseHandler}) => {

  const [showInfo, setShowInfo] = useState(false) 

  /* define random audio pick and animation */

  return (
    // Entire wrapper
    <div className='md:flex md:flex-col items-center justify-center mx-auto h-screen md:py-0'> 
          
      {/*The card*/}
      <div className={`h-auto md:h-auto w-full xl:w-3/5  shadow md:mx-auto md:flex md:flex-col md:rounded-3xl bg-gray-50 py-3 animate__animated
      `}> 
      
        
        {/* Main body */}
        <div className='flex flex-col h-full md:h-auto'>
        
            <div className={`flex flex-col
                  ${showInfo ? 'opacity-0 h-0' : 'opacity-100 h-full'} `}
                >

                  {/* three cases -- two stress exercises and just exercise */}
                
                 
                  <ExercisePlayerEnglish id={exercise.id} l2={l2} exerciseHandler={exerciseHandler}/>
                  
            <div/>    
        
          </div>
        </div>    
      </div>
    </div> 

          
  
  ) 

  


    
}
export default CardExerciseEnglish;
