import useAuth from "./hooks/useAuth";
import { useEffect, useState } from "react";
import Dropdown from 'react-dropdown';
import CategoryDropdownL1 from "./CategoryDropdownL1";
import CategoryDropdownL2 from "./CategoryDropdownL2";
import CategoryDropdownRoles from "./CategoryDropdownRoles";

const UserSettings = ({successHandler}) => {
    const {auth, setAuth} = useAuth();
    const [requestRole, setRequestRole] = useState("");
    const [newName, setNewName] = useState("");
    const [l1, setL1] = useState("");
    const [l2, setL2] = useState("");
    const [nameError, setNameError] = useState("");
    const [notifs, setNotifs] = useState([]);

    const requestAccess = async () => {
        const token = localStorage.getItem("token");
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/users/request-access/${requestRole}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        });
        if (response.status === 409) {
            localStorage.removeItem("token");
            setAuth({});
        }
    } 
    
    const updateName = async () => {
        setNameError("");
        const token = localStorage.getItem("token");
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/users/update-username/${newName}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });
        if (response.ok) {
            const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/users/me`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
            });
            if (response.ok) {
                let userData = await response.json();
                userData["token"] = token;
                setAuth(userData);
            }
        } else {
            setNameError(`Username ${newName} is already taken`)
        }
        if (response.status === 401) {
            localStorage.removeItem("token");
            setAuth({});
        }
    }

    const deleteUser = async () => {
        const token = localStorage.getItem("token");
        await fetch(`${process.env.REACT_APP_USER_API_URL}/users/delete-user`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });
        localStorage.removeItem("token");
        setAuth({});
    }

    const changeL1 = async () => {
        const token = localStorage.getItem("token");
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/users/update-l1/${l1}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });
        if (response.status === 401) {
            localStorage.removeItem("token");
            setAuth({});
        }
    }

    const changeL2 = async () => {
        const token = localStorage.getItem("token");
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/users/update-l2/${l2}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });
        if (response.status === 401) {
            localStorage.removeItem("token");
            setAuth({});
        }
    }

    const acceptInvitation = async (tag) => {
        const token = localStorage.getItem("token");
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/users/accept-invitation/${tag}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });
        if (response.ok) {
            getNotifs();
        }
        if (response.status === 401) {
            localStorage.removeItem("token");
            setAuth({});
        }
    }

    const denyInvitation = async (tag) => {
        const token = localStorage.getItem("token");
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/users/deny-invitation/${tag}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });
        if (response.ok) {
            getNotifs();
        }
        if (response.status === 401) {
            localStorage.removeItem("token");
            setAuth({});
        }
    }

    const getNotifs = async () => {
        if (auth?.email !== "guest") {
            const token = localStorage.getItem("token");
            const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/users/notifs`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            if (response.ok) {
                const json = await response.json();
                setNotifs(json);
                console.log(json);
            }
            if (response.status === 401) {
                localStorage.removeItem("token");
                setAuth({});
            }
        }
    }

    const removeNotif = async (index) => {
        const token = localStorage.getItem("token");
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/users/notif/${index}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });
        if (response.ok) {
            getNotifs();
        }
        if (response.status === 401) {
            if (response.status === 401) {
                localStorage.removeItem("token");
                setAuth({});
            }
        }
    }

    const clearNotifs = async () => {
        const token = localStorage.getItem("token");
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/users/notifs`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });
        if (response.ok) {
            getNotifs();
        }
        if (response.status === 401) {
            if (response.status === 401) {
                localStorage.removeItem("token");
                setAuth({});
            }
        }
    }

    useEffect(() => {
        const token = localStorage.getItem("token");
        fetch(`${process.env.REACT_APP_USER_API_URL}/users/get-l1`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status === 401) {
                localStorage.removeItem("token");
                setAuth({});
            }
        }).then(json => {
            if (json) {
                setL1(json["l1"]);
            }
        });
    }, []);

    useEffect(() => {
        const token = localStorage.getItem("token");
        fetch(`${process.env.REACT_APP_USER_API_URL}/users/get-l2`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status === 401) {
                localStorage.removeItem("token");
                setAuth({});
            }
        }).then(json => {
            if (token) {
                setL2(json["l2"]);
            }
        });
    }, []);

    useEffect(() => {getNotifs()}, []);

    const roleOptions = ["USER", "TEACHER", "ADMIN"]

    return (
        <div className="h-auto flex flex-col">
           
            <div className="flex flex-col border-2 border-gray-300 rounded-xl p-10 mx-auto my-10 bg-white">
            <button className="btn btn-circle btn-outline"
                onClick={()=> {successHandler(false)}}      
            >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" /></svg>
            </button>

                <p className="p-10 text-center text-2xl text-blue-500 font-bold ">User settings</p>
                <div className="flex flex-row  border-2 border-gray-100 shadow mx-auto gap-10 p-5">
                    <div className="flex flex-col border border-2 border-blue-600 mx-auto p-10 rounded-xl shadow-md bg-blue-50">
                        <div className="flex flex-row items-center gap-2"><p className="text-blue-600 font-semibold text-xl">Username:</p> <p className="text-md font-semibold text-gray-600">{auth?.username}</p></div>
                        <div className="flex flex-row items-center gap-2"><p className="text-blue-600 font-semibold text-xl">Email address:</p><p className="text-md font-semibold text-gray-600"> {auth?.email}</p></div>
                        <div className="flex flex-row items-center gap-2"><p  className="text-blue-600 font-semibold text-xl">Role:</p><p className="text-md font-semibold text-gray-600">{auth?.role}</p></div>
                        <div className="flex flex-row items-center gap-2"><p  className="text-blue-600 font-semibold text-xl">L1:</p><p className="text-md font-semibold text-gray-600">{l1}</p></div>
                        <div className="flex flex-row items-center gap-2"><p  className="text-blue-600 font-semibold text-xl">L2:</p><p className="text-md font-semibold text-gray-600">{l2}</p></div>
                    </div>
                    {/* (notifs.length !== 0) &&
                        <div className="flex flex-col border-amber-400 border-2 p-2 rounded-md ">
                            <div className="text-lg text-amber-500 font-semibold mx-auto">Notifications</div>
                            <button onClick={clearNotifs}>Clear</button>
                            {notifs.map((notif, i) => {
                                return (
                                <div className="flex flex-row gap-2 items-center mt-2">
                                    <div className="text-teal-400 text-md font-semibold">{notif.course}</div>
                                    <div className="text-teal-400 text-md font-semibold">{notif.detail}</div>

                                    {notif.type === "INVITATION" &&
                                        <div className="flex flex-row">
                                            <button className="border-2 border-green-500 bg-green-100 text-green-400 p-1 rounded" onClick={() => acceptInvitation(notif.course)}>Accept</button>
                                            <button className="border-2 border-red-400 text-lg text-red-500" onClick={() => denyInvitation(notif.course)}>Deny</button>
                                        </div>
                                    }
                                    <button onClick={() => removeNotif(i)}>X</button>
                                </div>
                                )
                            })}
                        </div>
                        
                    */}
                </div>



                <div className="flex flex-col items-stretch lg:flex-row border-2 border-gray-200 rounded-xl gap-2 p-5 mx-auto mt-10 bg-gray-100 shadow-md">

                    <div className="flex flex-col gap-5 p-2 items-center border-2 p-2 border-gray-200 rounded-md bg-gray-50">
                        <p className="text-xl font-semibold text-blue-500">Change username:</p>
                        <div className=" h-12 border-4 border-teal-400 rounded-md p-2 text-xl"><input className="outline-none" placeholder="Entter new username"
                                 type="text" onChange={(e) => setNewName(e.target.value)} value={newName} /></div>        
                        
                        {nameError&& <div>{nameError}</div>}
                        <button className="border-2 border-purple-300  mx-auto p-2 rounded-md text-xl font-semibold text-blue-600 hover:text-blue-800 hover:bg-purple-100 bg-purple-50" onClick={updateName}>Update username</button>
                    </div>
                   
                    <div className="flex flex-col items-center gap-5 p-2  border-2 border-gray-200 rounded-md bg-gray-50 ">
                            <p className="text-xl font-semibold text-blue-500">Change role:</p>
                            <CategoryDropdownRoles selectHandler={e => setRequestRole(e.target.value)} elValue={auth?.role} />
                    
                            <button     className="border-2 border-purple-300  mx-auto p-2 rounded-md text-xl font-semibold text-blue-600 hover:text-blue-800 hover:bg-purple-100 bg-purple-50"
                                        onClick={requestAccess}>Update role</button>
                    </div>

                    <div className="flex flex-col items-center gap-5 p-2  border-2 border-gray-200 rounded-md bg-gray-50">
                        <p className="text-xl font-semibold text-blue-500">Change native language:</p>
                            <CategoryDropdownL1 selectHandler={e => setL1(e.target.value)} elValue={l1} />
                    
                        <button className="border-2 border-purple-300  mx-auto p-2 rounded-md text-xl font-semibold text-blue-600 hover:text-blue-800 hover:bg-purple-100 bg-purple-50" 
                                onClick={changeL1}>Update native language
                        </button>
                    </div>
                    
                    <div className="flex flex-col items-center gap-5 p-2  border-2 border-gray-200 rounded-md bg-gray-50">
                        <p className="text-xl font-semibold text-blue-500">
                            Preferred Language Course:
                        </p>
                        <CategoryDropdownL2 selectHandler={e => setL2(e.target.value)} elValue={l2} />
                    
                        <button className="border-2 border-purple-300  mx-auto p-2 rounded-md text-xl font-semibold text-blue-600 hover:text-blue-800 hover:bg-purple-100 bg-purple-50" 
                                onClick={changeL2}>Update language course</button>
                    </div>           
                </div>
            </div>
        </div>
    )
}

export default UserSettings;
