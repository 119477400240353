import { useEffect, useState } from "react";
import useAuth from "./hooks/useAuth";
import Paginator from "../course/components/Paginator";

const ClassInterface = ({successHandler, course}) => {
    const {auth, setAuth} = useAuth();
    const [students, setStudents] = useState([]);
    const [studentPages, setStudentPages] = useState(0);
    const [studentPage, setStudentPage] = useState(1);
    const [assignments, setAssignments] = useState([]);
    const [chats, setChats] = useState([]);
    const [chat, setChat] = useState("");

    const getStudents = async (page = 1) => {
        const token = localStorage.getItem("token");
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/course/students/${course}/${page}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        });
        if (response.ok) {
            const json = await response.json();
            setStudents(json);
        }
        if (response.status === 401) {
            localStorage.removeItem("token");
            setAuth({});
        }
    }

    const leaveClass = async () => {
        const token = localStorage.getItem("token");
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/users/leave-class/${course}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        });
        if (response.ok) {
            successHandler("");
        }
        if (response.status === 401) {
            localStorage.removeItem("token");
            setAuth({});
        }
    }
    
    const getStudentPages = async () => {
        const token = localStorage.getItem("token");
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/course/student-pages/${course}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        });
        if (response.ok) {
            const json = await response.json();
            setStudentPages(json.pages);
        }
        if (response.status === 401) {
            localStorage.removeItem("token");
            setAuth({});
        }
    }

    const getAssignments = async () => {
        const token = localStorage.getItem("token");
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/course/assignments/${course}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        });
        if (response.ok) {
            const json = await response.json();
            setAssignments(json);
        }
        if (response.status === 401) {
            localStorage.removeItem("token");
            setAuth({});
        }
    }

    const getChats = async () => {
        const token = localStorage.getItem("token");
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/course/chats/${course}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        });
        if (response.ok) {
            const json = await response.json();
            setChats(json);
        }
        if (response.status === 401) {
            localStorage.removeItem("token");
            setAuth({});
        }
    }

    const sendChat = async (event) => {
        event.preventDefault();
        const token = localStorage.getItem("token");
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/course/chat/${course}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                text: chat
            })
        });
        if (response.ok) {
            getChats();
            setChat("");
        }
        if (response.status === 401) {
            localStorage.removeItem("token");
            setAuth({});
        }
    }

    const checkChat = async () => {
        const token = localStorage.getItem("token");
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/course/chat-count/${course}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });
        if (response.ok) {
            const json = await response.json();
            if (json.count !== chats.length) {
                getChats();
            }
        }
    }

    useEffect(() => {getStudents()}, []);
    useEffect(() => {getStudentPages()}, []);
    useEffect(() => {getStudents(studentPage)}, [studentPage]);
    useEffect(() => {getAssignments()}, []);
    useEffect(() => {getChats()}, []);
    useEffect(() => {checkChat()});

    return <div>
        <button className="btn btn-circle btn-outline"
            onClick={()=> {successHandler("")}}      
        ><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" /></svg>
        </button>
        <div className="flex flex-col">
            <div className="flex flex-row justify-between">
                <div className="flex flex-col border border-4">
                    <b>Students:</b>
                    <Paginator maxpage={studentPages} 
                        page={studentPage}
                        clickHandler={
                            (page) => {setStudentPage(page);}
                        }
                    />
                    {students.map(student => {
                        return <div>{student}</div>
                    })}
                </div>
                <div className="flex flex-col border border-4">
                    <b>Assignments</b>
                    {assignments.map(assignment => {
                        return <div>{assignment}</div>
                    })}
                </div>
                <div className="flex flex-col border border-4">
                    <b>Chat</b>
                    {chats.map(chat => {
                        return <div className="flex flex-col border">
                            <div className="text-xs">{chat.user}</div>
                            <div>{chat.text}</div>
                            <div className="text-xs">{chat.time}</div>
                        </div>
                    })}
                    <form className="flex flex-row">
                        <input className="border" type="text" value={chat} onChange={(e) => setChat(e.target.value)}/>
                        <button onClick={(e) => sendChat(e)}>Send</button>
                    </form>
                </div>
            </div>
            <button className="border" onClick={leaveClass}>Leave class</button>
        </div>
    </div>
}

export default ClassInterface;