import DisplayWord from "../Display/DisplayWord";
const ListenAndWrite = ({inputtracks}) => {

    return (
        <>
        <DisplayWord   tracks={inputtracks} />
        </>
    )

}

export default ListenAndWrite;