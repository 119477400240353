import { Tooltip} from "@material-tailwind/react";
import { useRef } from "react";

const CardSoundGroupButtonMPArray = ({currentEx, include, color}) => {
    
   
   /* let choices =[[`${process.env.REACT_APP_STATIC_URL}`+currentTrack[0].audio_0.sort(() => 0.5 - Math.random()).slice(0,1), `${process.env.REACT_APP_STATIC_URL}`+currentTrack[1].audio_1.sort(() => 0.5 - Math.random()).slice(0,1)],
    [`${process.env.REACT_APP_STATIC_URL}`+currentTrack[0].audio_1.sort(() => 0.5 - Math.random()).slice(0,1),`${process.env.REACT_APP_STATIC_URL}`+currentTrack[1].audio_0.sort(() => 0.5 - Math.random()).slice(0,1) ]]
    let choice = choices.sort(() => 0.5 - Math.random()).slice(0,1)[0]
    

    const audio_0 = new Audio(choice[0])
    const audio_1 = new Audio (choice[1])
    
    console.log(audio_0, audio_1,audio_0.duration, audio_1.duration, "audio")
    const  PlayBoth = async () => {
        
        
        setTimeout(() => {
            audio_0.play();
            }, 2) 
        
        audio_0.onended=function()
        {
            setTimeout(() => {
            audio_1.play();
            }, 200)   
        }
    };*/

return (
        

            <Tooltip placement="bottom" className="flex flex-col bg-blue-500 font-medium text-white text-center rounded-xxl px-3"
                animate={{
                mount: { scale: 1.4, y: 0 },
                unmount: { scale: 0, y: 25 },}} 
               

                content=<span style={{ whiteSpace: 'pre-line' }}> {currentEx.length === 1 ?
                    String(currentEx[0].MP_wordpair[0].word)+" - "+String(currentEx[0].MP_wordpair[1].word)
                    :
                        currentEx.length === 2 ?
                        String(currentEx[0].MP_wordpair[0].word)+" - "+String(currentEx[0].MP_wordpair[1].word) +'\n' +
                        String(currentEx[1].MP_wordpair[0].word)+" - "+String(currentEx[1].MP_wordpair[1].word) 
                    
                        :
                        currentEx.length === 3 && 
                        String(currentEx[0].MP_wordpair[0].word)+" - "+String(currentEx[0].MP_wordpair[1].word) +'\n' +
                        String(currentEx[1].MP_wordpair[0].word)+" - "+String(currentEx[1].MP_wordpair[1].word) +'\n' +
                        String(currentEx[2].MP_wordpair[0].word)+" - "+String(currentEx[2].MP_wordpair[1].word)
                }</span>
                     >
                <button  className="shadow-xl text-blue-700 font-medium text-2xl hover:text-3xl  py-2 px-4 border w-16 h-16 border-blue-500 hover:border-blue-600 rounded-full hover:bg-green-500" 
                style={ include ? {backgroundColor : color }:{backgroundColor : "lightgray"} }
                /* onMouseOver={PlayBoth}*/>
                {currentEx[0].Second_MinimalPair_IPA}
        
                </button>
            </Tooltip> 
    )
}
export default CardSoundGroupButtonMPArray;