import React from 'react'
import { useState } from 'react';
import useAuth from '../../../../Users/hooks/useAuth';
import { Link } from 'react-router-dom';
import ExercisePlayer from './Exercise/ExercisePlayer';
import ExercisePlayerThreeStress from './Exercise/ExercisePlayerThreeStress';
import ExercisePlayerTwoStress from './Exercise/ExercisePlayerTwoStress';

const CardExercise = ({exercise, l2, exerciseHandler}) => {
  const {auth, setAuth} = useAuth()
  // console.log("JOY, exercise, entry point to Exercise, level 0, exercise name: ", exercise.Exercise_name, 'object passed as argument: ', exercise )  
  const [showInfo, setShowInfo] = useState(false) 
  const [exit, setExit] = useState(false)



    return (
      // Entire wrapper
      <div className='md:flex md:flex-col items-center justify-center mx-auto h-screen md:py-0'> 
            
        {/*The card*/}
        <div className={`h-full md:h-auto w-full xl:w-3/5  shadow md:mx-auto md:flex md:flex-col md:rounded-3xl bg-gray-50 md:py-3 animate__animated
        `}> 
        
          
          {/* Main body */}
          <div className='flex flex-col h-full md:h-auto'>
          
              <div className={`flex flex-col
                    ${showInfo ? 'opacity-0 h-0' : 'opacity-100 h-full'} `}
                  >

                    {/* three cases -- two stress exercises and just exercise */}
                  
                    {exercise.Exercise_name === 'first-second-third' ? 
                    <ExercisePlayerThreeStress id={exercise.id} l2={l2} exerciseHandler={exerciseHandler}/>
                    :
                    exercise.Exercise_name === 'first-second' ? 
                    <ExercisePlayerTwoStress id={exercise.id} l2={l2} exerciseHandler={exerciseHandler} />
                    :
                    <ExercisePlayer id={exercise.id} l2={l2} exerciseHandler={exerciseHandler}/>
                    }
              <div/>    
          
            </div>
          </div>    
        </div>
      </div> 

            
    
    ) 
}
export default CardExercise;

// MAIN in return
            // <div className='flex flex-col'>

            //   {/*Cross button to exit*/}
            //   <div className='p-10'> 
            //     <button className="btn btn-circle btn-outline"
            //       onClick={()=> {exerciseHandler(null)}}
            //     >
            //       <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" /></svg>
            //     </button>
            //   </div>

            //   {/*Header in the exercise card*/}
            //   <div className="w-full xl:w-1/2  mx-auto flex flex-col border-2 rounded-md"> 
            //     {/*Info button*/}
            //     <div className='flex flex-row mx-2 my-2'> 
            //      {exercise.Exercise_information && 
            //         <button className="hover:scale-110"   onClick={()=>{showInfo ? setShowInfo(false) : setShowInfo(true)}}>
            //           {showInfo ? <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" /></svg>
            //           : 
            //           <svg xmlns="http://www.w3.org/2000/svg" className='h-6 w-6' viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2.033 16.01c.564-1.789 1.632-3.932 1.821-4.474.273-.787-.211-1.136-1.74.209l-.34-.64c1.744-1.897 5.335-2.326 4.113.613-.763 1.835-1.309 3.074-1.621 4.03-.455 1.393.694.828 1.819-.211.153.25.203.331.356.619-2.498 2.378-5.271 2.588-4.408-.146zm4.742-8.169c-.532.453-1.32.443-1.761-.022-.441-.465-.367-1.208.164-1.661.532-.453 1.32-.442 1.761.022.439.466.367 1.209-.164 1.661z"/></svg>
            //           }
            //         </button>
            //       }
                  
            //       <div className='grow'>
            //       {/* open in new window button */}
            //       </div>

            //       {/* Open in the new tab */}
            //       <Link   to={`/player/${(exercise.Exercise_name === 'first-second-third' || exercise.Exercise_name === 'first-second') ?  exercise.Exercise_name :  'normal'}/${l2}/${exercise.id}`} 
            //               target="_blank" 
            //               rel="noreferrer"
            //       >
            //         <button className ="bg-white hover:bg-gray-100 text-gray-800 font-semibold 
            //                             text-sm py-2 px-2 border border-gray-400 rounded shadow"
            //         >
            //           A new tab
            //         </button>
            //       </Link>
            //     </div>
            //     <div className='m-auto'>
            //       <p className='font-bold text-xl'>
            //         {exercise.Exercise_name}
            //       </p>
            //     </div>
            //     {/* block with exercise info */}
            //     <div className='flex flex-col p-5'>
            //       <div className={`flex text-left text-xl text-mono border-2 rounded-md p-3 shadow-md mx-auto
            //       ${showInfo ? 'opacity-100' : 'opacity-0'}
            //       `}>
            //       {showInfo && exercise.Exercise_information}
            //       </div>
                
            //       <div className={`flex flex-col
            //       ${showInfo ? 'opacity-0 h-0' : 'opacity-100 h-full'} `}
            //     >

            //       {/* three cases -- two stress exercises and just exercise */}
                
            //       {exercise.Exercise_name === 'first-second-third' ? 
            //       <ExercisePlayerThreeStress id={exercise.id} l2={l2} />
            //       :
            //       exercise.Exercise_name === 'first-second' ? 
            //       <ExercisePlayerTwoStress id={exercise.id} l2={l2} />
            //       :
            //       <ExercisePlayer id={exercise.id} l2={l2} />
            //       }
                  
            //       </div>
            //     </div>   
            //   </div>
            // </div>                 