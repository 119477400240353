import { Tooltip} from "@material-tailwind/react";
import useActivity from "../../../../../Users/hooks/useActivity";
import { CircularProgressbarWithChildren, CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useEffect, useState } from "react";


const CardSoundGroupButtonNW = ({currentTrack, include, color, title, name}) => {
    const {activity} = useActivity();
    const [percentage, setPercentage] = useState(0) 
    useEffect(()=>{
                    if (name in activity)
                        {setPercentage(activity[name]['fulfilled'])
                        }
                    else
                    {setPercentage(0)}    
                    },[activity]

                )
  

    return (
        
            <Tooltip placement="top" className="flex flex-col bg-blue-700 font-medium text-white rounded-full px-3"
                animate={{
                mount: { scale: 1.4, y: -10 },
                unmount: { scale: 0, y: 25 },}} 
                content={
                String(currentTrack.word)+ "-*"+String(currentTrack.nonword) }>
                    <button>
                <CircularProgressbarWithChildren value={percentage}
                background
                backgroundPadding={6}
                styles={buildStyles({
                  backgroundColor: include? color : 'lightgray' ,
                  textColor: "darkgray",
                  pathColor: "greenyellow",
                  trailColor: "transparent",
                  textSize: "14px",
                })}
                 
                
                
                >          
                    <p className='text-2xl'>{title}</p>                      
                        
                        
                    </CircularProgressbarWithChildren>
                </button>
                
            </Tooltip> 
    )
}
export default CardSoundGroupButtonNW;