import React from 'react'
import { Tooltip} from "@material-tailwind/react";
import CardSoundGroupButtonMP from './Button/CardSoundGroupButtonMP';
import CardSoundGroupButtonNW from './Button/CardSoundGroupButtonNW';
import { useRef } from 'react';




const CardSoundGroup = ({exercise, include, exHandler, choice}) => {
  


function handleFocus(timer) {
  let pulse_duration = 1700;
  if (timer){
    pulse_duration = timer*1000+300;
  }
  

  inputRef.current.animate(  
    [ 
   
      
      { transform: "scale(1.05)" },
      { transform: "translateY(-10%)" }, 
      { transform: "translateY(-30%)" }, 
      { transform: "scale(1.2)" },
      { transform: "translateY(-10%)" },
      { transform: "translateY(0%)" }, 
      { transform: "scale(1.2)" },
      { transform: "scale(1.0)" },
      ],
      { duration: pulse_duration, fill: "forwards", iterations: 1 } 
   
  
  )

}



  const inputRef = useRef(null);

 
  /* const audio_0 = new Audio(choice.choice[0])
  const audio_1 = new Audio (choice.choice[1])
  */

  /* define circles , pair sounds*/
  const group = exercise.Group_name
  const dummy = [...Array(33)]
  const positions = [2, 21,31, 16, 0, 28, 4, 12, 24, 29, 7, 19,26, 9, 18 ]
  let colorcodes = ["deepskyblue", "cornflowerblue", "aquamarine", "aliceblue", 
                    "azure", "darkturquoise","cadetblue", "dodgerblue" , "aqua",
                    "blueviolet", "fuchsia", "lavender", "lightsteelblue", 
                    "mediumpurple", "yellowgreen"
                  ]
  const dummy_index=14
  const test = exercise.dictionary
  const names = Object.keys(test);
  // console.log('JOY: circle with many circles: sound group, front page, level 2, target sound: ', exercise.sound)
  names.forEach((key, index) => {
    dummy[positions[index]] = test[key]
  });
  dummy[dummy_index] = group


  
    return (

         
                    

          <Tooltip className="flex flex-col bg-indigo-300 font-medium text-white rounded-full"
            animate={{
              mount: { scale: 1.1, y: 30 },
              }} 
              placement="bottom"
            content={include? "Choose a sound pair" : "Already familiar sounds" }
          > 
                  
            <div  
                  className="scale-75 hover:xl:scale-90
                              grid grid-rows-6 grid-flow-col 
                              w-96 h-96 
                              bg-farmWhite rounded-full 
                              hover:bg-blue-100 shadow-2xl duration-300 font-mono"
            >
                        
            {dummy.map(
              (el, index)=>{ 
             
                  return (

                    el ?    
                            
                            index !== dummy_index 
                            ?
                              el[0].MP_wordpair ?
                                
                                <div  ref={ el[0].Exercise_name === choice.Sample_name ? inputRef : undefined} 
                                      key={String(index) + String(el[0].Exercise_name)+String(el[0].Group_lesson)}  
                                      onClick={() => {exHandler(el[0])}} 
                                >
                                  <CardSoundGroupButtonMP 
                                      currentTrack={el[0].MP_wordpair} 
                                      include={include} 
                                      title={el[0].Sidekick} 
                                      name={el[0].Exercise_name}
                                      color={String(colorcodes[positions.indexOf(index)])} 
                                  />
                                </div>
                                
                              :
                                  <div  key={String(index) + String(el[0].Exercise_name)} 
                                        onClick={() => {exHandler(el[0])}} 
                                  >
                                    <CardSoundGroupButtonNW 
                                          currentTrack={el[0].MP_nonword} 
                                          title={ el[0].Sidekick}
                                          name={el[0].Exercise_name} 
                                          include={include} color={String(colorcodes[positions.indexOf(index)])} 
                                    />
                                  </div>
                              

                            : 
                              
                              <button key={String(index) + String(el[0].Exercise_name)}  
                                    
                                    className={`row-span-2 col-span-2 flex justify-center items-center  shadow-xl 
                                              truncate hover:text-white font-medium hover:border-transparent rounded-full  
                                              ${ include  ? 
                                                          "bg-amber-300 hover:bg-green-500 text-blue-700 border border-yellow-800" 
                                                          : 
                                                          "bg-gray-300 hover:bg-grey-500 text-grey-700 border border-gray-600"}
                                              `}
                              >
                                <p className={`${ group.split(' ').length > 1 ? 'text-2xl' : 'text-5xl' } `}>{group}</p>
                              </button>
                              

                        : 
                            <button key={index} 
                                    className="bg-transparent border w-16 h-16  border-transparent 
                                                hover:border-transparent rounded-full"
                            >
                          
                            </button>
                            
                            )
                            }
              
            )}
          </div>    
         </Tooltip>  
             
    
    ) 
}
export default CardSoundGroup;
