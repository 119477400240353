import React from 'react'
import { Tooltip} from "@material-tailwind/react";
import { useState } from 'react';
import { Link } from 'react-router-dom';
import CardPlayer from './Player/CardPlayer';
import CardSoundGroupButtonMP from './Button/CardSoundGroupButtonMP';
import CardSoundGroupButtonNW from './Button/CardSoundGroupButtonNW';
import { useRef } from 'react';
import sortingShuffle from '../../shuffle';
import Selector from './Selector';
import ExercisePlayer from './Exercise/ExercisePlayer';


const CardSoundGroup = ({exercise, l2, include}) => {

  const  PlayBoth = async () => {

    handleFocus(audio_0.duration+audio_1.duration)
    
    setTimeout(() => {
        audio_0.play();
        }, 2) 
    
    audio_0.onended=function()
    {
        setTimeout(() => {
        audio_1.play();
        }, 200)   
    }
};
const  StopBoth = async () => {
    
    
        audio_0.pause();
        audio_0.currentTime=0;   
    
        
        audio_1.pause();
        audio_1.currentTime=0;   
    
};

function handleFocus(timer) {
  let pulse_duration = 1700;
  if (timer){
    pulse_duration = timer*1000+300;
  }
  

  inputRef.current.animate(  
    [ 
   
      
      { transform: "scale(1.05)" },
      { transform: "translateY(-10%)" }, 
      { transform: "translateY(-30%)" }, 
      { transform: "scale(1.2)" },
      { transform: "translateY(-10%)" },
      { transform: "translateY(0%)" }, 
      { transform: "scale(1.2)" },
      { transform: "scale(1.0)" },
      ],
      { duration: pulse_duration, fill: "forwards", iterations: 1 } 
   
  
  )

}


  
  /* define random sounds pick */

  var products = [];
  
  const keys = Object.keys(exercise.dictionary);
  keys.forEach((key, index) => {
    if (exercise.dictionary[key][0].MP_wordpair) {
      products.push(exercise.dictionary[key][0])
    }

  });

  const inputRef = useRef(null);
  let AudioSample = [];
  let choices = ['',''];
  let Sample = [];

  if (products.length !== 0){

    Sample =  sortingShuffle(products)[0];
    
    
    AudioSample = Sample.MP_wordpair
  
    choices =[  [ `${process.env.REACT_APP_STATIC_URL}`+sortingShuffle(AudioSample[0].audio_0).slice(0,1), 
                `${process.env.REACT_APP_STATIC_URL}`+sortingShuffle(AudioSample[1].audio_1.slice(0,1))
                ],
                [ `${process.env.REACT_APP_STATIC_URL}`+ sortingShuffle(AudioSample[0].audio_1).slice(0,1),
                `${process.env.REACT_APP_STATIC_URL}`+ sortingShuffle(AudioSample[1].audio_0).slice(0,1) 
                ]
              ]
  }
  
  let choice = sortingShuffle(choices)[0]
  const audio_0 = new Audio(choice[0])
  const audio_1 = new Audio (choice[1])

  /* define circles , pair sounds*/
  const group = exercise.sound
  const dummy = [...Array(33)]
  const positions = [2, 21,31, 16, 0, 28, 4, 12, 24, 29, 7, 19,26, 9, 18 ]
  let colorcodes = ["deepskyblue", "cornflowerblue", "aquamarine", "aliceblue", 
                    "azure", "darkturquoise","cadetblue", "dodgerblue" , "aqua",
                    "blueviolet", "fuchsia", "lavender", "lightsteelblue", 
                    "mediumpurple", "yellowgreen"
                  ]
  const dummy_index=14
  const test = exercise.dictionary
  const names = Object.keys(test);
  
  names.forEach((key, index) => {
    dummy[positions[index]] = test[key]
  });
  dummy[dummy_index] = exercise.sound

  const [show, setShow] = useState(true);
  const [currentEx, setCurrentEx] = useState({});
  const tabsData = [  
    {label: 'Training'},
    {label: 'Test yourself'},
    
  ];
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabs, setTabs] = useState(tabsData)
  
    return (

          show 
            
          ?
                    

          <Tooltip className="flex flex-col bg-indigo-300 font-medium text-white rounded-full"
            animate={{
              mount: { scale: 1.1, y: -15 },
              }} 
              placement="top"
            content={include? "Choose a sound pair" : "Already familiar sounds" }
          > 
                  
            <div  onMouseEnter={AudioSample.length > 0 ? PlayBoth : undefined } 
                  onMouseLeave={AudioSample.length > 0 ? StopBoth : undefined} 
                  className="scale-75 xl:scale-90 2xl:scale-100 
                              grid grid-rows-6 grid-flow-col 
                              w-96 h-96 
                              bg-farmWhite rounded-full 
                              hover:bg-blue-100 shadow-2xl duration-300 font-mono"
            >
                        
            {dummy.map(
              (el, index)=>{ 
              
                  return (

                    el ?    
                            
                            index !== dummy_index 
                            ?
                              el[0].MP_wordpair ?
                                
                                <div  ref={ el[0].Exercise_name === Sample.Exercise_name ? inputRef : undefined} 
                                      key={String(index) + String(el[0].Exercise_name)}  
                                      onClick={() => {setShow(false); setActiveTabIndex(0); setCurrentEx(el[0])} } 
                                >
                                  <CardSoundGroupButtonMP 
                                      currentTrack={el[0].MP_wordpair} 
                                      include={include} 
                                      title={el[0].Second_MinimalPair_IPA ? el[0].Second_MinimalPair_IPA : el[0].Exercise_name} 
                                      color={String(colorcodes[positions.indexOf(index)])} 
                                  />
                                </div>
                                
                              :
                                  <div  key={String(index) + String(el[0].Exercise_name)} 
                                        onClick={() => {setShow(false); setActiveTabIndex(0); setCurrentEx(el[0])}} 
                                  >
                                    <CardSoundGroupButtonNW 
                                          currentTrack={el[0].MP_nonword} 
                                          title={ el[0].Second_MinimalPair_IPA ? el[0].Second_MinimalPair_IPA : el[0].Exercise_name} 
                                          include={include} color={String(colorcodes[positions.indexOf(index)])} 
                                    />
                                  </div>
                              

                            : 
                              
                              <button key={String(index) + String(el[0].Exercise_name)}  
                                    
                                    className={`row-span-2 col-span-2 flex justify-center items-center  shadow-xl 
                                              truncate hover:text-white font-medium hover:border-transparent rounded-full text-5xl 
                                              ${ include  ? 
                                                          "bg-amber-300 hover:bg-green-500 text-blue-700 border border-yellow-800" 
                                                          : 
                                                          "bg-gray-300 hover:bg-grey-500 text-grey-700 border border-gray-600"}
                                              `}
                              >
                                {group}
                              </button>
                              

                        : 
                            <button key={index} 
                                    className="bg-transparent border w-16 h-16  border-transparent 
                                                hover:border-transparent rounded-full"
                            >
                          
                            </button>
                            
                            )
                            }
              
            )}
          </div>    
         </Tooltip>  
          :

              <div className="flex flex-col border-b-2 border-l-2 border-r-2 rounded-md scale-90"> 
                <div className='flex flex-row'>
                  <button className ="bg-white hover:bg-gray-100 text-gray-800 font-semibold 
                                      text-sm py-2 px-2 border border-gray-400 rounded shadow"  
                          onClick={() => {setShow(true);} } 
                  > 
                    Go back
                  </button>
                  
                  <div className='grow border-b-2'>

                  </div>
                  <Link   to={`/player/${l2}/${currentEx.id}`} 
                          target="_blank" 
                          rel="noreferrer"
                  >
                    <button className ="bg-white hover:bg-gray-100 text-gray-800 font-semibold 
                                        text-sm py-2 px-2 border border-gray-400 rounded shadow"
                    >
                      A new tab
                    </button>
                  </Link>
                </div>
              
                {(tabs[activeTabIndex].label === 'Training')  &&
                  <CardPlayer id={currentEx.id} l2={l2}  />  }

                {(tabs[activeTabIndex].label === 'Test yourself')  &&
                  <ExercisePlayer id={currentEx.id} l2={l2} />
                }

                <Selector 
                    clickHandler={(tab_index) =>
                            {
                                setActiveTabIndex(tab_index)
                            }
                    } 
                    tabsData={tabs} 
                />      
              </div>
                                
    
    ) 
}
export default CardSoundGroup;
