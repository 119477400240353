import React from "react";
import useSWR from "swr";
const fetcher = (...args) => fetch(...args).then((res) => res.json());

const CategoryDropdownLevel = ({ selectHandler, elValue, course}) => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API_URL}/distinct_field?field=Level&course=${course}`,
    fetcher,  {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  ); 
  
  if (error) return <div>`failed to load {process.env.REACT_APP_API_URL}`</div>;
  if (!data) return <div>loading...</div>;


  return (
    <select className="mr-2 ml-2  text-black-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-gray-600"
      onChange={selectHandler}
      value={elValue}
    >
      <option value="">All levels</option>
      {data.sort((a, b) => a.localeCompare(b, undefined, { numeric: false, sensitivity: 'base' })).map((lang) => {
        return (
          <option value={lang.replace(" ","%20")} key={lang}>
            {lang}
          </option>
        );
      })}
    </select>
  );
};

CategoryDropdownLevel.defaultProps = {
  elValue: "",
  course : "",
  

};
export default CategoryDropdownLevel;
