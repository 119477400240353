import { Tooltip} from "@material-tailwind/react";
import useActivity from "../../../../../Users/hooks/useActivity";
import { CircularProgressbarWithChildren, CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useEffect, useState } from "react";



const CardSoundGroupButtonMPArray = ({currentEx, include, color}) => {
    var stringex = currentEx.map( (el) => String(el.MP_wordpair[0].word)+" - "+String(el.MP_wordpair[1].word)).join(' \n')
    
    const {activity} = useActivity();
  
    const [percentage, setPercentage] = useState() 
        
    useEffect(()=>{
        let currentPer = []
        currentEx.map((el)=>{
        
          if (el.Exercise_name in activity){
            currentPer.push(activity[el.Exercise_name]['fulfilled']);
            
        }

          else
          {currentPer.push(0)}
          
          })
        if (currentPer.length > 0) {
          
        setPercentage(currentPer.reduce((a,b)=>a+b)/currentPer.length)
        }
        else {setPercentage(0)}
        },[activity]
      )

    
return (
        

            <Tooltip    placement="top" className="flex flex-col bg-blue-500 font-medium text-white text-center rounded-xxl px-3"
                        animate={{
                                mount: { scale: 1.4, y: -25 },
                                unmount: { scale: 0, y: 25 },
                                }} 
               

                        content=
                                <span style={{ whiteSpace: 'pre-line' }}
                                >
                                    { stringex}
                                </span>
            >
               
                <button>
                    <CircularProgressbarWithChildren  value={percentage}
                                                                background
                                                                backgroundPadding={6}
                                                                styles={buildStyles({
                                                                    backgroundColor: include? color  : 'lightgray' ,
                                                                    textColor: "darkgray",
                                                                    pathColor: "greenyellow",
                                                                    trailColor: "lightgray",
                                                                    textSize: "14px",
                                                                })}
                                                                
                    
                    > 
                        {currentEx[0].Second_MinimalPair_IPA}
                    </CircularProgressbarWithChildren>
                </button>
            </Tooltip> 
    )
}
export default CardSoundGroupButtonMPArray;