import React from 'react'
import { useState, useEffect } from 'react';
import CardPlayer from '../../components/Card/Exercises/Player/CardPlayer';
import Selector from '../../components/Card/SoundGroups/Selector';
import ExercisePlayer from '../../components/Card/Exercises/Exercise/ExercisePlayer';
import {useParams} from "react-router-dom"
import ExercisePlayerThreeStress from '../../components/Card/Exercises/Exercise/ExercisePlayerThreeStress';
import ExercisePlayerTwoStress from '../../components/Card/Exercises/Exercise/ExercisePlayerTwoStress';
import useAuth from '../../../Users/hooks/useAuth';

const TabPlayer = () => {
  
  let {l2, id, name} = useParams()

  const { setAuth } = useAuth();

  const checkToken = async () => {
    const token = localStorage.getItem("token");
    if (token) {
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/users/me`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
        if (response.ok) {
            let userData = await response.json();
            console.log(userData);
            userData["token"] = token;
            setAuth(userData);
        }
    }
}

useEffect(() => {
    checkToken();
}, []);
  
 let tabsData = []
 if (name === 'first-second-third')
 {tabsData = [  
  {label: 'Test yourself'},
  
];
}
else{
  tabsData = [  
    {label: 'Training'},
    {label: 'Test yourself'},
    
  ];
}
  
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabs, setTabs] = useState(tabsData)
  
    return (

      <div className='flex flex-col'>
              <div className="w-auto md:w-1/2 border-2 m-auto my-20  rounded-md"> 
                
                               
                {(tabs[activeTabIndex].label === 'Training')  &&
                  
                  <CardPlayer id={id} l2={l2}  />  }

                {(tabs[activeTabIndex].label === 'Test yourself')  &&
                  <>
                    {name === 'first-second-third' ?
                    <ExercisePlayerThreeStress id={id} l2={l2} /> :
                    name === 'first-second' ? 
                    <ExercisePlayerTwoStress id={id} l2={l2} /> :
                    <ExercisePlayer id={id} l2={l2} />}
                  </>
                }

                <Selector 
                    clickHandler={(tab_index) =>
                            {
                                setActiveTabIndex(tab_index)
                            }
                    } 
                    tabsData={tabs} 
                />      
              </div>
                                
            </div>
    ) 
}
export default TabPlayer;
