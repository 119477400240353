import { useMemo, useState, useEffect } from "react";
import TwoStressOne from "../StressButton/TwoStressOne";
import TwoStressTwo from "../StressButton/TwoStressTwo";
import Results from "../Display/Results";
import 'animate.css'; // npm install animate.css --save


const ListenAndClickIITwoStress= ({ tracks, resultsHandler, nextTab, nextExercise, progress, setProgress, tab}) => {
  const [track, setTrack] = useState(1)
  const [playState, setPlayState] = useState(false) // sounds are played when true
  const [results, setResults] = useState({'results': {}, 'value': []})
  const [buttonState, setButtonState] = useState(false) // pair buttons are disabled when false
  const [start, setStart] = useState(false)
  const [registerHit, setRegisterHit] = useState(false)
  const [resultPage, setResultPage] = useState(false)
  const [scores, setScores] = useState(progress[tab] ? progress[tab] : [])
  
  // Input: 0,1, or 2. Depending on the button
  const Button = ({button}) => {
    return (

      // CHOICES
      <div className={` flex flex-col items-center
      ${
        button === 0 ?
        'mb-2 md:mb-0'
        : 'mt-2 md:mt-0'
      }

      `}>
        <div>

          {/*  Translation for button 1. Shows above button on small screen */}
          {/* {
            button === 0 ?
            <p className=" block md:hidden break-word text-sm mb-2 border-b-2 pb-1 text-gray-500"> {target.translation} </p>
            : null
          } */}

          <button
          disabled={!buttonState || registerHit}
          className={`
                border-b-4 py-3 px-10 rounded-full font-serif:Times text-3xl font-semibold text-white shadow-md animate__animated
                ${animatePulse[button] ? pulse : ""}
                ${animateRight[button] ? correct : ""}
                ${animateWrong[button] ? wrong : ""}
                ${!animateRight[button] && !animateWrong[button] ? "bg-pygblue border-blue-700 hover:bg-blue-500" : ""}`} 
          style={{ '--animate-duration': '1s' }} // Change the duration of the animation here. Default: 1s
          onClick={()=>{button === 0 ? Playit_0() : Playit_1()}}>

                {/* Stress icons */}
                {(button === 0) ?
                    <TwoStressOne />
                      :
                    <TwoStressTwo />
                }

                {/* Word target: target.word */}
                
                {/* <p className={`text-center text-white ${target.word.length < 13 ? "" : "break-all"}`}>
                      {target.word}
                </p> */}

                {/* Word translation: target.translation */}
          </button>

          {/* Translation for button 2. Shows below button on small screen. */}
          {/* {
            button === 1 ?
            <p className=" block md:hidden break-word text-sm mt-2 border-t-2 pt-1 text-gray-500"> {target.translation} </p>
            : null
          } */}
        </div>
      </div>
    )
  }
 
  //  For animating the pair buttons when playing, '0s' is the default duration
  const [animatePulse, setAnimatePulse] = useState([false,false])
  const [animateRight, setAnimateRight] = useState([false,false])
  const [animateWrong, setAnimateWrong] = useState([false,false])
  let pulse = `animate__pulse`
  let correct = `animate__tada bg-green-500 border-green-700 opacity-60`	
  let wrong = `animate__shakeX bg-red-500 border-red-700 opacity-60`

  // Tracks and audios
  let target = tracks[track-1]
  let audio_0 = useMemo(() => new Audio(target.audio),[target]);

  console.log(target, 'target')
 
  const PlayNext = () => {
    var audio_duration = 2000
    if (audio_0.duration) 
    {
      audio_duration = audio_0.duration*1000 + 300
     
    }
    else
    {
     
        audio_duration = 2000
     
    }

  
    setTimeout(() => {
    if (track < tracks.length)
      {
        setTrack(track + 1);
        setButtonState(false);
        setPlayState(true);
        setRegisterHit(false);
        setAnimatePulse([false,false]);
        setAnimateRight([false,false]);
        setAnimateWrong([false,false]);
      }
      else
      {
        setResultPage(true)
        resultsHandler(results)
              }
      }, 2300)
  }


  // If this is clicked, then they chose the first button. 
  const Playit_0 = () => {
    setRegisterHit(true)
    setAnimatePulse([false,false])
    console.log('button 1 clicked')   
    var audio_duration = audio_0.duration ? audio_0.duration : 1


    if (target.MainStressSyllable === 1) {
      if (!results.results[track]){
          results.results[track] = {"eval": true, "target": target }
          results.value.push(true)
        setResults(results)
      }
    
      setAnimateRight([true,false])
      setScores([...scores, 1])
    }
    
    else
    {
      if (!results.results[track]){
        results.results[track] = {"eval": false, "target":  target }
        results.value.push(false)
        setResults(results)
      }
      setAnimateWrong([true,false])
      setScores([...scores, 0])
    }
    PlayNext()
  };


  const Playit_1 = () => {
    setRegisterHit(true)
    setAnimatePulse([false,false, false])
    console.log('button 2 clicked')   
    var audio_duration = audio_0.duration ? audio_0.duration: 1

  
    if (target.MainStressSyllable === 2) {
      if (!results.results[track]){
        results.results[track] = {"eval": true, "target": target }
        results.value.push(true)
      
        setResults(results)
        
      }
      
      setAnimateRight([false,true, false])
      setScores([...scores, 1])
    }
    
    else {
      if (!results.results[track]){
        results.results[track] = {"eval": false, "target":  target }
        results.value.push(false)
        setResults(results)
      }

      setAnimateWrong([false,true, false])
      setScores([...scores, 0])
    }
    PlayNext()
  };

  

  

  useEffect(()=>{

    
    let mounted = true
    setAnimatePulse([false,false])
    
    
    const handleCanPlayThrough = () => {
      var audio_duration = audio_0.duration ? audio_0.duration : 1
      audio_0.removeEventListener('canplaythrough', handleCanPlayThrough);
      console.log('Audio can be played through.');
      if (mounted)
      {
      setTimeout(() => {

        audio_0.play();
        setAnimatePulse([true,true])

      }, 300 ) 
      }
      
    };

    if ( playState === true ) {
      
      setTimeout(()=> {
        
        audio_0.addEventListener('canplaythrough', handleCanPlayThrough);
        audio_0.addEventListener("ended", ()=>{setButtonState(true); setPlayState(false);})
        
        audio_0.currentTime = 0; 
        
        
      }  , 300);

      setAnimatePulse([false,false])
    }

    else {
      setPlayState(false)
    }
    
    return () => {
      mounted = false
      audio_0.removeEventListener('ended', () => {setButtonState(true);})
      audio_0.pause()
    }
  },[track, playState])


  // Tracks the scorers for the exercise
  useEffect(() => {
    setProgress(prevProgress => {
      const newProgress = [...prevProgress]
      newProgress[tab] = scores
      return newProgress
    })
  },[scores])


 

  
  return (
    <div className="flex flex-col items-center justify-center px-20 pb-10">
    { (resultPage === false) ? 
      <>
        
        {/* PLAY BUTTON */}
        <div className='mx-auto pt-7 md:pb-7 pb-10'>
          <button 
            disabled={playState || registerHit}
            className={`bg-pygblue rounded-3xl p-8 border-b-4 shadow-md
              ${playState || registerHit
                ? "opacity-40 cursor-default border-transparent" 
                : "hover:bg-blue-500  opacity-100 border-blue-700 active:translate-y-1 active:ease-in-out active:shadow-xl "}
              `}
            
            onClick={() => {setPlayState(true); setStart(true)}}
      >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.00 32.00" fill="white"
                className={`w-12 h-12 `}
                >
                  <path d="M16,6v20c0,1.1-0.772,1.537-1.715,0.971l-6.57-3.942C6.772,22.463,5.1,22,4,22H3c-1.1,0-2-0.9-2-2 v-8c0-1.1,0.9-2,2-2h1c1.1,0,2.772-0.463,3.715-1.029l6.57-3.942C15.228,4.463,16,4.9,16,6z M26.606,5.394 c-0.781-0.781-2.047-0.781-2.828,0s-0.781,2.047,0,2.828C25.855,10.3,27,13.062,27,16s-1.145,5.7-3.222,7.778 c-0.781,0.781-0.781,2.047,0,2.828c0.391,0.391,0.902,0.586,1.414,0.586s1.023-0.195,1.414-0.586C29.439,23.773,31,20.007,31,16 S29.439,8.227,26.606,5.394z M22.363,9.636c-0.781-0.781-2.047-0.781-2.828,0s-0.781,2.047,0,2.828C20.479,13.409,21,14.664,21,16 s-0.52,2.591-1.464,3.535c-0.781,0.781-0.781,2.047,0,2.828c0.391,0.391,0.902,0.586,1.414,0.586s1.023-0.195,1.414-0.586 C24.064,20.664,25,18.404,25,16S24.063,11.336,22.363,9.636z"></path>
              </svg>
      </button>
        </div>
      
    
        {/* CHOICES */}
        
        <div className={`md:w-4/5 w-full grid grid-cols-1 md:grid-cols-3 items-center justify-center text-center md:py-5
          ${
            start
            ? "opacity-100"
            : "opacity-40"
          }`}>
      
          <Button button={0} />

          <h1 className="text-gray-500 font-mono font-bold"> or </h1>

          <Button button={1} />

          {/* Translations */}
          <p className={` hidden md:block col-start-1 break-word text-sm mt-2 border-t-2 pt-1 text-gray-500 
            ${
              registerHit && target.MainStressSyllable === 1?
              'opacity-100'
              :'opacity-0'
            }`}> {target.translation} </p>

          <p className={`hidden md:block col-start-3 break-word text-sm mt-2 border-t-2 pt-1 text-gray-500
            ${
              registerHit && target.MainStressSyllable === 2?
              'opacity-100'
              :'opacity-0'
            }`}> {target.translation} </p>



        </div>
        
        

    
      </> 
      :
      <Results 
        results={results.value} 
        nextTab={nextTab}
        nextExercise={nextExercise}
      />
      
    } 
      </div>
    
  );
};
export default ListenAndClickIITwoStress;