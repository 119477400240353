import { Tooltip} from "@material-tailwind/react";
import { useMemo, useRef, useState, useCallback, useEffect } from "react";
import PaginatorExercise from "../PaginatorExercise";


const DisplayPairNonWord= ({ tracks }) => {
  console.log("NONWORD", tracks)
  const [playNext, setPlayNext] = useState(true)
  const [track, setTrack] = useState(1)
  const [buttonState, setButtonState] = useState(true)
  const [playState, setPlayState] = useState(true)
  const [playnow, setPlayNow] = useState(false)
  const [registerHit, setRegisterHit] = useState(false)

  
  const no_frames = [ { color: "red" },
  { transform: "scale(1.05)", color: "red" },
  { transform: "translateX(-5%)", color: "red" }, 
  { transform: "translateX(5%)", color: "red" }, 
  { transform: "scale(1.05)", color: "red" },
  { transform: "scale(1.0)" , color: "inherit"},
  ]

  const yes_frames = [ { color: "green" },
  { transform: "scale(1.05)", color: "green" },
  { transform: "rotate(0.02turn)" },
  { transform: "rotate(-0.02turn)" },
  { transform: "scale(1.05)", color: "green" },
  { transform: "scale(1.0)" , color: "inherit"},
  ]


  const inputRef_0 = useRef(null);
  const inputRef_1 = useRef(null);

  let choice = tracks[track-1]
  
  
  let first = choice.first
  
  console.log(choice, "nonword audio")
  let audio_0 = useMemo(() => new Audio(choice.audio),[choice]);
 
  console.log(audio_0, first, "nonword audio")

  const Playit_0 = () => {
    setRegisterHit(true)

    setPlayNext(false)
    
    
    var audio_duration = audio_0.duration ? audio_0.duration : 1
    console.log("duration", audio_duration, audio_0.duration)
    
    inputRef_0.current.animate(  
      yes_frames,{ duration: audio_duration*1000 , fill: "forwards", iterations: 1 } 
    );
    
    audio_0.play();
  };


  const Playit_1 = () => {
    setRegisterHit(true)

    setPlayNext(false)
    
    
    inputRef_1.current.animate(  
      no_frames,{ duration: 1000 , fill: "forwards", iterations: 1 } 
    );
    
    
  };

 

  

  useEffect(()=>{

    
    let mounted = true
    console.log(inputRef_0, inputRef_1, "refs")
    const frames = [ { color: "blue" },
    { transform: "scale(1.05)", color: "blue" },
    { transform: "translateY(-10%)", color: "blue" }, 
    { transform: "translateY(0%)" , color: "blue"}, 
    { transform: "scale(1.05)", color: "blue" },
    { transform: "scale(1.0)" , color: "inherit"},
    ]
    
    
    
    const handleCanPlayThrough = () => {
      var audio_duration = audio_0.duration ? audio_0.duration : 1
      audio_0.removeEventListener('canplaythrough', handleCanPlayThrough);
      console.log('Audio can be played through.');
      if (mounted)
      {
      setTimeout(() => {
        audio_0.play();
        inputRef_0.current.animate(  
          frames,{ duration: audio_duration*1000 , fill: "forwards", iterations: 1 } 
        );
        inputRef_1.current.animate(  
          frames,{ duration: audio_duration*1000 , fill: "forwards", iterations: 1 } 
        );
      }, audio_duration ) 
      }
      
    };

    if (track > 1 || playnow === true ) {
      
      setTimeout(()=> {
        
        audio_0.addEventListener('canplaythrough', handleCanPlayThrough);
        audio_0.addEventListener("ended", ()=>{setButtonState(false);})
        
        audio_0.currentTime = 0; 
        
        
      }  , 300);
    }
    else
    {
      setPlayState(false)
   

    }
    return () => {
      mounted = false
    
    }
  },[track, audio_0, playnow, playState])


 

  
  return (
    <div className="flex flex-col py-5 ">
   
    <div className="flex py-1 mx-auto">
    <button disabled={playState ? true : false} 
      className={`bg-blue-100 border-2 border-blue-300 rounded-xl text-gray-600 hover:text-red-600 font-bold py-1 px-2 text-xl ${
        playState
        ? "opacity-0"
        : "opacity-100"
    }`}
      onClick={()=>{setPlayNow(true); setPlayState(true)}}
    >
      Start 
    </button>
    </div>
    
  
    
      
      {first ?
      <div className={`w-full flex py-5 flex-cols-2 px-5 gap-x-5 ${
        playState
        ? "opacity-100"
        : "opacity-50"
    }`}
      >
      
        <Tooltip className="flex flex-col bg-indigo-300 font-medium text-white rounded-full px-3"
              animate={{
                mount: { scale: 1.2, y: 0 },
                unmount: { scale: 0, y: 25 },}} 
              content={playNext === false ? choice.translation : null}
              
        >
          <button disabled={buttonState} 
          className={`grow 
          ${ registerHit ?
          "bg-transparent hover:bg-indigo-100 hover:border rounded-full hover:shadow-xl font-serif:Times text-3xl font-semibold text-gray-600 hover:text-blue-600"
        : "py-1 px-2 bg-blue-100 border-2 border-blue-300 rounded-xl text-gray-600  font-serif:Times text-3xl font-semibold text-gray-600 hover:text-blue-600"}`}
          
          onClick={Playit_0}>
          
            <p className={`text-center ${
                choice.word.length < 13
                ? ""
                : "break-all"
                }`} ref={inputRef_0} >{choice.word}</p>
          
          </button>
        </Tooltip>
        
        <Tooltip className="flex flex-col bg-indigo-300 font-medium text-white rounded-full px-3" 
              animate={{
                mount: { scale: 1.1, y: 0 },
                unmount: { scale: 0, y: 25 },}} 
                content={playNext === false ? choice.nonword_translation : null}
        > 
            <button disabled={buttonState} 
            className={`grow 
            ${ registerHit ?
            "bg-transparent hover:bg-indigo-100 hover:border rounded-full hover:shadow-xl font-serif:Times text-3xl font-semibold text-gray-600 hover:text-blue-600"
          : "py-1 px-2 bg-blue-100 border-2 border-blue-300 rounded-xl text-gray-600  font-serif:Times text-3xl font-semibold text-gray-600 hover:text-blue-600"}`}
            onClick={Playit_1}  >
            
              <p className={`text-center ${
                choice.nonword.length < 13
                ? ""
                : "break-all"
                }`}
              ref={inputRef_1} >{choice.nonword}</p>
              
            </button>
        </Tooltip>
        </div>
        :
        <div className={`w-full flex py-5 flex-cols-2 px-5 gap-x-5 ${
          playState
          ? "opacity-100"
          : "opacity-50"
      }`}
        >
        <Tooltip className="flex flex-col bg-indigo-300 font-medium text-white rounded-full px-3 "
              animate={{
                mount: { scale: 1.1, y: 0 },
                unmount: { scale: 0, y: 25 },}} 
                content={playNext === false ? choice.nonword_translation : null}
        > 
            <button disabled={buttonState} 
            className={`grow 
            ${ registerHit ?
            "bg-transparent hover:bg-indigo-100 hover:border rounded-full hover:shadow-xl font-serif:Times text-3xl font-semibold text-gray-600 hover:text-blue-600"
          : "py-1 px-2 bg-blue-100 border-2 border-blue-300 rounded-xl text-gray-600  font-serif:Times text-3xl font-semibold text-gray-600 hover:text-blue-600"}`}
            onClick={Playit_1} >
            
              <p className={`text-center ${
                choice.nonword.length < 13
                ? ""
                : "break-all"
                }`} ref={inputRef_1} >{choice.nonword}</p>
            
            </button>
        </Tooltip>
        
        <Tooltip className="flex flex-col bg-indigo-300 font-medium text-white rounded-full px-3"
              animate={{
                mount: { scale: 1.2, y: 0 },
                unmount: { scale: 0, y: 25 },}} 
              content={playNext === false ? choice.translation : null}
        >
          <button disabled={buttonState}
          className={`grow 
          ${ registerHit ?
          "bg-transparent hover:bg-indigo-100 hover:border rounded-full hover:shadow-xl font-serif:Times text-3xl font-semibold text-gray-600 hover:text-blue-600"
        : "py-1 px-2 bg-blue-100 border-2 border-blue-300 rounded-xl text-gray-600  font-serif:Times text-3xl font-semibold text-gray-600 hover:text-blue-600"}`}
          onClick={Playit_0}>
          
            <p className={`text-center ${
                choice.word.length < 13
                ? ""
                : "break-all"
                }`} ref={inputRef_0} >{choice.word}</p>
          
          </button>
        </Tooltip> 
        </div>
}
        <div className='scale-75'>
          <PaginatorExercise maxpage={Math.ceil(tracks.length)} page={track} offState={playNext}
                  clickHandler={(page) => {
                      setTrack(page);
                      setButtonState(true);
                      setPlayState(true);
                      setPlayNext(true);
                      setRegisterHit(false)

                  }}
          />
        </div>
      
        
      </div>
    
  );
};
export default DisplayPairNonWord;