import { useState } from "react";

const ForgotPassword = ({forgotPassHandler}) => {
    const [username, setName] = useState("");
    const [ok, setOk] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const onFormSubmit = async (event) => {
        event.preventDefault();
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/users/forgot-pw/${username}`, {method: "POST"});
        if (response.ok) {
            setOk(true);
        }
        setSubmitted(true);
    }

    return (
        <div className="flex flex-col mx-auto w-full h-full">
           
                <form onSubmit={onFormSubmit}>
                 <div  className="flex flex-col justify-center items-center">
                    <label className="text-xl font-semibold">Enter username or email:</label>
                    <input 
                        type="text"
                        value={username}
                        className="input input-bordered input-accent w-full max-w-xs m-3"
                        onChange={(e) => setName(e.target.value)}
                    />
                    <button className="bg-blue-200 hover:bg-blue-300 rounded-xl p-2">Send email</button>
                    </div>
                </form>
                <div>
                {submitted &&
                    <div className="flex flex-col justify-center items-center">
                        {
                            ok ?
                            <div className="flex flex-col">
                            <div className="text-xl text-blue-400">Email sent</div> 
                            <button onClick={()=>{forgotPassHandler(false)}} className="bg-blue-400 p-2 rounded-xl">Go back</button>
                            </div>:
                            "User does not exist"
                        }
                    </div>
                }
                </div>
            </div>
        
    )
}

export default ForgotPassword;