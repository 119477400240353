
import Selector from "./Components/Selector";
import { useEffect, useState } from "react";
import useAuth from "./hooks/useAuth";
const ActivitiesInterface = ({tabs = ['Catalan (Central)','Spanish (Castilian)', 'English', 'Italian', 'Greek', 'Norwegian']})=>{
    const {auth, setAuth} = useAuth();
    const [activeUsers, setActiveUsers] = useState([])
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [picked, setPicked] = useState(null)
    const [pickedInfo, setPickedInfo] = useState(null)
    const types = ['ListenAndWrite', 'ListenAndClick', 'ListenAndClickII', 'ListenAndSpeak']

        useEffect(()=>{
            const token = localStorage.getItem("token");
            fetch(`${process.env.REACT_APP_USER_API_URL}/stats/active_users/${tabs[activeTabIndex]}`, {
                method: "GET",
                headers:  {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })
                .then(response=>{
                    if (response.ok) {
                        return response.json();
                    }
                    if (response.status === 401) {
                        localStorage.removeItem("token");
                        setAuth({});
                    }
                })
                .then(json=>{
                    if (json) {
                        setActiveUsers(json);
                    }
                })
        },[activeTabIndex]) 
        useEffect(()=>{
        
            if (picked) {
            const token = localStorage.getItem("token");
            fetch(`${process.env.REACT_APP_USER_API_URL}/stats/haloinfo/${tabs[activeTabIndex]}/${picked}`, {
                method: "GET",
                headers:  {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })
                .then(response=>{
                    if (response.ok) {
                        return response.json();
                    }
                    if (response.status == 401) {
                        localStorage.removeItem("token");
                        setAuth({});
                    }
                })
                .then(json=>{
                    var act = json
                    setPickedInfo(act)
                    console.log(act, "act")
                   
                })     
            } 
             
          
        },[picked, activeTabIndex]) 


        return(
        <div className="flex flex-col "><p>Activities</p>

                <Selector 
                        clickHandler={(tab_index) =>
                                {
                                    setActiveTabIndex(tab_index); setPicked(null); setPickedInfo(null)
                                }
                        } 
                        tabsData={tabs} 
                        active = {activeTabIndex}


                        
                    
                    />
                    <div className="border-r-2 border-l-2 border-b-2 border-gray-200">
                        <div className="flex flex-col md:flex-row justify-around border-2 border-emerald-400 rounded-xl  m-5 p-10 gap-10 ">    

                            <div className="">    
                                <div className="flex flex-col">
                                <p className="text-xl text-center font-semibold text-blue-500">Active users </p>
                                    <div className="grid grid-cols-1 border-2 border-gray-200 rounded-xl p-5 mt-5 items-left gap-5">
                                        {(activeUsers.length>0) && activeUsers.map((user,i) =>{
                                            

                                            return(
                                                
                                                user&&<button onClick={()=>{setPicked(user)}}
                                                                className="truncate text-xl text-indigo-500 bg-indigo-100 text-center border-2 border-indigo-800 rounded-md p-2" key={user}>
                                                                    {user}
                                                        </button>
                                            )
                                        })}
                                        </div>
                                </div>
                            </div>

                            {(pickedInfo)&& 
                                <div className="">
                                    <div className="flex flex-col">
                                    
                                    <div className=" flex flex-col lg:flex-rowtext-xl font-semibold text-blue-500 mx-auto"><p className="text-blue-600 text-xl">Exercises by</p> <p className="truncate">{picked}</p></div>
                                        
                                    <div className="mx-auto divide-y-2 flex flex-col border-2 border-teal-400 rounded-xl py-5 lg:px-20 mt-5">
                                            
                                        {Object.keys(pickedInfo).map((key) =>{
                                            
                                            return( 
                                            <>
                                            <div className="grid grid-cols-1  lg:grid-cols-2"> 
                                            <div className="text-xl text-blue-600 font-semibold" key={key}>{key}:</div><div></div> 
                                                
                                                    <div></div>
                                                    <div className="">
                                                    
                                                {
                                                Object.keys(pickedInfo[key]).map((akey)=>{
                                                return(
                                                    
                                                    types.includes(akey)&&
                                                    <div className="flex flex-col lg:flex-row gap-1 lg:gap-4">{akey}: {pickedInfo[key][akey].passed ? <p className="text-blue-400">Passed</p> : <p className="text-red-400">Failed</p>}</div>
                                                        
                                                    
                                                    )
                                                
                                                })}
                                                 <div className="flex flex-col lg:flex-row gap-1 lg:gap-4 ">Fullfilled: <p className="text-blue-700 font-semibold">{pickedInfo[key].fulfilled}</p></div>
                                                </div>

                                                
                                                </div>
                                           
                                            </>
                                        )
                                            
                                        })}
                                    </div>
                                    </div>
                                </div>
                            }
                            
                            
                        </div>
                    </div>




        </div>)
    }
export default ActivitiesInterface;