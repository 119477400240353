import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";

const getCharacterValidationError = (string) => {
    return `Your password must have at least 1 ${string} character`;
  };
const schema = yup.object({
    password: yup.string()
        .required("Please enter a password")
        // check minimum characters
        .min(8, "Password must have at least 8 characters")
        // different error messages for different requirements
        .matches(/[0-9]/, getCharacterValidationError("digit"))
        .matches(/[a-z]/, getCharacterValidationError("lowercase"))
        .matches(/[A-Z]/, getCharacterValidationError("uppercase")),
        confirmPassword: yup.string()
        .required("Please re-type your password")
        // use oneOf to match one of the values inside the array.
        // use "ref" to get the value of passwrod.
        .oneOf([yup.ref("password")], "Passwords do not match")
});

const ResetPw = () => {
    let {id, code} = useParams();

    const navigate = useNavigate();

    const {
    register,
    handleSubmit,
    formState: { errors }, control
    } = useForm({
        resolver: yupResolver(schema)
    });

    const onFormSubmit = async (data) => {
        const body = JSON.stringify({
            id: id,
            code: code,
            pw: data.password
        });
        console.log(body);
        const response = await fetch(
            `${process.env.REACT_APP_USER_API_URL}/users/reset-pw`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: body
            }
        );
        if (response.ok) {
            navigate("/");
        }
    }

    const onErrors = (errors) => console.error(errors);

    return (
        <div>
            <form onSubmit={handleSubmit(onFormSubmit, onErrors)}>
                <input
                    type="password"
                    autoComplete="off"
                    placeholder="Select password"
                    className="input input-bordered input-accent w-full max-w-xs m-3"
                    name="password"
                    {...register("password")}
                />
                {errors?.password && errors.password.message}

                <input
                    type="password"
                    autoComplete="off"
                    placeholder="Repeat password"
                    className="input input-bordered input-accent w-full max-w-xs m-3"
                    name="confirmPassword"
                    {...register("confirmPassword")}
                />
                {errors?.confirmPassword && errors.confirmPassword.message}
                <button>Reset password</button>
            </form>
        </div>
    )
}

export default ResetPw;