import { useMemo, useState, useEffect } from "react";
import Results from "../Display/Results";
import 'animate.css'; // npm install animate.css --save


// use tracks.length for nrExercises
const ListenAndClick = ({ tracks, info, resultsHandler, nextTab, nextExercise, progress, setProgress, tab}) => {
  const [track, setTrack] = useState(1)
  const [playState, setPlayState] = useState(false) // sounds are played when true
  const [results, setResults] = useState({'results': {}, 'value': []})
  const [buttonState, setButtonState] = useState(false) // pair buttons are disabled when false
  const [start, setStart] = useState(false)
  const [registerHit, setRegisterHit] = useState(false)
  const [resultPage, setResultPage] = useState(false)
  const [scores, setScores] = useState(progress[tab] ? progress[tab] : [])

  // console.log('JOY: exercise, level 2, listen and click, exercise', info.name)
 
  //  For animating the pair buttons when playing, '0s' is the default duration
  const [animatePulse, setAnimatePulse] = useState([false,false])
  const [animateRight, setAnimateRight] = useState([false,false])
  const [animateWrong, setAnimateWrong] = useState([false,false])
  let pulse = `animate__pulse`
  let correct = `animate__tada bg-green-500 border-green-700 opacity-60`	
  let wrong = `animate__shakeX bg-red-500 border-red-700 opacity-60`

  // Tracks and audios
  let choice = tracks[track-1][0]
  let target = tracks[track-1][1]

  let first = true
  let second = false
  if (choice[0].word === target.word)
  {
      first = true;
      second = false
  }
  else
  {
      first = false;
      second = true;
  }

 
  let audio_0 = new Audio()
 
  var readyState = audio_0.readyState;

  let audio_1 = new Audio()
  let audio_2 = new Audio()
  audio_0.src = choice[0].audio
  audio_0.load()
  audio_1.src = choice[1].audio
  audio_1.load()
  audio_2.src = target.audio
  audio_2.load()

  



  const PlayNext = () => {
    var audio_duration = 2000
    
    if (audio_0.duration) 
    {
      audio_duration = audio_0.duration*1000 + 300
      if (audio_1.duration){
        audio_duration = Math.max(audio_0.duration*1000, audio_1.duration*1000)+300
      }
    }
    else
    {
      if (audio_1.duration){
        audio_duration =  audio_1.duration*1000 + 300
      }
    }
  
    setTimeout(() => {
    if (track < tracks.length)
      {
        setTrack(track + 1);
        setButtonState(false);
        setPlayState(true);
        setRegisterHit(false);
        setAnimatePulse([false,false]);
        setAnimateRight([false,false]);
        setAnimateWrong([false,false]);
      }
      else
      {
        setResultPage(true)
        resultsHandler(results)
      }
    }, audio_duration)
  }

  const Playit_0 = (str) => {

    var audio_duration = audio_0.duration ? audio_0.duration : 1
    audio_0.play();
    setRegisterHit(true)
    setAnimatePulse([false,false])
    console.log('button 1 clicked')
    
    if (str.first === true)
    {
      if (!results.results[track]){
        results.results[track] = {"choice" : choice, "target": target, "eval": true}
        results.value.push(true)
        setResults(results)
      }

      setAnimateRight([true,false])
      setScores([...scores, 1])
    }

    else
    {
      if (!results.results[track]){
        results.results[track] = {"choice" : choice, "target": target, "eval": false}
        results.value.push(false)
        setResults(results)
      }
      setAnimateWrong([true,false])
      setScores([...scores, 0])
    }
    
    setRegisterHit(true)
    PlayNext()
  };

  // After clicking second choice
  const Playit_1 = (str)  => {
    var audio_duration = audio_1.duration ? audio_1.duration : 1
    audio_1.play();

    setRegisterHit(true)
    setAnimatePulse([false,false])
    console.log('button 2 clicked')

    if (str.second === true)
    {

    if (!results.results[track]){
      results.results[track] = {"choice" : choice,"target": target, "eval": true}
      results.value.push(true)
      setResults(results)
    }
    
    setAnimateRight([false,true])
    setScores([...scores, 1])
    }
    else
    {
      if (!results.results[track]){
        results.results[track] = {"eval" :false, "target": target, "choice" : choice}
        results.value.push(false)
        setResults(results)
        
      }

      setAnimateWrong([false,true])
      setScores([...scores, 0])
    }
    PlayNext()
  };

  

  useEffect(()=>{
    
    console.log("useeffect 1?")
    let mounted = true; 

    const FireUp = () => {
      console.log("useeffect 2?")
      audio_0.removeEventListener("ended", FireUp)
      var audio_duration = audio_1.duration ? audio_1.duration : 1
      console.log(audio_0.readyState, "readystate fireup")
      setTimeout(() => {
        
        setAnimatePulse([false,true])     
        audio_1.play();
          
      }, 500) 
    
    }
  
    
    const FireUpTarget = () => {
      console.log("useeffect 3?")
      console.log(audio_2.readyState, "readystate targetfireup")
      setTimeout(() => {

        setAnimatePulse([false,false])

      }, 200);

      audio_1.removeEventListener("ended", FireUpTarget)
      var audio_duration = audio_2.duration ? audio_2.duration : 1
      
      setTimeout(() => {

        setAnimatePulse([true,true])
        audio_2.play();
        
      }, 300) 
    
    }



    const handleCanPlayThrough = () => {
      console.log("useeffect 4?")
      console.log(audio_0.readyState, "readystate handlecanplaythrough")
      var audio_duration = audio_0.duration ? audio_0.duration : 1
      audio_0.removeEventListener('canplaythrough', handleCanPlayThrough);
      if (mounted)
      {
      setTimeout(() => {

        audio_0.play();
        setAnimatePulse([true,false])

      }, 300 ) 
      }
      
    };

    if (playState === true ) {
      console.log("useeffect 5?")
      setTimeout(()=> {
       
        audio_0.addEventListener('canplaythrough', handleCanPlayThrough)
        
        audio_0.addEventListener("ended", FireUp)
        audio_1.addEventListener("ended", FireUpTarget)
        audio_2.addEventListener("ended", ()=>{setButtonState(true); setPlayState(false);})
        
        audio_0.currentTime = 0; 
        audio_1.currentTime = 0;
        audio_2.currentTime = 0; 
        
        
      }  , 300);

      setAnimatePulse([false,false])
    }

    else {
      console.log("useeffect 6?")
      setPlayState(false)
    }
   
    return () => {
      console.log("useeffect 7?")
      mounted = false
      audio_1.removeEventListener("ended", FireUpTarget)
      audio_2.removeEventListener("ended", ()=>{setButtonState(true);})
      audio_0.pause();
      audio_1.pause();
      audio_2.pause();

    }
  },[track, playState])


  // Tracks the scorers for the exercise
  useEffect(() => {
    setProgress(prevProgress => {
      const newProgress = [...prevProgress]
      newProgress[tab] = scores
      return newProgress
    })
  },[scores])


 

  
  return (
    <div className="flex flex-col items-center justify-center px-20 pb-10">
    { (resultPage === false) ? 
      <>
        
        {/* PLAY BUTTON */}
        <div className='mx-auto pt-7 md:pb-7 pb-10'>
          <button 
            disabled={playState || registerHit}
            className={`bg-pygblue rounded-3xl p-8 border-b-4 shadow-md
              ${playState || registerHit
                ? "opacity-40 cursor-default border-transparent" 
                : "hover:bg-blue-500  opacity-100 border-blue-700 active:translate-y-1 active:ease-in-out active:shadow-xl "}
              `}
            
            onClick={() => {setPlayState(true); setStart(true)}}
          >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.00 32.00" fill="white"
                className={`w-12 h-12 `}
                >
                  <path d="M16,6v20c0,1.1-0.772,1.537-1.715,0.971l-6.57-3.942C6.772,22.463,5.1,22,4,22H3c-1.1,0-2-0.9-2-2 v-8c0-1.1,0.9-2,2-2h1c1.1,0,2.772-0.463,3.715-1.029l6.57-3.942C15.228,4.463,16,4.9,16,6z M26.606,5.394 c-0.781-0.781-2.047-0.781-2.828,0s-0.781,2.047,0,2.828C25.855,10.3,27,13.062,27,16s-1.145,5.7-3.222,7.778 c-0.781,0.781-0.781,2.047,0,2.828c0.391,0.391,0.902,0.586,1.414,0.586s1.023-0.195,1.414-0.586C29.439,23.773,31,20.007,31,16 S29.439,8.227,26.606,5.394z M22.363,9.636c-0.781-0.781-2.047-0.781-2.828,0s-0.781,2.047,0,2.828C20.479,13.409,21,14.664,21,16 s-0.52,2.591-1.464,3.535c-0.781,0.781-0.781,2.047,0,2.828c0.391,0.391,0.902,0.586,1.414,0.586s1.023-0.195,1.414-0.586 C24.064,20.664,25,18.404,25,16S24.063,11.336,22.363,9.636z"></path>
              </svg>
          </button>
        </div>

        
        
        <div className={`md:w-4/5 w-full grid grid-cols-1 md:grid-cols-3 items-center justify-center text-center md:py-5
          ${
            start
            ? "opacity-100"
            : "opacity-40"
          }`}>

          {/* CHOICES */}
          <div className="flex flex-col items-center mb-5 md:mb-0">
            

            <div>
              {/*  Shows only on small screens */}
              <p className=" block md:hidden break-word text-sm mb-2 border-b-2 pb-1 text-gray-500"> {choice[0].translation} </p>

              {/* Choice 1 button */}
              <button 
                disabled={!buttonState || registerHit} 
                className={`border-b-4 py-5 px-20 rounded-full font-serif:Times text-3xl font-semibold text-white shadow-md animate__animated
                  ${animatePulse[0] ? pulse : ""}
                  ${animateRight[0] ? correct : ""}
                  ${animateWrong[0] ? wrong : ""}
                  ${!animateRight[0] && !animateWrong[0] ? "bg-pygblue border-blue-700 hover:bg-blue-500" : ""}
                `}
                style={{ '--animate-duration': '1s' }} // Change the duration of the animation here. Default: 1s
                onClick={() => { Playit_0({ first }) }}>
                  
                <p className={`text-center text-white ${choice[0].word.length < 13 ? "" : "break-word"}`}>
                  {choice[0].word}
                </p>
                
              </button>

              

            </div>
            

          </div>

          
          <h1 className="text-gray-500 font-mono font-bold"> or </h1>

          <div className="flex flex-col items-center mt-5 md:mt-0">

            <div>
              {/* Choice 2 button */}
              <button 
                disabled={!buttonState || registerHit} 
                className={`border-b-4 py-5 px-20 rounded-full font-serif:Times text-3xl font-semibold text-white shadow-md animate__animated
                  ${animatePulse[1] ? pulse : ""}
                  ${animateRight[1] ? correct : ""}
                  ${animateWrong[1] ? wrong : ""}
                  ${!animateRight[1] && !animateWrong[1] ? "bg-pygblue border-blue-700 hover:bg-blue-500" : ""}
                `}
                style={{ '--animate-duration': '1s' }} // Change the duration of the animation here. Default: 1s
                onClick={() => { Playit_1({ second }) }}>
                <p className={`text-center text-white ${choice[1].word.length < 13 ? "" : "break-word"}`}>
                  {choice[1].word}
                </p>
              </button>

              {/*  Shows only on small screens */}
              <p className="block md:hidden break-word text-sm mt-2 border-t-2 pt-1 text-gray-500"> {choice[1].translation} </p>

            </div>
            
          </div>

          <p className="hidden md:block col-start-1 break-word text-sm mt-2 border-t-2 pt-1 text-gray-500"> {choice[0].translation} </p>
          <p className="hidden md:block col-start-3 break-word text-sm mt-2 border-t-2 pt-1 text-gray-500"> {choice[1].translation} </p>


        </div>
        
    
      </> 
      :
      <Results 
        results={results.value} 
        nextTab={nextTab}
        nextExercise={nextExercise}
      />
      
    } 
    </div>
    
  );
};
export default ListenAndClick;

