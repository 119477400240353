import Paginator from "../Paginator"
import useSWR from "swr";
import CardSoundGroup from "./SoundGroups/CardSoundGroup";
import CardSoundGroupNorwegian from "./SoundGroups/CardSoundGroupNorwegian";
import CardSoundGroupEnglish from "./SoundGroups/CardSoundGroupEnglish";
import { useEffect, useState, useRef } from "react";

const fetcher = (...args) => fetch(...args).then((res) => res.json());


const Cards = ({L2Lang, L1L2List, AllSounds, category, exerciseHandler, focusId}) => {
    
    const itemsRef = useRef(null);
    useEffect(()=>{ if (focusId) {scrollToId(focusId)}},[focusId])
    function scrollToId(itemId) {
        const frames = [ 
        { transform: "scale(0.9)"},
        { transform: "scale(1.05)"},
        { transform: "scale(0.9)"},
        { transform: "scale(0.95)"},
        { transform: "scale(1.0)"},
        ]    
        const map = getMap();
        const node = map.get(itemId);
        node.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start'
        });
        node.animate(  
            frames ,{ duration: 2000 , fill: "forwards", iterations: 1 } 
              );
        console.log(node, "node")
        }
  
    function getMap() {
      if (!itemsRef.current) {
        // Initialize the Map on first usage.
        itemsRef.current = new Map();
      }
      return itemsRef.current;
    }
  
  
    let [pageIndex, setPageIndex] = useState(1);

    useEffect(()=>{
        setPageIndex(1)

        },[L2Lang])
    

    /*const { data, error, isLoading } = useQuery({
        queryKey: [L2Lang,pageIndex],
        queryFn: () =>
        axios
            .get(`${process.env.REACT_APP_API_URL}sounds/${L2Lang}/3?page=${pageIndex}`)
            .then((res) => res.data),
            
        });
    */
    

    let path = ''
    if (category)
    {
        path = 'soundspercategory/'+category
    }
    else
    {
        path = 'soundswithposition'
    }
 
    const { data, error } = useSWR(
        
            `${process.env.REACT_APP_API_URL}/${path}/${L2Lang}`,
            fetcher,  {
                revalidateIfStale: false,
                revalidateOnFocus: false,
                revalidateOnReconnect: false
                }
            );
    
    if (error) return (
        <div className="flex justify-center shadow-lg mx-auto  2xl:py-48 mx-auto p-6 2xl:px-12">
            <p>Data servers are not availableat the moment</p>
        </div>

      

    )
    if (!data) return ( 
        <div className="flex justify-center shadow-lg mx-auto  2xl:py-48 mx-auto p-6 2xl:px-12">
            <div  role="status">
                <svg aria-hidden="true" className="w-32 h-32 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
            <span className="sr-only">Loading...</span>
            </div>
         </div>
        
    )

    return(
       

           

                        
            <div className="flex flex-col w-1/2 rounded-xl  mx-auto bg-white border-4 shadow-2xl">
                <p className="text-xl md:text-3xl text-blue-600 font-semibold text-center pb-20 pt-10"> {category}</p>
                {data.soundgroups && data.soundgroups.map(
                
                    (el,i)=>{
                        const key_value = category+'_'+el.sound
                        
                        return (    

                            
                                    L2Lang === 'Norwegian' ?
                                        <div key={key_value}  className="flex justify-center p-20">  
                                        
                                            <CardSoundGroupNorwegian 
                                                include = {L1L2List.length !== 0 ? 
                                                                L1L2List.includes(el.sound) ? true :    AllSounds.includes(el.sound) ? false :  true 
                                                            :
                                                            true
                                                            }  
                                                exercise = {el} 
                                                l2={L2Lang}
                                                
                                            />   
                                        </div>


                                    :   
                                    
                                        L2Lang === 'English' ?

                                            <div key={key_value}  className="flex justify-center p-20 ">  

                                                <CardSoundGroupEnglish  
                                                include = {L1L2List.length !== 0 ? 
                                                                L1L2List.includes(el.sound) ? true :    AllSounds.includes(el.sound) ? false :  true 
                                                            :
                                                            true
                                                            }  
                                                    exercise = {el} 
                                                    l2={L2Lang}
                                                        
                                                />   
                                        </div>
                                        :
                                        <div     
                                            key={key_value}  
                                            className="flex justify-center"
                                            ref={(node) => {
                                                const map = getMap();
                                                if (node) {
                                                  map.set(key_value, node);
                                                } else {
                                                  map.delete(key_value);
                                                }
                                              }}
                                        
                                        
                                        >  
                                        
                                            <CardSoundGroup
                                                
                                                include = {
                                                                L1L2List.length !== 0 ? L1L2List.includes(el.sound) ? true :  
                                                                    AllSounds.includes(el.sound) ? false :  true 
                                                                :
                                                                true
                                                            }  
                                                exercise = {el} 
                                                
                                                exHandler={(state) =>
                                                    {
                                                        exerciseHandler(state);
                                                        
                                                    }}
                                                
                                            />   
                                        </div>

                                        
                                                    
                        )
                    }
                )}
            </div>


           
        
                            

        

    );





};
export default Cards;
