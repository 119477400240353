import { useState } from "react";

const Selector =  ({tabsData, clickHandler }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
   
    <div className="pt-2 flex flex-row rounded-md">
    
    {tabsData.map((tab, idx) => {
    return (
        <button 
        key={idx}
        className={`grow py-2  hover:bg-gray-100 px-2 rounder-xl text-left  transition-colors duration-300 ${
            idx === activeTabIndex
            ? " shadow-md border-b-2  text-blue-600 font-bold text-md hover:border-gray-200"
            : "text-gray-400 text-sm font-semibold hover:text-gray-600 hover:font-bold"
        }`}
        onClick={() => {
          clickHandler(idx)
          setActiveTabIndex(idx)
        }
        }>
        {tab.label}
        </button>
    );
    })}
</div>

  );
}
export default Selector;