import Course from "../components/Course";
import useAuth from "../../Users/hooks/useAuth";
import Login from "../../Users/Login";
import ForgotPassword from "../../Users/ForgotPw";
import Register from "../../Users/Register";
import { useEffect, useState } from "react";
import CategoryDropdownL2 from "../components/CategoryDropdownL2";
import CategoryDropdownL1 from "../components/CategoryDropdownL1";


const Entry = ()=>{
    const { auth, setAuth } = useAuth();
    const [forgotPass, setForgotPass] = useState(false)
    const [register, setRegister] = useState(false)
    const [L1Lang, setL1Lang] = useState('Other')
    const [L2Lang, setL2Lang] = useState("Catalan (Central)")

    const checkToken = async () => {
        const token = localStorage.getItem("token");
        if (token && !auth?.email) {
            const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/users/me`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              });
            if (response.ok) {
                let userData = await response.json();
                userData["token"] = token;
                setAuth(userData);
            }
        }
    }

   useEffect(() => {checkToken()});

    if (auth?.email) {
        return <div>
            <Course inL1={L1Lang} inL2={L2Lang}/>
        </div>
    }

    return(
    
        <div>

        {(!auth?.email) && 
        <div className="flex flex-col gap-y-10">
            <div className="flex flex-col px-10 py-10 gap-y-10 my-5 border-2 border-sky-600 rounded-xl shadow-md mx-auto bg-sky-50"> 
                <p className="mx-auto text-4xl  font-bold text-blue-600">
                    Pygmalion:
                </p>
                <p className=" text-xl  mx-auto font-semibold text-blue-400">            
                    the prototype for The computer-assisted listening and speaking tutor (CALST)
                </p>
            </div>
            {register   ?   
                            <Register successHandler={(state)=> {setRegister(state)}}/> 

                        :   
                            <div className="scale-90 sm:scale-75 grid grid-cols-1 border-blue-300 lg:grid-cols-2 gap-4 md:mx-10 place-content-between md:border-2 rounded-xl md:shadow-xl space-y-10   ">

                             
                                    <div className="scale-75 sm:scale-100 flex flex-col sm:mx-auto my-10 gap-y-10 border-2 border-gray-200 p-10 rounded-xl shadow-md">
                                
                                        
                                        <div className="flex flex-col">
                                            <CategoryDropdownL2 selectHandler={(event) => {
                                                                setL2Lang(event.target.value);
                                                            }}
                                                            elValue={L2Lang}/>
                                            <p className='mx-auto text-md font-mono font-semibold text-blue-400'>
                                                Select language course
                                            </p>
                                        </div>

                                        <div className="flex flex-col">
                                            <CategoryDropdownL1 
                                                selectHandler={(event) => {
                                                    setL1Lang(event.target.value);
                                                }}
                                                elValue={L1Lang}
                                            />
                                            <p className='mx-auto text-md font-mono font-semibold text-blue-400'>
                                                Your native language
                                            </p>
                                        </div>
                                                    
                                    </div>
                                
                        
                                   
                                {
                                forgotPass  ?   
                                                <ForgotPassword forgotPassHandler={(state) =>{setForgotPass(state)}} />
                                            : 
                                            
                                                <div className="scale-75 sm:scale-100 flex flex-col sm:mx-auto my-10 gap-y-10">  
                                                <Login  forgotPassHandler={(state) =>{setForgotPass(state)}}
                                                        registerHandler={(state) =>{setRegister(state)}}/>
                                                </div>
                                }
                            </div>  
                            
            }

        </div>
        }
        </div>
        
    )
}
export default Entry;
