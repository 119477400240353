import {useState, useEffect} from 'react'
import sortingShuffle from '../../../shuffle';
import ListenAndClick from '../ListenAnd/ListenAndClick';
import Selector from './Selector';
import ListenAndClickII from '../ListenAnd/ListenAndClickII';
import ListenAndWrite from '../ListenAnd/ListenAndWrite';
import ListenAndSpeak from '../ListenAnd/ListenAndSpeak';
import Tabs from '../Player/PlayerTabs';
import Summary from '../Display/Summary';
import { Link } from 'react-router-dom';
import 'animate.css';

const ExercisePlayerNorwegian = ({id, l2, dialect, exerciseHandler}) => {
    const [results, setResults] = useState({})
    const [showResults, setShowResults] = useState(null)
    const [PlayTracks, setPlayTracks] = useState([])
    const [PlayTracksNW, setPlayTracksNW] = useState([])
    const [PlayTracksNW_LS, setPlayTracksNW_LS] = useState([])
    const [PlayTracksNW_LW, setPlayTracksNW_LW] = useState([])
    const [status, setStatus] = useState(null);
    const [info, setInfo] = useState({})
    const [NumOfParts, setNumOfParts] = useState(4)
    const [hideTitle, setHideTitle] = useState(false)
    const [nextExercise, setNextExercise] = useState(null)

    const tabsData = [  
        {label: 'Listen & Click I', instructions:'In this listening exercise you hear two very similar words, followed by a repetition of one of these words. Can you hear which of the two words was repeated? This is called a discrimination task.'},
        {label: 'Listen & Click II', instructions: 'In this listening exercise you hear only one word. Can you hear which of the two words was said?'},
        {label: 'Listen & Speak', instructions: 'In this exercise, you first hear the tutor’s pronunciation of the word. When the bar under the recordings bar starts to run, you speak the word into the microphone. Compare your own pronunciation with that of the tutor. Not happy with your pronunciation? Press the loudspeaker button and give it another try.'},
        {label: 'Listen & Write', instructions: 'It can be hard to know how words that you hear are written. Can you write the word you hear correctly?'},
      ];

    const tabsDataNW = [  
        {label: 'Listen & Click II'},
        {label: 'Listen & Speak'},
        {label: 'Listen & Write'},
    ]; 
    const [nrExercises, setNrExercises] = useState([]) // Structure example [10,10,10,10]
    const [progress, setProgress] = useState([]) // Structure example[[1,0,1],[0],[0],[0]]

    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [tabs, setTabs] = useState(tabsData)
    const [activeTabIndexDia, setActiveTabIndexDIa] = useState(0);
    const [tabsDia, setTabsDia] = useState([])

    useEffect(()=>{
        setProgress([])
    },[dialect])
    const findNextExercise = (progress, activeTabIdx) => {
        const length = tabsData.length
    
        // Check from activeTabIndex to the end
        for (let idx = activeTabIdx +1 ; idx < length; idx++) {
            console.log(idx, "idx first", length, "length")
            if (progress[idx] === undefined || (progress[idx] && progress[idx].length < nrExercises[idx])) {
                setNextExercise(tabs[idx].label) 
                console.log(tabs[idx].label, "nextExercise")
                return
            }
        }
    
        // Check from the start of the array to activeTabIndex
        // for (let idx = 0; idx < activeTabIndex; idx++) {
        //     console.log(idx, "idx second", activeTabIndex, "activeTabIndex")
        //     if (progress[idx] === undefined || (progress[idx] && progress[idx].length < nrExercises[idx])) {
        //         setNextExercise(tabs[idx].label)
        //         console.log(tabs[idx].label, "nextExercise")
        //         return
        //     }
        // }
    
        // If no such index is found, return -1 for now. TODO?: handle this case
        return -1;
        }


        useEffect(() => {
            if (progress[activeTabIndex]) {
                const updatedProgress = [...progress]
                updatedProgress[activeTabIndex] = []
                setProgress(updatedProgress)
            }
        }, [activeTabIndex])

        useEffect(() => {

            if (activeTabIndex !== tabsData.length-1 
                && progress[activeTabIndex] 
                && progress[activeTabIndex].length === nrExercises[activeTabIndex]) {
                
                setTimeout(() => {
                    setHideTitle(true)
                    
                }, 100);
                findNextExercise(progress, activeTabIndex)
    
            }
            else {
                setHideTitle(false)
            }
    
        }, [progress])
    

        useEffect(() => {
        
            if (PlayTracks.length === 0) {setTabs(tabsDataNW); setNumOfParts(3)}
                        else {setTabs(tabsData); setNumOfParts(4)}
                        
        }, [PlayTracks])

    

    let data_source=`${process.env.REACT_APP_API_URL}/norwegian/exercise/${id}`
    useEffect(()=>{
        const fetchAssets = async () => {
            setStatus('IN_PROGRESS');
            try { 
        
        await fetch(data_source)
            .then(response=>response.json())
                .then(json=>{
                    setStatus('SUCCESS')
                    var tracks = [];
                    var nwproducts = [];
                   
                    
                    var dia = dialect
                    for (const lang of json.MP_wordpairs) { 
                        if (lang[0]['audio_'+dia.label].length > 0 && lang[1]['audio_'+dia.label].length > 0)   {             
                            var deal_mp = 
                                [
                                    [   {audio : `${process.env.REACT_APP_STATIC_URL}`+ sortingShuffle(lang[0]['audio_'+dia.label])[0].replace('C:\\CalstFiles\\WordObjectContent','').replace('D:\\Workspace\\CalstFiles\\WordObjectContent',''), word : lang[0].word, translation : lang[0].Translation[lang[0].Translation_description.findIndex(x => x ==='en')]  },
                                        {audio : `${process.env.REACT_APP_STATIC_URL}`+ sortingShuffle(lang[1]['audio_'+dia.label])[0].replace('C:\\CalstFiles\\WordObjectContent','').replace('D:\\Workspace\\CalstFiles\\WordObjectContent',''), word : lang[1].word, translation : lang[1].Translation[lang[1].Translation_description.findIndex(x => x ==='en')] }
                                    ], 
                                    [   {audio : `${process.env.REACT_APP_STATIC_URL}`+ sortingShuffle(lang[0]['audio_'+dia.label])[0].replace('C:\\CalstFiles\\WordObjectContent','').replace('D:\\Workspace\\CalstFiles\\WordObjectContent',''), word : lang[0].word, translation : lang[0].Translation[lang[0].Translation_description.findIndex(x => x ==='en')]  },
                                    {audio : `${process.env.REACT_APP_STATIC_URL}`+ sortingShuffle(lang[1]['audio_'+dia.label])[0].replace('C:\\CalstFiles\\WordObjectContent','').replace('D:\\Workspace\\CalstFiles\\WordObjectContent',''), word : lang[1].word, translation : lang[1].Translation[lang[1].Translation_description.findIndex(x => x ==='en')] }
                                    ]
                                ]
                            tracks.push([sortingShuffle(deal_mp[0]), sortingShuffle(deal_mp[1])[0]])
                            let deal = sortingShuffle([true, false])[0]
                            var deal_nw = sortingShuffle(
                                [   {audio : `${process.env.REACT_APP_STATIC_URL}`+ sortingShuffle(lang[0]['audio_'+dia.label])[0].replace('C:\\CalstFiles\\WordObjectContent','').replace('D:\\Workspace\\CalstFiles\\WordObjectContent',''), word : lang[0].word, translation : lang[0].Translation, nonword : lang[1].word, nonword_translation: lang[1].Translation[lang[1].Translation_description.findIndex(x => x ==='en')]  , first : deal},
                                    {audio : `${process.env.REACT_APP_STATIC_URL}`+ sortingShuffle(lang[1]['audio_'+dia.label])[0].replace('C:\\CalstFiles\\WordObjectContent','').replace('D:\\Workspace\\CalstFiles\\WordObjectContent',''), word : lang[1].word, translation : lang[1].Translation, nonword : lang[0].word, nonword_translation: lang[0].Translation[lang[0].Translation_description.findIndex(x => x ==='en')], first : deal}
                                ]
                                );
                        nwproducts.push(deal_nw[0])
                        console.log(deal_mp[0], deal_nw[0], lang[0],lang[1], "dealsmp and nw")

                        };
                    };
                    let info_json = {"name": json.Exercise_name, "group": json.Group_lesson, "type": json.Type_description,  'id': id, "l2" : l2}

                    setPlayTracks(tracks);
                    setPlayTracksNW(nwproducts);
                    setPlayTracksNW_LS(sortingShuffle(nwproducts))
                    setPlayTracksNW_LW(sortingShuffle(nwproducts))
                    setInfo(info_json);
                    setNrExercises([tracks.length, nwproducts.length, nwproducts.length, nwproducts.length])
                         // tracks lengths of the exercises
                    console.log(nrExercises, "nrex")
                  })    
                }
                catch (e)
                { 
                    setStatus('FAILURE')
                }        
            }
                fetchAssets();     
                 },[id,l2, dialect]) 

    
  return (
       
       

    
    (status === "SUCCESS") ?  
    <div className={`flex flex-col flex-grow border-2 border-gray-200 rounded-md shadow-xl
        `}>
           
           {console.log(nrExercises, PlayTracks, "nrex")}     
                
                                 
                    {showResults ?                  
                        <div>
                            <Summary 
                            sum={results}
                            parts={NumOfParts} 
                            info={info}
                            backToExercises={(state) => {
                                setShowResults(state);
                                setResults({})
                                setActiveTabIndex(0)
                                
                                
                            }}
                            
                            />
                        </div>
                    :
                    <div>    
                        <Selector 
                            clickHandler={(tab_index) =>
                                    {
                                        setActiveTabIndex(tab_index);
                                    }
                            } 
                            tabsData={tabs} 
                            active = {activeTabIndex}
                            exerciseHandler={exerciseHandler} 
                            progress={progress}
                            setProgress={setProgress} 
                            nrExercises={nrExercises}

                        />
                        {/* Title */}
                     <div className='flex flex-col items-center p-8 md:p-5'>
                        <div className={`text-3xl animate__animated
                            ${
                                hideTitle ? 'hidden' : ''
                            }`}>
                            {tabs[activeTabIndex].label.startsWith('Listen & Click') ? 'Listen & Click' : tabs[activeTabIndex].label}
                        </div>                      
                    </div>
                        
                    <div className='flex flex-col grow'>
                        
                            {(tabs[activeTabIndex].label === 'Listen & Click I' && PlayTracks.length > 0 )&&     
                                    
                                <ListenAndClick tracks={PlayTracks}
                                                info={info} 
                                                resultsHandler={(state) =>
                                                    {
                                                        if (results)
                                                            { 
                                                                results['ListenAndClick'] = state
                                                                setResults(results);
                                                            }
                                                        else
                                                            {
                                                                setResults({'ListenAndClick': state});
                                                            };

                                                        
                                                        

                                                    
                                                    } 
                                                } 
                                                
                                                nextTab = {(tab) =>
                                                    {
                                                        setActiveTabIndex(tabs.findIndex((element) => element.label === tab))
                                                    }
                                                }
                                                nextExercise = {nextExercise}
                                                
                                                // for progress bar
                                                progress={progress} 
                                                setProgress={setProgress} 
                                                tab={activeTabIndex} 
                                
                                
                                />
                                    
                            }
                                
                            {(tabs[activeTabIndex].label === 'Listen & Click II' && PlayTracksNW.length > 0 ) &&
                                        
                                    
                                    <ListenAndClickII   tracks={PlayTracksNW} 
                                                        resultsHandler={(state) =>
                                                            {
                                                                if (results)
                                                                    { 
                                                                        
                                                                        results['ListenAndClickII'] = state
                                                                        setResults(results);
                                                                    }
                                                                else
                                                                    {
                                                                        setResults({'ListenAndClickII': state});
                                                                    };
                            
                                                                
                                                                
                            
                                                            
                                                            } 
                                                        } 
                                                        nextTab = {(tab) =>
                                                            {
                                                                setActiveTabIndex(tabs.findIndex((element) => element.label === tab))
                                                            }
                                                        }
                                                        nextExercise = {nextExercise}
                            
                                                        // for progress bar
                                                        progress={progress} 
                                                        setProgress={setProgress} 
                                                        tab={activeTabIndex}                            
                                                        
                                    />
                                    
                            }


                                    
                            {(tabs[activeTabIndex].label === 'Listen & Write'  && PlayTracksNW_LW.length > 0)&&    
                                    
                                    <ListenAndWrite tracks={PlayTracksNW_LW} 
                                    resultsHandler={(state) =>
                                        {
                                            if (results)
                                                { 
                                                
                                                    results['ListenAndWrite'] = state
                                                    setResults(results);
                                                }
                                            else
                                                {
                                                    setResults({'ListenAndWrite': state});
                                                };
        
                                            
                                
        
                            
                                               } 
                                        } 
                                            showResultsHandler = {(state) => 
                                                {
                                                    setShowResults(state);
                                                }
                                            
                                            
                                             }
                                             progress={progress} 
                                             setProgress={setProgress} 
                                             tab={activeTabIndex}  
                                    />
                                    
                            }
                        
                            {(tabs[activeTabIndex].label === 'Listen & Speak' && PlayTracksNW_LS.length > 0)&&    
                                    
                                    <ListenAndSpeak tracks={PlayTracksNW_LS} 
                                    
                                    resultsHandler={(state) =>
                                        {
                                            if (results)
                                                { 
                                                
                                                    results['ListenAndSpeak'] = state
                                                    setResults(results);
                                                }
                                            else
                                                {
                                                    setResults({'ListenAndSpeak': state});
                                                };
        
                                            
                                            
        
                                        
                                        } 
                                    } 
                                    nextTab = {(tab) =>
                                        {
                                            setActiveTabIndex(tabs.findIndex((element) => element.label === tab))
                                        }
                                    }
                                    nextExercise = {nextExercise}
        
                                    // for the progress bar
                                    progress={progress} 
                                    setProgress={setProgress} 
                                    tab={activeTabIndex}               
                                    />
                                    
                            }
                                
                        </div>


                        
                        <button
                    className={`border-t-0 border-gray-300 p-4 bg-inherit rounded-t-xl text-gray-400 font-extrabold md:hidden w-full tracking-wide
                    ${showResults ? 'hidden' : ''}
                    `}
                    onClick={() => 
                        activeTabIndex + 1 === tabs.length ? 
                        setShowResults(true)  
                        :setActiveTabIndex(activeTabIndex + 1)
                    }
                >
                    S K I P <p className='font-normal tracking-normal'>{tabs[activeTabIndex].label}</p>
                </button> 
                    </div>
                    }
                </div>
                :
                (status === "FAILURE") ? 
    <div className='border-2 flex flex-col'>
        <div    className='mx-auto text-xl text-red-500'>Error with data catching, check</div>
        <Link   className='border-2  rounded-md p-2 mx-auto text-red-500 text-2xl' 
                to={`${process.env.REACT_APP_API_URL}/exercise_raw/${l2}/${id}`} 
                target="_blank"
        >
            the raw data
        </Link>
    </div>
    :
    <div className='flex flex-col mx-auto text-md'>Loading...</div>

            
              
    )
}


export default ExercisePlayerNorwegian;