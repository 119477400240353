import DisplayPair from "../Display/DisplayPair";
const ListenAndClick = ({inputtracks}) => {

    return (
        <>
        <DisplayPair   tracks={inputtracks} />
        </>
    )


}

export default ListenAndClick;