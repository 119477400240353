import { Tooltip} from "@material-tailwind/react";
import { useMemo } from "react";
import { useRef } from "react";

const DisplayTrackMP= ({ currentTrack }) => {
  console.log(currentTrack, "displaytrackmp")
  const frames = [ { color: "red" },
  { transform: "scale(1.05)", color: "red" },
  { transform: "translateY(-10%)", color: "red" }, 
  { transform: "translateY(-30%)" , color: "red"}, 
  { transform: "scale(1.2)" , color: "red"},
  { transform: "translateY(-10%)" , color: "red"},
  { transform: "translateY(0%)" , color: "red"}, 
  { transform: "scale(1.2)", color: "red" },
  { transform: "scale(1.0)" , color: "inherit"},
  ]
  const inputRef_0 = useRef(null);
  const inputRef_1 = useRef(null);


 
  let audio_0 = useMemo(() => new Audio(currentTrack[0].audio))
  let audio_1 = useMemo(() => new Audio(currentTrack[1].audio))
  console.log(audio_0, audio_0, "audio")
  const Playit_0 = () => {
    audio_0.removeEventListener("ended", FireUp)
    inputRef_0.current&&inputRef_0.current.animate(  
      frames,{ duration: audio_0.duration*1000 , fill: "forwards", iterations: 1 } 
    );
    audio_0.play();
  };

  const Playit_1 = () => {
    inputRef_1.current&&inputRef_1.current.animate(  
      frames ,{ duration: audio_1.duration*1000 , fill: "forwards", iterations: 1 } 
        );
    audio_1.play();
  };

  const FireUp = () => {
    setTimeout(() => {
      inputRef_1.current&&inputRef_1.current.animate(  
          frames ,{ duration: audio_1.duration*1000 , fill: "forwards", iterations: 1 } 
            );
      audio_1.play();
    }, 300) 
  };

  const  PlayBoth = () => {
    audio_0.addEventListener("ended", FireUp)
    audio_0.currentTime = 0; 
    audio_1.currentTime = 0;
    audio_0.oncanplaythrough = function(){
      setTimeout(() => {
        audio_0.play();
        inputRef_0.current&&inputRef_0.current.animate(  
          frames,{ duration: audio_0.duration*1000 , fill: "forwards", iterations: 1 } 
        );
      }, audio_0.duration) 
      }
    };

  
  return (
    <div className="w-full flex flex-cols-3">
    
      <Tooltip className="flex flex-col bg-indigo-300 font-medium text-white rounded-full px-3"
            animate={{
              mount: { scale: 1.2, y: 0 },
              unmount: { scale: 0, y: 25 },}} 
            content={currentTrack[0].translation}
      >
          <button className="basis-2/5  bg-transparent hover:bg-indigo-100 hover:border rounded-full hover:shadow-xl font-serif:Times text-sm font-semibold text-gray-600 hover:text-blue-600" onClick={Playit_0}>
           {currentTrack[0].word.length < 10  ?
            <p className="text-center" ref={inputRef_0} >{currentTrack[0].word}</p>
            :
            <p className="text-center break-all" ref={inputRef_0} >{currentTrack[0].word}</p>
           }
          </button>
      </Tooltip>

<button className="basis-1/5 bg-transparent text-gray-600 hover:text-red-600 font-bold py-3 text-sm" onClick={PlayBoth}>
       <p className="text-center"> &#8883;</p>
      </button>
        
        
      <Tooltip className="flex flex-col bg-indigo-300 font-medium text-left text-white rounded-full px-3"
            animate={{
              mount: { scale: 1.1, y: 0 },
              unmount: { scale: 0, y: 25 },}} 
            content={currentTrack[1].translation}
      > 
          <button className="basis-2/5 bg-transparent text-sm  font-serif:Times hover:shadow-xl  hover:bg-indigo-100 hover:border rounded-full font-semibold text-gray-600 hover:text-blue-600" onClick={Playit_1}>
          {currentTrack[1].word.length < 10  ?
            <p className="text-center" ref={inputRef_1}>{currentTrack[1].word}</p>
            :
            <p className="text-center break-all" ref={inputRef_1}>{currentTrack[1].word}</p>
          }
          </button>
      </Tooltip>
     
      
    </div>
    
  );
};
export default DisplayTrackMP;