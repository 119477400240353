import React from 'react'
import { useState, useEffect } from 'react';
import CardPlayerNorwegian from '../../components/Card/Exercises/Player/CardPlayerNorwegian';
import Selector from '../../components/Card/SoundGroups/Selector';
import ExercisePlayerNorwegian from '../../components/Card/Exercises/Exercise/ExercisePlayerNorwegian';
import {useParams} from "react-router-dom"
import useAuth from '../../../Users/hooks/useAuth';

const TabPlayerNorwegian = () => {
  let {l2, id} = useParams()

  const { setAuth } = useAuth();

  const checkToken = async () => {
    const token = localStorage.getItem("token");
    if (token) {
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/users/me`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
        if (response.ok) {
            let userData = await response.json();
            console.log(userData);
            userData["token"] = token;
            setAuth(userData);
        }
    }
}

useEffect(() => {
    checkToken();
}, []);
  
  const tabsData = [  
    {label: 'Training'},
    {label: 'Test yourself'},
    
  ];
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabs, setTabs] = useState(tabsData)
    
  const [dia, setDia] = useState({label : 'Oslo'})

  function handleState(newValue) {
      setDia(newValue);
      console.log(newValue, "neval")
   }

    return (
              <div className="w-auto md:w-1/2 mx-auto border-2  rounded-md"> 


                    {(tabs[activeTabIndex].label === 'Training')  &&
                      <CardPlayerNorwegian change={handleState} id={id} l2={l2}  />
                    }
                    {(tabs[activeTabIndex].label === 'Test yourself')  &&
                      <ExercisePlayerNorwegian dialect={dia} id={id} l2={l2} />
                    }
                    
                    <Selector 
                    clickHandler={(tab_index) =>
                            {
                                setActiveTabIndex(tab_index)
                            }
                    } 
                    tabsData={tabs} 
                />      
                </div>
              
                                
    
    ) 
}
export default TabPlayerNorwegian;
