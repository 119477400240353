import { useState, useEffect } from "react";

const Selector =  ({tabsData, clickHandler, active }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(active);
  
  useEffect(() => {
    setActiveTabIndex(active)
}, [active]);



  

  return (
   
    <div className="tabs grow">
    
    {tabsData.map((tab, idx) => {
      
      return (
        
          <a 
            key={idx}
            className={`tab grow text-center 
                        text-sm xl:text-xl tab-xs xl:tab-md  tab-lifted hover:bg-gray-100 transition-colors duration-300 
                        ${
                          idx === activeTabIndex
                          ? " tab-active text-blue-700 font-semibold"
                          : "hover:border-gray-200 text-gray-400 font-semibold antialiased bg-gray-100"
                        }
                      `}
            onClick={() => {
                              clickHandler(idx)
                              setActiveTabIndex(idx)
                            }
          }>
            <div className="flex flex-col">
              <div>{tab}</div>

           </div>
          </a>
          
      );
      })}
      
</div>

  );
}
export default Selector;