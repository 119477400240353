import { Tooltip} from "@material-tailwind/react";
import { useMemo } from "react";
import sortingShuffle from "../../../shuffle";

const CardSoundGroupButtonMPAudioNorwegian = ({currentTrack, include, color, title}) => {
    
    

   
      
    let AudioSample = [];
    let Sample = [];
    console.log(currentTrack, "track")
    AudioSample = currentTrack.MP_wordpair;
    console.log(AudioSample,  "audiosam")
    const audio_tag ='audio_'+AudioSample[0].dialect 
    console.log(audio_tag,"tag")
   
    
  
    
  
    let choice = ['','']
    choice = [`${process.env.REACT_APP_STATIC_URL}`+ sortingShuffle(AudioSample[0][audio_tag])[0], `${process.env.REACT_APP_STATIC_URL}`+ sortingShuffle(AudioSample[1][audio_tag])[0]]
  
      
    let audio_0 = useMemo(() => new Audio(choice[0].replace('C:\\CalstFiles\\WordObjectContent','').replace('D:\\Workspace\\CalstFiles\\WordObjectContent','')),[choice])
    let audio_1 = useMemo(() => new Audio(choice[1].replace('C:\\CalstFiles\\WordObjectContent','').replace('D:\\Workspace\\CalstFiles\\WordObjectContent','')),[choice])
    console.log(audio_0, audio_1, currentTrack.MP_wordpair[0].word, currentTrack.MP_wordpair[1].word)

    const  PlayBoth = async () => {
        
        
        setTimeout(() => {
            audio_0.play();
            }, 2) 
        
        audio_0.onended=function()
        {
            setTimeout(() => {
            audio_1.play();
            }, 200)   
        }
    };
    const  StopBoth = async () => {
        
        
            audio_0.pause();
            audio_0.currentTime=0;   
        
            
            audio_1.pause();
            audio_1.currentTime=0;   
        
    };

return (
        

            <Tooltip placement="bottom" className="flex flex-col bg-blue-700 font-medium text-white rounded-full px-3"
                animate={{
                mount: { scale: 1.4, y: 0 },
                unmount: { scale: 0, y: 25 },}} 
                content={String(currentTrack.MP_wordpair[0].word)+"-"+String(currentTrack.MP_wordpair[1].word)} >
               
                <button className="shadow-xl text-blue-700 font-medium text-2xl hover:text-3xl  py-2 px-4 border w-16 h-16 border-blue-500 hover:border-blue-600 rounded-full hover:bg-green-500" 
                style={ include ? {backgroundColor : color }:{backgroundColor : "lightgray"} }
                onMouseEnter={PlayBoth} onMouseLeave ={StopBoth}>
                { title } 
                </button>
            </Tooltip> 
    )
}
export default CardSoundGroupButtonMPAudioNorwegian;