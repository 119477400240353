import React from 'react'
import { Tooltip} from "@material-tailwind/react";
import { useState } from 'react';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import CardPlayerNorwegian from '../Player/CardPlayerNorwegian';
import { useRef } from 'react';
import sortingShuffle from '../../../shuffle';
import CardSoundGroupButtonMP from '../Button/CardSoundGroupButtonMP';
import CardSoundGroupButtonMPAudioNorwegianPosition from '../Button/CardSoundGroupButtonMPAudioNorwegianPosition';
import CardSoundGroupButtonMPAudioEnglishPosition from '../Button/CardSoundGroupButtonMPAudioEnglishPosition';
import Selector from '../Selector';
import ExercisePlayerNorwegian from '../Exercise/ExercisePlayerNorwegian';
const CardSoundPositionsNorwegian = ({exercise, l2, include}) => {
  console.log(exercise, "inside inside")
  const  PlayBoth = async () => {
    handleFocus(audio_0.duration+audio_1.duration)
    
    setTimeout(() => {
        audio_0.play();
        }, 2) 
    
    audio_0.onended=function()
    {
        setTimeout(() => {
        audio_1.play();
        }, 200)   
    }
  };
  const  StopBoth = async () => {
      
      
          audio_0.pause();
          audio_0.currentTime=0;   
      
          
          audio_1.pause();
          audio_1.currentTime=0;   
      
  };

  function handleFocus(timer) {
    let pulse_duration = 1700;
    if (timer){
      pulse_duration = timer*1000+300;
    }
    
    inputRef.current && inputRef.current.animate(  
      [
        { transform: "scale(1.05)" },
        { transform: "translateY(-10%)" }, 
        { transform: "translateY(-30%)" }, 
        { transform: "scale(1.2)" },
        { transform: "translateY(-10%)" },
        { transform: "translateY(0%)" }, 
        { transform: "scale(1.2)" },
        { transform: "scale(1.0)" },
        ],
        { duration: pulse_duration, fill: "forwards", iterations: 1 } 
    
    
    )

  }

    
    

  /* define random audio pick and animation */
  const inputRef = useRef(null);
  var products = [];

  for (const lang of exercise) {
    if (lang.MP_wordpair) {
      products.push(lang)
    }
  }
 
  let AudioSample = [];
  let Sample = [];
  Sample = sortingShuffle(products)[0]
  AudioSample = Sample.MP_wordpair;
  
  const audio_tag ='audio_'+AudioSample[0].dialect 
 
  

  

  let choice = ['','']
  choice = [`${process.env.REACT_APP_STATIC_URL}`+ sortingShuffle(AudioSample[0][audio_tag])[0], `${process.env.REACT_APP_STATIC_URL}`+ sortingShuffle(AudioSample[1][audio_tag])[0]]

    
  let audio_0 = useMemo(() => new Audio(choice[0].replace('C:\\CalstFiles\\WordObjectContent','').replace('D:\\Workspace\\CalstFiles\\WordObjectContent','')),[choice])
  let audio_1 = useMemo(() => new Audio(choice[1].replace('C:\\CalstFiles\\WordObjectContent','').replace('D:\\Workspace\\CalstFiles\\WordObjectContent','')),[choice])
  
  /* design of circles */

  const dummy = [...Array(9)]
  
  const positions = [3,4,5]

  const colorcodes = ["deepskyblue", "cornflowerblue", "aquamarine", "aliceblue", "azure", "darkturquoise","cadetblue", "dodgerblue" , "aqua","blueviolet", "fuchsia", "lavender", "lightsteelblue", "mediumpurple", "yellowgreen"]
  
   
  if ( exercise.length === 2 )
  {
    for (const item of exercise ) 
    {
      if (item.Position === 'final')
      {
        dummy[5] = item
      }
      else if (item.Position === 'initial')
      {
        dummy[3] = item
      }
      else
      {
        dummy[4] = item
      }
      
    }
  }

  if ( exercise.length === 3 )
  {
    for (const item of exercise ) 
    {
      if (item.Position === 'final')
      {
        dummy[5] = item
      }
      
      else if (item.Position === 'initial')
      {
        dummy[3] = item
      }
      else
      {

        dummy[4] = item
      }
     
    }
  }
 
 
  
  
  const [show, setShow] = useState(true);
  const [currentEx, setCurrentEx] = useState({});
  const tabsData = [  
    {label: 'Training'},
    {label: 'Test yourself'},
    
  ];
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabs, setTabs] = useState(tabsData)
    
  const [dia, setDia] = useState({label : 'Oslo'})

  function handleState(newValue) {
      setDia(newValue);
      console.log(newValue, "neval")
   }




    return (

          show 
            
          ?
                    

         
                  
                  <div  className=" py-12 scale-75 2xl:scale-100 grid grid-cols-3 gap-2 bg-farmWhite">
                        
                        {dummy.map(
                          (el, index)=>{ 
                             
                              return (
                                
                                el ? 
                                        
                                      
                                        <div className='basis-1/3' key={String(index) + String(el.Exercise_name)}  onClick={() => {setShow(false); setCurrentEx(el)} } >
                                           <CardSoundGroupButtonMPAudioNorwegianPosition currentTrack={el} include={include} title={el.Exercise_name} color={String(colorcodes[positions.indexOf(index)])} />
                                        </div>

                                        
                                    : 
                                        <div  key={String(index) + 'dummy'}  className='basis-1/3' >
                                        <button className="bg-transparent border  border-transparent hover:border-transparent rounded-full">
                                             
                                        </button>
                                        </div>
                                        
                                        )
                                       }
                          
                        )}
                      </div>    
           
          :
       
            <div  onMouseEnter={StopBoth} 
            className="flex flex-col flex-grow pt-4 bg-FarmWhite rounded-md 
            border-gray-200 transition ease-in-out"
            > 
              <div className='flex flex-row'>
                  <button className="bg-white hover:bg-gray-100 text-gray-800 font-semibold  
                                      text-sm py-2 px-4 border border-gray-400 rounded shadow"
                          onClick={() => {setShow(true);}}
              > 
                Back to positions
              </button>
              <div className='grow border-b-2'>

              </div>
                            
              <Link   to={`/norwegianplayer/${currentEx.id}`} 
                      target="_blank"
              >
                <button className="right-0 bg-white hover:bg-gray-100 text-gray-800 font-semibold text-sm 
                                      py-2 px-4 border border-gray-400 rounded shadow"
                >
                  A new tab
                  </button>
              </Link>
              </div>
              
            
            

                      {(tabs[activeTabIndex].label === 'Training')  &&
                      <CardPlayerNorwegian change={handleState} id={currentEx.id} l2={l2}  />
                    }
                    {(tabs[activeTabIndex].label === 'Test yourself')  &&
                      <ExercisePlayerNorwegian dialect={dia} id={currentEx.id} l2={l2} />
                    }
                    
                    <Selector 
                    clickHandler={(tab_index) =>
                            {
                                setActiveTabIndex(tab_index)
                            }
                    } 
                    tabsData={tabs} 
                />      
                    </div>
                    
                                      
    
    ) 
}
export default CardSoundPositionsNorwegian
