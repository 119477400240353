import DisplayPairNonWord from "../Display/DisplayPairNonWord";
const ListenAndClickII = ({inputtracks}) => {

    return (
        <>
        <DisplayPairNonWord   tracks={inputtracks} />
        </>
    )


}

export default ListenAndClickII;