import { Tooltip} from "@material-tailwind/react";
import { useMemo, useRef, useState, useEffect } from "react";
import PaginatorExercise from "../PaginatorExercise";

const DisplayPair= ({ tracks }) => {
  const [playNext, setPlayNext] = useState(true)
  const [track, setTrack] = useState(1)
  const [buttonState, setButtonState] = useState(true)
  const [playState, setPlayState] = useState(true)
  const [playnow, setPlayNow] = useState(false)
  const [registerHit, setRegisterHit] = useState(false)
  
  const no_frames = [ { color: "red" },
  { transform: "scale(1.05)", color: "red" },
  { transform: "translateX(-5%)", color: "red" }, 
  { transform: "translateX(5%)", color: "red" }, 
  { transform: "scale(1.05)", color: "red" },
  { transform: "scale(1.0)" , color: "inherit"},
  ]

  const yes_frames = [ { color: "green" },
  { transform: "scale(1.05)", color: "green" },
  { transform: "rotate(0.02turn)" },
  { transform: "rotate(-0.02turn)" },
  { transform: "scale(1.05)", color: "green" },
  { transform: "scale(1.0)" , color: "inherit"},
  ]


  const inputRef_0 = useRef(null);
  const inputRef_1 = useRef(null);

  let choice = tracks[track-1][0]
  let target = tracks[track-1][1]

  let first = true
  let second = false
  if (choice[0].word === target.word)
  {
      first = true;
      second = false
  }
  else
  {
      first = false;
      second = true;
  }

  let audio_0 = useMemo(() => new Audio(choice[0].audio),[choice]);
  let audio_1 = useMemo(() => new Audio(choice[1].audio),[choice]);
  let audio_2 = useMemo(() => new Audio(target.audio),[target]);


  const Playit_0 = (str) => {
    setRegisterHit(true)
    setPlayNext(false)
    
    
    var audio_duration = audio_0.duration ? audio_0.duration : 1
    
    if (str.first === true)
    {
    inputRef_0.current&&inputRef_0.current.animate(  
      yes_frames,{ duration: audio_duration*1000 , fill: "forwards", iterations: 1 } 
    );
    }
    else
    {
      inputRef_0.current&&inputRef_0.current.animate(  
        no_frames,{ duration: audio_duration*1000 , fill: "forwards", iterations: 1 } 
      );
    }
    audio_0.play();
  };

  const Playit_1 = (str)  => {
    setRegisterHit(true)
    setPlayNext(false)
    var audio_duration = audio_1.duration ? audio_1.duration : 1
    if (str.second === true)
    {
    inputRef_1.current&&inputRef_1.current.animate(  
      yes_frames,{ duration: audio_duration*1000 , fill: "forwards", iterations: 1 } 
    );
    }
    else
    {
      inputRef_1.current&&inputRef_1.current.animate(  
        no_frames,{ duration: audio_duration*1000 , fill: "forwards", iterations: 1 } 
      );
    }
    audio_1.play();
  };

  

  useEffect(()=>{

    
    let mounted = true

    const frames = [ { color: "blue" },
    { transform: "scale(1.05)", color: "blue" },
    { transform: "translateY(-10%)", color: "blue" }, 
    { transform: "translateY(0%)" , color: "blue"}, 
    { transform: "scale(1.05)", color: "blue" },
    { transform: "scale(1.0)" , color: "inherit"},
    ]

    const FireUp = () => {
      audio_0.removeEventListener("ended", FireUp)
      var audio_duration = audio_1.duration ? audio_1.duration : 1
      
      setTimeout(() => {
        
            inputRef_1.current&&inputRef_1.current.animate(  
                  frames ,{ duration: audio_duration*1000 , fill: "forwards", iterations: 1 } 
                    );
                
        audio_1.play();
          
      }, 300) 
    
    }
  
    
    const FireUpTarget = () => {
      audio_1.removeEventListener("ended", FireUpTarget)
      var audio_duration = audio_2.duration ? audio_2.duration : 1
      
      setTimeout(() => {
        
        
        inputRef_0.current&&inputRef_0.current.animate(  
          frames ,{ duration: audio_duration*1000 , fill: "forwards", iterations: 1 } 
            );
        inputRef_1.current&&inputRef_1.current.animate(  
          frames ,{ duration: audio_duration*1000 , fill: "forwards", iterations: 1 } 
            );
        audio_2.play();
        
      }, 300) 
    
    }



    const handleCanPlayThrough = () => {
      var audio_duration = audio_0.duration ? audio_0.duration : 1
      audio_0.removeEventListener('canplaythrough', handleCanPlayThrough);
      if (mounted)
      {
      setTimeout(() => {
        audio_0.play();
        inputRef_0.current&&inputRef_0.current.animate(  
          frames,{ duration: audio_duration*1000 , fill: "forwards", iterations: 1 } 
        );
      }, audio_duration ) 
      }
      
    };

    if (track > 1 || playnow === true ) {
      
      setTimeout(()=> {
        
        audio_0.addEventListener('canplaythrough', handleCanPlayThrough);
        audio_0.addEventListener("ended", FireUp)
        audio_1.addEventListener("ended", FireUpTarget)
        audio_2.addEventListener("ended", ()=>{setButtonState(false);})
        
        audio_0.currentTime = 0; 
        audio_1.currentTime = 0;
        audio_2.currentTime = 0; 
        
        
      }  , 300);
    }
    else
    {
      setPlayState(false)
   

    }
    return () => {
      mounted = false
      audio_1.removeEventListener("ended", FireUpTarget)
      audio_2.removeEventListener("ended", ()=>{setButtonState(false);})
      audio_0.pause();
      audio_1.pause();
      audio_2.pause();

    }
  },[track, audio_0, audio_1, audio_2, playnow, playState])


 

  
  return (
    <div className="flex flex-col py-5 ">
   
      <div className="flex py-1 mx-auto">
      <button disabled={playState ? true : false} 
        className={`bg-blue-100 border-2 border-blue-300 rounded-xl text-gray-600 
                    hover:text-red-600 font-bold py-1 px-2 text-xl 
                    ${
                        playState
                        ? "opacity-0"
                        : "opacity-100"
                    }
                  `}
        onClick={()=>{setPlayNow(true); setPlayState(true)}}
      >
        Start 
      </button>
      </div>
      
    
      <div className={`w-full flex py-5 flex-cols-2 px-5 gap-x-5 
                        ${
                            playState
                            ? "opacity-100"
                            : "opacity-50"
                        }
                      `}
      >
      
        <Tooltip className="flex flex-col bg-indigo-300 break-word font-medium 
                            text-sm text-center text-white rounded-full px-1"
              animate={{
                mount: { scale: 1.0, y: 0 },
                unmount: { scale: 0, y: 25 },}} 
              content={choice[0].translation}
        >
          <button disabled={buttonState} 
          className={`grow 
                    ${ registerHit ?
                    "bg-transparent hover:bg-indigo-100 hover:border rounded-full hover:shadow-xl font-serif:Times text-3xl font-semibold text-gray-600 hover:text-blue-600"
                  : "py-1 px-2 bg-blue-100 border-2 border-blue-300 rounded-xl text-gray-600  font-serif:Times text-3xl font-semibold text-gray-600 hover:text-blue-600"}`}
          
          
          
          onClick={()=>Playit_0({first})}>
          
            <p 
              className={`text-center ${
                choice[0].word.length < 13
                ? ""
                : "break-all"
                }`}
              ref={inputRef_0} >
                {choice[0].word}
            </p>
          
          </button>
        </Tooltip>

       
          
          
        <Tooltip className="flex flex-col bg-indigo-300 break-word font-medium 
                            text-sm text-center text-white rounded-full px-1"
              animate={{
                mount: { scale: 1.0, y: 0 },
                unmount: { scale: 0, y: 25 },}} 
              content={choice[1].translation}
        > 
            <button disabled={buttonState} 
            className={`grow 
            ${ registerHit ?
            "bg-transparent hover:bg-indigo-100 hover:border rounded-full hover:shadow-xl font-serif:Times text-3xl font-semibold text-gray-600 hover:text-blue-600"
          : "py-1 px-2 bg-blue-100 border-2 border-blue-300 rounded-xl text-gray-600  font-serif:Times text-3xl font-semibold text-gray-600 hover:text-blue-600"}`}
  
            onClick={()=>Playit_1({second})} >
            
              <p className={`text-center ${
                choice[1].word.length < 13
                ? ""
                : "break-all"
                }`}
               ref={inputRef_1}>{choice[1].word}</p>
            
            </button>
        </Tooltip>
        </div>
        <div className='scale-75'>
          <PaginatorExercise maxpage={Math.ceil(tracks.length)} page={track} offState={playNext}
                  clickHandler={(page) => {
                      setTrack(page);
                      setButtonState(true);
                      setPlayState(true);
                      setPlayNext(true)
                      setRegisterHit(false)
                  }}
          />
        </div>
      
        
      </div>
    
  );
};
export default DisplayPair;