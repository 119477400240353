import { Tooltip} from "@material-tailwind/react";
import { useMemo, useRef } from "react";

const DisplayTrackMPNorwegian= ({ currentTrack }) => {
  const frames = [ { color: "red" },
  { transform: "scale(1.05)", color: "red" },
  { transform: "translateY(-10%)", color: "red" }, 
  { transform: "translateY(-30%)" , color: "red"}, 
  { transform: "scale(1.2)" , color: "red"},
  { transform: "translateY(-10%)" , color: "red"},
  { transform: "translateY(0%)" , color: "red"}, 
  { transform: "scale(1.2)", color: "red" },
  { transform: "scale(1.0)" , color: "inherit"},
  ]
  const inputRef_0 = useRef(null);
  const inputRef_1 = useRef(null);
  
  
  let choice = ['','']
  choice = [`${process.env.REACT_APP_STATIC_URL}`+currentTrack.pair_0.track_0.sort(() => 0.5 - Math.random()).slice(0,1)[0], `${process.env.REACT_APP_STATIC_URL}`+currentTrack.pair_1.track_0.sort(() => 0.5 - Math.random()).slice(0,1)[0]]

    
  let audio_0 = useMemo(() => new Audio(choice[0].replace('C:\\CalstFiles\\WordObjectContent','').replace('D:\\Workspace\\CalstFiles\\WordObjectContent','')),[choice])
  let audio_1 = useMemo(() => new Audio(choice[1].replace('C:\\CalstFiles\\WordObjectContent','').replace('D:\\Workspace\\CalstFiles\\WordObjectContent','')),[choice])
  const Playit_0 = () => {
    audio_0.removeEventListener("ended", FireUp)
    inputRef_0.current&&inputRef_0.current.animate(  
      frames,{ duration: audio_0.duration*1000 , fill: "forwards", iterations: 1 } 
    );
    audio_0.play();
  };

  const Playit_1 = () => {
    inputRef_1.current&&inputRef_1.current.animate(  
      frames ,{ duration: audio_1.duration*1000 , fill: "forwards", iterations: 1 } 
        );
    audio_1.play();
  };

  const FireUp = () => {
    setTimeout(() => {
      inputRef_1.current&&inputRef_1.current.animate(  
        frames ,{ duration: audio_1.duration*1000 , fill: "forwards", iterations: 1 } 
          );
      audio_1.play();
    }, 300) 
  };

  const  PlayBoth = () => {
    audio_0.addEventListener("ended", FireUp)
    audio_0.currentTime = 0; 
    audio_1.currentTime = 0;
    audio_0.oncanplaythrough = function(){
      setTimeout(() => {
        console.log(inputRef_0.current, inputRef_1.current, "warning")
        inputRef_0.current&&inputRef_0.current.animate(  
          frames,{ duration: audio_0.duration*1000 , fill: "forwards", iterations: 1 } 
        );
        audio_0.play();
      }, audio_0.duration) 
      }
    };

  
  return (
    <div className="w-full flex flex-cols-3">
    
      <Tooltip className="flex flex-col bg-indigo-300 font-medium text-white rounded-full px-3"
            animate={{
              mount: { scale: 1.2, y: 0 },
              unmount: { scale: 0, y: 25 },}} 
            content={currentTrack.pair_0.translation}
      >
          <button className="basis-2/5  bg-transparent hover:bg-indigo-100 hover:border rounded-full hover:shadow-xl text-sm font-serif:Times font-semibold text-gray-600 hover:text-blue-600" onClick={Playit_0}>
            <p ref={inputRef_0} >{currentTrack.pair_0.word}</p>
          </button>
      </Tooltip>

<button className="basis-1/5  bg-transparent text-gray-600 hover:text-red-600 font-bold py-3 text-sm" onClick={PlayBoth}>
        &#8883;
      </button>
        
        
      <Tooltip className="flex flex-col bg-indigo-300 font-medium text-left text-white rounded-full px-3"
            animate={{
              mount: { scale: 1.1, y: 0 },
              unmount: { scale: 0, y: 25 },}} 
            content={currentTrack.pair_1.translation}
      > 
          <button className="basis-2/5 bg-transparent font-serif:Times text-sm hover:shadow-xl  hover:bg-indigo-100 hover:border rounded-full font-semibold text-gray-600 hover:text-blue-600" onClick={Playit_1}>
            <p ref={inputRef_1}>{currentTrack.pair_1.word}</p>
          </button>
      </Tooltip>
     
      
    </div>
    
  );
};
export default DisplayTrackMPNorwegian;