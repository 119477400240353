const TwoStressOne = () => {
    
   
   

return (
        

           
    <div className='w-32 h-16 flex flex-row m-auto rounded-xl'>
        <div className='m-auto w-10 h-10 rounded-full bg-white'></div>
        <div className='m-auto w-6 h-6 rounded-full bg-white'></div>
    </div>
           
    )
}
export default TwoStressOne;