const PaginatorExercise =  ({page, clickHandler, maxpage, offState }) => {
   
  return (
    <div className="flex flex-col max-auto items-center scale-90">
    
    
    <div className="flex flex-row text-xs mx-2 text-semibold justify-center items-center">

        Pair
        <span className=" font-bold text-xs ml-2  text-gray-500">
            {page} of {maxpage}
        </span>
    </div>
    
    
    {page < maxpage ? (
    offState ?
    <button disabled={true}
        className="m-auto px-3 py-2 rounded-xl  font-serif:Times text-xl font-semibold text-gray-500 border-2 border-gray-300"
       
    >
           Next
    </button> :
    <button disabled={false}
        className="m-auto px-3 py-2 rounded-xl  font-serif:Times text-xl font-semibold bg-blue-100 border-2 border-blue-300 text-black-600 hover:text-blue-600 hover:bg-indigo-100"
        onClick={() => clickHandler(page + 1)}
    >
        
       Next
    </button>
    ) : (
        offState ?
        <button disabled={true}
        className="m-auto px-3 py-2 rounded-xl  font-serif:Times text-xl font-semibold text-gray-500 border-2 border-gray-300"
        
    >
    Next
        
    </button>
    :
    <button disabled={false}
        className="m-auto px-3 py-2 rounded-xl  font-serif:Times text-xl font-semibold bg-blue-100 border-2 border-blue-300 text-black-600 hover:text-blue-600 hover:bg-indigo-100"
        onClick={() => clickHandler(1)}
    >
    
        Next
    </button>
    )}

</div>
   
)}
export default PaginatorExercise;