import React from 'react'


const CardSoundGroup = ({exercise, include, exHandler}) => {
  const test = exercise.dictionary
  const names = Object.keys(test);
  let dum =  []
  names.forEach((key, index) => {
    dum.push(test[key])
  });
 
  
    return (
          <div className='flex flex-grow flex-col gap-y-5 pb-5'>
                <div className='mx-5 rounded-xl py-3 bg-gray-50 text-2xl font-medium text-center border-2'> 
                  { include ? "Target sound: " : "Familiar target sound: "} 
                  <span className='text-3xl text-center font-semibold text-blue-600'>
                    {exercise.sound}
                  </span>
                </div>           
            <div  
                  className="flex flex-col   
                              mx-auto py-10 
                              bg-farmWhite rounded-md 
                               duration-300 font-mono gap-y-7"
            >
            
            {dum.map(
              (el, index)=>{ 
              
                  return (
                              el[0].MP_wordpair ?
                                <div className='flex flex-row  flex-grow  justify-center'>
                                  <div  className="tooltip tooltip-right tooltip-accent" 
                                        data-tip={`${el[0].MP_wordpair[0].word} - ${el[0].MP_wordpair[1].word} `} 
                                  >
                                    <div  
                                          key={String(index) + String(el[0].Exercise_name)}  
                                          onClick={() => {exHandler(el[0]);} } 
                                    >
                                      <button  className="shadow-xl text-blue-700 font-medium text-2xl hover:text-3xl  py-2 px-4 border w-20 h-20 border-blue-500 hover:border-blue-600 rounded-full hover:bg-green-500" 
                                                style={ include ? {backgroundColor : "lightskyblue" }:{backgroundColor : "lightgreen"} }
                                      >
                                        {el[0].Second_MinimalPair_IPA ? el[0].Second_MinimalPair_IPA : el[0].Exercise_name}   
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              :
                              <div className='flex flex-row flex-grow justify-center'>
                                <div  className="tooltip tooltip-right tooltip-accent" 
                                      data-tip={`${el[0].MP_nonword.word} - *${el[0].MP_nonword.word} `} 
                                >
                                  <div  key={String(index) + String(el[0].Exercise_name)} 
                                        onClick={() => {exHandler(el[0]); }} 
                                  >
                                    <button  className="shadow-xl text-blue-700 font-medium text-2xl hover:text-3xl  
                                                        py-2 px-4 border w-20 h-20 border-blue-500 hover:border-blue-600 
                                                        rounded-full hover:bg-green-500" 
                                             style={ include ? {backgroundColor : "lightskyblue" }:{backgroundColor : "lightgreen"} }         
                                    >
                                      {el[0].Second_MinimalPair_IPA ? el[0].Second_MinimalPair_IPA : el[0].Exercise_name} 
                                    </button>
                                  </div>
                                </div>
                              </div>

                            )
                            }
              
            )}
          </div>    
          </div>
         
    ) 
}
export default CardSoundGroup;
