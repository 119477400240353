import { useMemo, useRef, useState,  useEffect } from "react";
import { MdMicNone, MdMic } from "react-icons/md";
import Results from "../Display/Results";
import { set } from "react-hook-form";
// import { set } from "react-hook-form";
const mimeType = "audio/webm";


const DisplayRecorder = ({ tracks, stream, resHandler, nextTab, nextExercise, progress, setProgress, tab }) => {

  const [results, setResults] = useState({'results': {}, 'value': []})
  const [track, setTrack] = useState(1)

  const [buttonState, setButtonState] = useState(true) // False when audio has stopped playing. Compare-button is enabled when false. Tracks the play button.

  const mediaRecorder = useRef(null);
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [audioChunks, setAudioChunks] = useState([]);
  const [audio, setAudio] = useState(null);

  // For the progress bar and result page
  const [scores, setScores] = useState(progress[tab] ? progress[tab] : []) 
  const [resultPage, setResultPage] = useState(false)

  // For the star rating
  const [canCompare, setCanCompare] = useState(false)
  const [canRate, setCanRate] = useState(false)
  const [rating, setRating] = useState(null)
  const [hover, setHover] = useState(null)
  const totalStars = 3



  // console.log(
  //   track, 'track',
  //   results, 'results',
  //   scores, 'scores',
  //   rating, 'rating',
  //   canRate, 'canRate',
  //   buttonState, 'buttonState'
  // )

  const [barWidth, setBarWidth] = useState('w-0')
  const [transitionDuration, setTransitionDuration] = useState(null)
  const [animation, setAnimation] = useState('') // Animation for the target word
  
  useEffect(() => {
    setAnimation('animate__zoomIn')
    const timer = setTimeout(() => {
      setAnimation('')
    }, 3000)

    return () => clearTimeout(timer)
  } , [track])


  useEffect(() => {

    if (buttonState) {
      setBarWidth('w-0 ')
      setTransitionDuration('100ms')
    }
    
  }, [buttonState])


  // Tracks the scorers for the exercise
  useEffect(() => {
    setProgress(prevProgress => {
      const newProgress = [...prevProgress]
      newProgress[tab] = scores
      return newProgress
    })
  },[scores])

 

  let choice = tracks[track-1]
  let audio_0 = useMemo(() => new Audio(choice.audio),[choice]);
  let audio_record = new Audio(audio)
 
  const PlayNext = () => {

    
    setTimeout(() => {
    if (track < tracks.length)
      {
      setTrack(track+1);
      setButtonState(true);
      setCanRate(false);
      setRating(null);
      setHover(null);
      setCanCompare(false);
      
      }
      else
      {
        setResultPage(true)
        resHandler(results)
      }
    }, 300)

  }

  useEffect(() => {
    if (!results.results[track]){

      if (rating === 1) {
        results.results[track] = {'eval': 'One', 'word': choice.word, "choice" : choice }
        results.value.push(false)
        setScores([...scores, 0]);
        setResults(results)
      } 

      else if (rating === 2) {
        results.results[track] = {'eval': 'Two', 'word': choice.word , "choice" : choice}
        results.value.push(true)
        setScores([...scores, 1]);
        setResults(results)
      }

      else if (rating === 3) {
        results.results[track] = {'eval': 'Three', 'word': choice.word , "choice" : choice}
        results.value.push(true)
        setScores([...scores, 1]);
        setResults(results)
      }
      
    };

    
  }, [rating])

  
  
  useEffect(() => {
    var audio_duration = audio_0.duration ? audio_0.duration : 1
    if (recordingStatus === 'recording') {

      setBarWidth('w-full')
      setTransitionDuration(`${3*audio_duration}s`)

      const timer = setTimeout(() => {
        stopRecording()
      }, 3*audio_duration*1000)
   
      return () => clearTimeout(timer);
    }
  }, [recordingStatus, audio_0])

  /*useEffect(()=>{console.log(audio, audio_record, buttonState, "recording")},[audio])*/
  
  const startRecording = async () => {
    
    setRecordingStatus("recording");
    //create new Media recorder instance using the stream
    const media = new MediaRecorder(stream, { type: mimeType });
    //set the MediaRecorder instance to the mediaRecorder ref
    mediaRecorder.current = media;
    //invokes the start method to start the recording process
    
    mediaRecorder.current.start();
    let localAudioChunks = [];
    
    mediaRecorder.current.ondataavailable = (event) => {
       if (typeof event.data === "undefined") return;
       if (event.data.size === 0) return;
       localAudioChunks.push(event.data);
    };
    setAudioChunks(localAudioChunks);
  };


  const stopRecording = () => {
    setRecordingStatus("inactive");
    setCanCompare(true)
    setButtonState(false)
    //stops the recording instance
    mediaRecorder.current.stop();
    mediaRecorder.current.onstop = () => {
      //creates a blob file from the audiochunks data
       const audioBlob = new Blob(audioChunks, { type: mimeType });
      //creates a playable URL from the blob file.
       const audioUrl = URL.createObjectURL(audioBlob);
       setAudio(audioUrl);
       console.log(audioUrl, audioChunks, audioBlob)
       setAudioChunks([]);
    };
  };

  

  const FireUpRecording = () => {
    audio_0.removeEventListener("ended", FireUpRecording)
    setTimeout(startRecording,0) 
  }


  const Playit_0 = () => {
    
    // setButtonState(true)
   
    var audio_duration = audio_0.duration ? audio_0.duration : 1
    audio_0.addEventListener("ended", FireUpRecording)
  
    audio_0.play();
  };
  
  const Play_record = () => {
  
    
    setTimeout(()=> {
        
      audio_0.addEventListener('canplaythrough', handleCanPlayThrough);
      audio_0.addEventListener("ended", FireUp)
      
      audio_0.currentTime = 0; 
      audio_record.currentTime = 0;
      setButtonState(true)
      
    }  , 300);
    
    setCanRate(true)
  };

  const FireUp = () => {
    audio_0.removeEventListener("ended", FireUp)
    
    
    setTimeout(() => {
      
      audio_record.play();
        
    }, 300) 
  
  }

  const handleCanPlayThrough = () => {
    
    audio_0.removeEventListener('canplaythrough', handleCanPlayThrough);
    console.log('Audio can be played through.');
    
    setTimeout(() => {
      audio_0.play();
      
    }, 300 ) 
    
    
  };



  return (
    <div className="flex flex-col my-auto items-center">
    
      {
        resultPage ? 
        
        <Results 
        results={results.value} 
        nextTab={nextTab} 
        nextExercise={nextExercise} 
        /> 
        
        : 
        <>

          {/* Target word and translation */}
          <div className="w-full flex flex-col py-5 text-center">
            <p 
            className={`m-auto px-5 py-1 mb-2 
                      font-bold text-3xl text-pygblue animate__animated 
                      ${
                        animation
                      }`}> 

              {choice.word} 
            </p>

            <p className="border-t-2 m-auto px-16 pt-1 text-gray-500"> {choice.translation} </p>
        
          </div>

          {/* Play button and microphone */}
          <div className='flex flex-row mx-auto py-7'>

            {/* PLAY BUTTON */}
            <button 
              disabled={!buttonState}
              className={`bg-pygblue rounded-3xl p-8 border-b-4 shadow-md mr-5
                ${!buttonState
                  ? "opacity-40 cursor-default border-transparent" 
                  : "hover:bg-blue-500  opacity-100 border-blue-700 active:translate-y-1 active:ease-in-out active:shadow-xl "}
                `}
              
              onClick={() => {Playit_0(); setButtonState(false); setCanRate(false); setCanCompare(false)}}
            >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.00 32.00" fill="white"
                  className={`w-12 h-12 `}
                  >
                    <path d="M16,6v20c0,1.1-0.772,1.537-1.715,0.971l-6.57-3.942C6.772,22.463,5.1,22,4,22H3c-1.1,0-2-0.9-2-2 v-8c0-1.1,0.9-2,2-2h1c1.1,0,2.772-0.463,3.715-1.029l6.57-3.942C15.228,4.463,16,4.9,16,6z M26.606,5.394 c-0.781-0.781-2.047-0.781-2.828,0s-0.781,2.047,0,2.828C25.855,10.3,27,13.062,27,16s-1.145,5.7-3.222,7.778 c-0.781,0.781-0.781,2.047,0,2.828c0.391,0.391,0.902,0.586,1.414,0.586s1.023-0.195,1.414-0.586C29.439,23.773,31,20.007,31,16 S29.439,8.227,26.606,5.394z M22.363,9.636c-0.781-0.781-2.047-0.781-2.828,0s-0.781,2.047,0,2.828C20.479,13.409,21,14.664,21,16 s-0.52,2.591-1.464,3.535c-0.781,0.781-0.781,2.047,0,2.828c0.391,0.391,0.902,0.586,1.414,0.586s1.023-0.195,1.414-0.586 C24.064,20.664,25,18.404,25,16S24.063,11.336,22.363,9.636z"></path>
                </svg>
            </button>

           
            <div className="border-2 rounded-3xl flex flex-col w-28 px-2 items-center justify-center">

              {/* Microphone  */}
              <div className={`flex flex-col items-center justify-center rounded-3xl 
                ${
                  buttonState ?
                  "opacity-40"
                  : "opacity-100"
                }`}>

                {/* Circle w microphone icon */}
                <div className={`relative  rounded-full flex justify-center items-center z-10 transition duration-500 
                  ${
                    recordingStatus === 'recording' ? 
                    "bg-red-500" 
                    : ""
                  }`}>

                  <div className={`absolute w-12 h-12 rounded-full opacity-20 z-[-1]
                    ${
                      recordingStatus === 'recording' ? 
                      "bg-gray-400 animate-record" 
                      : ""
                    }`}>
                  
                  </div>

                  <MdMicNone size='50px' color={`
                    ${recordingStatus === 'recording'? 
                      'white'
                      : 'black'
                    }`}/>

                </div>
              
              </div>

              {/* Bar */}
              <div className="flex flex-row justify-start h-2 mt-3 w-full rounded-full bg-gray-400">
                  <div 
                  className={` bg-pygblue rounded-full transition-width ease-linear opacity-50
                    ${
                      barWidth
                    }`}
                  style={{transition: transitionDuration}}
                  >
                  </div>
              </div>

            </div>
              
          </div>


          {/* Compare component */}
          <div className="w-full md:w-auto">

            <div className="flex flex-col mx-auto my-3 w-2/3 md:w-auto ">
              <button 
              disabled={!canCompare}  
              className={`bg-pygblue rounded-xl py-3 px-5 border-b-4 shadow-md text-white font-serif:Times text-xl font-semibold   
                ${
                  !canCompare
                  ? "opacity-40 cursor-default border-transparent"
                  : "bg-pygblue hover:bg-blue-500 opacity-100 border-blue-700 active:translate-y-1 active:ease-in-out active:shadow-xl "
              }`}
              onClick={() => {Play_record()}}>
                
                  <p className="text-center">Compare</p>
                  
                </button>
            </div>


            {/* Rating component */}
            <div 
            className={`m-auto items-center rounded-xl bg-pygblue w-2/3 md:w-auto
              py-5 md:py-2 px-4 mb-10
              animate__animated animate__faster
              ${ canRate ? 
              " opacity-100 animate__zoomIn" 
              : "opacity-0 animate__zoomOut" 
              }`}>


              <div className={`flex flex-row justify-center`}>
                {[...Array(totalStars)].map((star, index) => {
                  const currentRating = index + 1;
                  
                  return (
                    
                      <label key={index}>
                        <input
                          className="hidden"
                          type="radio"
                          name="rating"
                          value={currentRating}
                          onClick={() => {setRating(currentRating); PlayNext();}}
                        />
                        <span
                          className="cursor-pointer text-4xl md:text-3xl md:m-1 m-3"
                          style={{
                            color:
                              currentRating <= (hover || rating) ? "#ffc107" : "#e5e5e5"
                          }}
                          onMouseEnter={() => setHover(currentRating)}
                          onMouseLeave={() => setHover(null)}
                        >
                          &#9733;
                        </span>
                      </label>

                      
                  );
                })}
              </div>

              {/* <p className=" text-center text-white">Rate your attempt.</p> */}
            </div>

          </div>
        </>
      }
     
    </div>
    
  );
};
export default DisplayRecorder;