import { useState } from "react";

const Selector =  ({tabsData, clickHandler }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
   
    <div className="flex flex-row gap-x-2">
    
    {tabsData.map((tab, idx) => {
      return (
          <button 
            key={idx}
            className={`flex-grow border-t-2 border-l-2 border-r-2 py-2 border-gray-300 
                        rounder-xl text-left 
                        pr-1 text-sm hover:bg-gray-100 transition-colors duration-300 
                        ${
                          idx === activeTabIndex
                          ? "text-blue-700 font-semibold"
                          : "shadow-sm border-dotted hover:border-gray-200 font-normal antialiased"
                        }
                      `}
            onClick={() => {
                              clickHandler(idx)
                              setActiveTabIndex(idx)
                            }
          }>
            {tab.label}
          </button>
      );
      })}
</div>

  );
}
export default Selector;