import { useState, useEffect } from "react";
import Popup from "reactjs-popup"; // npm i reactjs-popup
import 'animate.css'; // npm i animate.css

// Component showing the progress of the exercises
const ProgressBar = ({ nrExercises, results, tabIdx, activeTabIndex }) => {
  const unitWidth =
    100/nrExercises[tabIdx] < Infinity ? `${100/nrExercises[tabIdx]}%` : '0%'
  
  // console.log("results ProgressBar", results, 'tabIdx', tabIdx, 'activeTabIdx', activeTabIndex)
  const [divs, setDivs] = useState([]);
  
  useEffect(() => {
    // Map through the results and add a div for each result
    if (results && results.length > 0) {
      const initialDivs = results.map((score, idx) => ({
        id: idx,
        width: '0%',
        score: score,
        rounded: 'rounded-none',
        transition: false
      }));

      setDivs(initialDivs);
      
      setTimeout(() => {
        setDivs((prevDivs) =>
          prevDivs.map((div, idx) => ({
            ...div,
            width: `${unitWidth}`,
            rounded: results.length === nrExercises[tabIdx] ? 'rounded-none' : (idx === results.length - 1 ? 'rounded-r-full' : 'rounded-none'),  
            transition: idx === results.length - 1 
          }))
        );
      }, 30);

    }

    else {
      setDivs([]);
    }
  }, [results, unitWidth]);


  return (
    <div className={`w-full h-3 md:h-1.5 flex flex-row justify-start animate__animated
      ${
        tabIdx === activeTabIndex ?
        'bg-blue-100'
        : 'bg-pygblue'
      }
      `}>
        
      {divs.map((div) => (
        <div
          key={div.id}
          className={`h-3 md:h-1.5 border-none
            ${div.transition ? 'transition-all duration-500 ease-linear' : ''}
            ${div.score === 1 ? "bg-green-500" : "bg-red-300"}
            ${div.rounded}
            
          `}
        
          style={{ width: div.width }}
        >
        </div>
      ))}

    </div>
  );
};

const Selector =  ({tabsData, clickHandler, active, exerciseHandler, progress, setProgress, nrExercises }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(active)
  const buttonWidth = `${100/tabsData.length}%`
  const [tabStyles, setTabStyles] = useState([])
  const [unclickable, setUnclickable] = useState([])
 
  useEffect(() => {
    // If finished exercise is finished, then unclickable
    if (progress[activeTabIndex] && progress[activeTabIndex].length === nrExercises[activeTabIndex]) {
      setUnclickable(prevUnclickable => {
        const newUnclickable = [...prevUnclickable];
        newUnclickable[activeTabIndex] = true;
        return newUnclickable;
      });}

    else {
      setUnclickable(prevUnclickable => {
        const newUnclickable = [...prevUnclickable];
        newUnclickable[activeTabIndex] = false;
        return newUnclickable;
      });
    }
  }, [progress]);


  useEffect(() => {
    setActiveTabIndex(active)
  }, [active]);


  // Adds styles to the tabs
  useEffect(() => {
    let newStyles = [];

    tabsData.forEach((tab, idx) => {
      // Base styles for small screens with rounded-full
      let style = [
        "w-full border bg-indigo-10 flex flex-col justify-between items-center overflow-hidden animate__animated",
        "rounded-full" 
      ];

      // Conditional styles for md screens
      if (idx === 0) {
        style.push("md:rounded-l-full md:rounded-none");
      } else if (idx === tabsData.length - 1) {
        style.push("md:rounded-r-full md:ml-1 md:rounded-none");
      } else {
        style.push("md:ml-1 md:rounded-none");
      }

      // Add md:w-[buttonWidth] class
      style.push("md:w-[buttonWidth]");

      newStyles.push(style.join(" "));
    });

    setTabStyles(newStyles);
  }, [tabsData]);



  return (
   
    <div className={`flex flex-row justify-between items-center p-7`}>

      {/*Button to exit*/}
      <div className="flex items-center items-center"> 
            <button type='button' className={`border-none`} onClick={()=> {
              exerciseHandler(null)}}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 scale-100 hover:scale-150 ease-in duration-100" viewBox="0 0 24 24" stroke="gray"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M6 18L18 6M6 6l12 12" /></svg>
            </button>
      </div>

      {/* Tabs */}
      <div className="h-10 mx-5 w-full
                            flex flex-col justify-between items-center
                            md:flex-row
                            ">
                {tabsData? tabsData.map((tab, idx) => 

                  <button 
                    key={idx}
                    className={` 

                      ${tabStyles[idx]}
                      ${
                        idx === activeTabIndex && !unclickable[idx]
                        ? "md:font-bold animate__flipInY animate__fast md:block"
                        : "md:font-medium md:block hidden"
                      }
                      ${
                        unclickable[idx]
                        ? ""
                        : " transition ease-in-out delay-75 hover:scale-105 duration-75"
                      }

                    `}
                    disabled={unclickable[idx] || idx === activeTabIndex}
                    onClick={() =>
                      {
                        if (progress[idx]) {
                          const updatedProgress = [...progress]
                          updatedProgress[idx] = []
                          console.log(updatedProgress, "updatedProgress")
                          setProgress(updatedProgress)
                      }
                        clickHandler(idx)
                        setActiveTabIndex(idx)

                      }
                    }
                  >

                    <h1 className='opacity-50 h-5 text-sm text-gray'>{tab.label}</h1>

                    <ProgressBar 
                      key={idx} 
                      nrExercises={nrExercises}
                      activeTabIndex={activeTabIndex}
                      tabIdx={idx}
                      results={progress[idx] ? progress[idx]: []}
                      />

                  </button>

                ): null}
                
      </div>
    
      {/* Question mark for more info about how to play the game.*/}
      <div>
          <Popup 
            trigger={
              <button className='flex items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 422.686 422.686" className="h-7 w-7 hover:fill-pygblue" fill="gray">
                    <path fill="#C8CED3" d="M211.343,422.686C94.812,422.686,0,327.882,0,211.343C0,94.812,94.812,0,211.343,0 s211.343,94.812,211.343,211.343C422.694,327.882,327.882,422.686,211.343,422.686z M211.343,16.257 c-107.565,0-195.086,87.52-195.086,195.086s87.52,195.086,195.086,195.086c107.574,0,195.086-87.52,195.086-195.086 S318.917,16.257,211.343,16.257z"></path>
                    <path d="M192.85,252.88l-0.569-7.397c-1.707-15.371,3.414-32.149,17.647-49.227 c12.811-15.078,19.923-26.182,19.923-38.985c0-14.51-9.112-24.182-27.044-24.467c-10.242,0-21.622,3.414-28.735,8.819 l-6.828-17.924c9.388-6.828,25.605-11.38,40.692-11.38c32.726,0,47.52,20.2,47.52,41.83c0,19.346-10.811,33.295-24.483,49.511 c-12.51,14.794-17.07,27.312-16.216,41.83l0.284,7.397H192.85V252.88z M186.583,292.718c0-10.526,7.121-17.923,17.078-17.923 c9.966,0,16.785,7.397,16.785,17.924c0,9.957-6.544,17.639-17.07,17.639C193.419,310.349,186.583,302.667,186.583,292.718z"></path> 
                </svg> 
              </button>}
            position={"bottom center"}
            arrow={false}
            >
              <div className="bg-yellow-200 rounded-md p-5 shadow-md">
                <p className='text-sm'>{tabsData[activeTabIndex].instructions}</p>
              </div>
          </Popup>
          
      </div>
      
    </div>

  );
}
export default Selector;
