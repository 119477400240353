
import { Tooltip} from "@material-tailwind/react";
import { useMemo, useRef } from "react";

const DisplayTrackLS = ({ currentTrack }) => {
  const frames = [ { color: "red" },
  { transform: "scale(1.05)", color: "red" },
  { transform: "translateY(-10%)", color: "red" }, 
  { transform: "translateY(-30%)" , color: "red"}, 
  { transform: "scale(1.2)" , color: "red"},
  { transform: "translateY(-10%)" , color: "red"},
  { transform: "translateY(0%)" , color: "red"}, 
  { transform: "scale(1.2)", color: "red" },
  { transform: "scale(1.0)" , color: "inherit"},
  ]
  const inputRef_0 = useRef(null);

  let audio_0 = new Audio(currentTrack.audio);
  const Playit_0 = () => {
    setTimeout(() => {
      inputRef_0.current.animate(  
        frames,{ duration: audio_0.duration*1000 , fill: "forwards", iterations: 1 } 
      );
      audio_0.play();
      }, 2)
    
  };

  
  
  return (
    <div className="w-full flex flex-col m-auto p-2">
      
      <Tooltip className="flex flex-col bg-indigo-300 font-medium text-white rounded-full px-3"
            animate={{
              mount: { scale: 1.1, y: 0 },
              unmount: { scale: 0, y: 25 },}} 
             content={currentTrack.translation}
             > 
         
       
         <button className=" bg-transparent hover:bg-indigo-100  text-sm hover:border rounded-full font-serif:Times font-semibold text-gray-600 hover:text-blue-600" onClick={Playit_0}>
          <p ref={inputRef_0}>{currentTrack.word}</p>

        </button>
        
      </Tooltip>
      
     
    
      
    </div>
  );
};
export default DisplayTrackLS;