import React from 'react'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ExercisePlayerNorwegian from './Exercise/ExercisePlayerNorwegian';
import Tabs from './Player/PlayerTabs';



const CardExerciseNorwegianTestOnly = ({exercise, l2, exerciseHandler}) => {
  const [showInfo, setShowInfo] = useState(false)
  const tabsData = [  
    {label: 'Oslo'},
    {label: 'Elverum'},
    {label: 'Trondheim'},
    {label: 'Malm'},
    {label: 'Stavanger'},
    {label: 'Bergen'},
    {label: 'Sandnessjøen'},
    {label: 'Tromsø'},
];
  const [tabs, setTabs] = useState(tabsData)   
  useEffect(()=>{
    const tabsData = [  
                        {label: 'Oslo'},
                        {label: 'Elverum'},
                        {label: 'Trondheim'},
                        {label: 'Malm'},
                        {label: 'Stavanger'},
                        {label: 'Bergen'},
                        {label: 'Sandnessjøen'},
                        {label: 'Tromsø'},
                    ];
    fetch(`${process.env.REACT_APP_API_URL}/norwegian/exercise/${exercise.id}`)
        .then(response=>response.json())
            .then(json=>{
                let info_json = {"name": json.Exercise_name, "group": json.Group_lesson, "type": json.Type_description}
                
                var dialects = [];
                var tab_dialect = []
                for ( const dia of tabsData) {
                    var dialect = []
                    for (const lang of json.MP_wordpairs) {
                        if (lang[0]['audio_'+dia.label].length > 0 && lang[1]['audio_'+dia.label].length > 0)
                        {

                        dialect.push({"pair_0":{"track_0":lang[0]['audio_'+dia.label], "word": lang[0].word, "translation": lang[0].Translation[lang[0].Translation_description.findIndex(x => x ==='en')] }, "pair_1":{"track_0":lang[1]['audio_'+dia.label],  "word": lang[1].word, "translation": lang[1].Translation[lang[1].Translation_description.findIndex(x => x ==='en')]}})
                        }
                    }

                    if (dialect.length > 4)
                    {
                        tab_dialect.push(dia)
                        dialects.push(dialect)
                    }
                    
                    
                }
                
                setTabs(tab_dialect) 
                setDia(tab_dialect[0])   

                 })
             
},[exercise.id,l2]) 

 
  
  
  
    
  const [dia, setDia] = useState()



    return (

          
                    



             
               
                <div className='flex flex-col'>

                  
                  
                  
                  <div className='mx-auto flex flex-row border-2 border-gray-200 shadow-xl p-5 rounded-md  mt-5 scale-75 md:scale-100'>


                    <div className="mr-3 py-10  flex items-center scale-75 md:scale-100">
                          <Tabs 
                              clickHandler={(tab_index) =>
                                      {
                                          
                                          setDia(tabs[tab_index]);
                                        
                                    
                                      }
                              } 
                              tabsData={tabs} 
                          />
                    </div>


                  
                    <div className='flex flex-col h-full md:h-auto'>
                 
                    <div className={`grow
                  ${showInfo ? 'opacity-0 h-0' : 'opacity-100 h-full'} `}
                >
                    
                    {dia && <ExercisePlayerNorwegian dialect={dia} id={exercise.id} l2={l2} exerciseHandler={exerciseHandler} />}
                    
                      </div>
                   
                </div>
 
                </div></div>

    ) 
}
export default CardExerciseNorwegianTestOnly;
