import React from 'react'
import { Tooltip} from "@material-tailwind/react";
import { useState, useMemo, useRef } from 'react';
import CardSoundGroupButtonMPArray from './Button/CardSoundGroupButtonMPArray';
import CardSoundPositions from './CardSoundPositions';


const CardSoundGroupPosition = ({exercise, l2, include, exHandler, choice}) => {
  /* For English/Norwegian version - with two or three position on circle */

  function handleFocus(timer) {
    let pulse_duration = 1700;
    if (timer){
      pulse_duration = timer*1000+300;
    }
    
    inputRef.current && inputRef.current.animate(  
      [
        { transform: "scale(1.05)" },
        { transform: "translateY(-10%)" }, 
        { transform: "translateY(-30%)" }, 
        { transform: "scale(1.2)" },
        { transform: "translateY(-10%)" },
        { transform: "translateY(0%)" }, 
        { transform: "scale(1.2)" },
        { transform: "scale(1.0)" },
        ],
        { duration: pulse_duration, fill: "forwards", iterations: 1 } 
    
    
    )

  }

   /* define random audio pick and animation */
  const inputRef = useRef(null);
  

  /* design of circles */

  const group = exercise.sound
  const dummy = [...Array(33)]
  
  const positions = [2, 21,31, 16, 0, 28, 4, 12, 24, 29, 7, 19,26, 9, 18 ]

  let colorcodes = [
                      "deepskyblue", "cornflowerblue", "aquamarine", "aliceblue", "azure", "darkturquoise",
                      "cadetblue", "dodgerblue" , "aqua","blueviolet", "fuchsia", "lavender", "lightsteelblue", 
                      "mediumpurple", "yellowgreen"
                    ]
  const dummy_index=14
  const test = exercise.dictionary
  const names = Object.keys(test);
  names.forEach((key, index) => {
    dummy[positions[index]] = test[key]
  });
  dummy[dummy_index] = exercise.sound
  
  const [currentEx, setCurrentEx] = useState();
  
  
 



    return (

          currentEx 
          ?  
          <div 
                className="h-auto scale-95 shadow-lg flex flex-col  bg-FarmWhite rounded-xl 
                          transition ease-in-out"
          >
            <button className ="bg-gray-100 hover:bg-gray-200 text-gray-800 font-semibold  
                                text-sm py-2 px-4 border border-gray-400 rounded shadow"  
                    onClick={() => {setCurrentEx(null);} } 
            > 
              Go back
            </button>
        
        
            <CardSoundPositions  include = {true}   
                      exercise = {currentEx} 
                      
                      posExerciseHandler={(state) =>
                        {
                            exHandler(state);
                            
                        }
                        
                      }

            /> 
          </div>          
          
          
          
          :
                    

          <Tooltip 
              className="flex flex-col bg-indigo-300 font-medium text-white rounded-full "
              animate={{
                mount: { scale: 1.0, y: 25 },
                unmount: { scale: 0, y: 25 },}} 
              placement="bottom"
              content={include? "Choose a sound pair" : "Already familiar sounds" }
           
           
           > 
              <div   
                    className="scale-75 2xl:scale-100 
                                grid grid-rows-6 grid-flow-col 
                                w-96 h-96 bg-farmWhite rounded-full 
                                hover:bg-blue-100 shadow-2xl duration-500 font-mono"
              >
                    
                {dummy.map(
                  (el, index)=>{                       
                      return (
                        
                        el ? 
                                
                                index !== dummy_index 

                                ?
                                
                                <div  ref={ el[0].Exercise_name === choice.Sample_name ? inputRef : undefined}   
                                      key={'host'+String(index) + String(el[0].Exercise_name)}  
                                      onClick={ (el.length > 1) ? () => { setCurrentEx(el)} : () => {exHandler(el[0])}} 
                                >
                                  <CardSoundGroupButtonMPArray 
                                        currentEx={el} 
                                        include={include} 
                                        color={String(colorcodes[positions.indexOf(index)])} 
                                  />
                                </div>

                                : 
                                <button key={String(index) + String(el[0].Exercise_name)}  
                                      to={`/targetsound/${l2}/${group.replace('/','_')}`} 
                                      target="_blank" 
                                      rel="noreferrer" 
                                      className={`row-span-2 col-span-2 flex justify-center items-center  shadow-xl 
                                                truncate hover:text-white font-medium hover:border-transparent rounded-full 
                                                text-5xl 
                                                ${ include  ? 
                                                            "bg-amber-300 hover:bg-green-500 text-blue-700 border border-yellow-800" 
                                                            : 
                                                            "bg-gray-300 hover:bg-grey-500 text-grey-700 border border-gray-600"}
                                                `}
                                >
                                  {group}
                                </button>

                            : 
                                <button key={index} 
                                        className="bg-transparent border w-16 h-16  border-transparent hover:border-transparent 
                                                    rounded-full"
                                >
                              
                                </button>
                                
                                )
                                }
                  
                )}
              </div>
                  
                  
            </Tooltip>  
          
                                
    
    ) 
}
export default CardSoundGroupPosition;
