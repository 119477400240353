import {useState, useEffect} from 'react'
import Paginator from '../../../Paginator'
import DisplayTrackMPEnglish from './DisplayTrack/DisplayTrackMPEnglish'

const CardPlayerEnglish = ({id, l2}) => {
    const path = process.env.REACT_APP_API_URL +'/exercise_raw/English/' + id
    
    const [PlayList, setPlayList] = useState([])
    const [info, setInfo] = useState({})
    const [pageMP,setPageMP] = useState(1)
    const NumberPairs = 5
    const [chunkPlayListMP, setChunkPlayListMP]  = useState([])  
    
 

    
    
    useEffect(()=>{
        
        fetch(`${process.env.REACT_APP_API_URL}/english/exercise/${id}`)
          .then(response=>response.json())
                .then(json=>{
                    let info_json = {"name": json.Exercise_name, "group": json.Group_lesson, "type": json.Type_description}
                    setInfo(info_json)
                
                    let products = [];
                    
                    for (const lang of json.MP_wordpairs) {
                        if (lang[0]['audio'].length > 0 && lang[1]['audio'].length > 0)
                        {

                        products.push({"pair_0":{"track_0":lang[0]['audio'], "word": lang[0].word, "translation": lang[0].Translation[lang[0].Translation_description.findIndex(x => x ==='en')] }, "pair_1":{"track_0":lang[1]['audio'],  "word": lang[1].word, "translation": lang[1].Translation[lang[1].Translation_description.findIndex(x => x ==='en')]}})
                        }
                    }

                                            
                   
                    setPlayList(products)
                        

                     })
                 
    },[id,l2]) 

    useEffect(()=> { 
        
        
            if(PlayList.length > NumberPairs) 
            {
                setChunkPlayListMP(PlayList.slice((pageMP-1)*NumberPairs,pageMP*NumberPairs))  
            }
            else 
            {
                setChunkPlayListMP(PlayList)
            }

    

    
    },[pageMP, PlayList])


    
  return (
       


            chunkPlayListMP.length > 0 ? 
                <div className="flex flex-col flex-grow">
                    

                        <div 
                                            className='flex flex-col px-2  bg-FarmWhite rounded-md transition  items-center ease-in-out'

                        >
                                
                              
                                <a  href={path} 
                                    target="_blank" 
                                    rel="noreferrer"
                                >
                                    <div className="pb-2 font-bold text-lg text-blue-600 my-1"
                                    >
                                        {info.name}
                                    </div>
                                </a>  
                                </div>
                                <div className="flex flex-col mx-2 border-dotted border-2 border-indigo-600  
                                    shadow-md  bg-FarmWhite rounded-xl transition 
                                    items-center ease-in-out mt-2">
                            
                            

                                    <div className="w-full">
                                    {(() => {
                                        
                                            return (                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
                                                <div className="bg-FarmWhite rounded-lg transition  items-center  ease-in-out">
                                                    
                                                    <div className="font-semibold text-sm text-center p-2  font-serif:'Times New Roman' text-lg text-FarmNavy  bg-FarmWhite rounded-lg"> 
                                                    Minimal Pairs
                                                    </div>

                                                    {chunkPlayListMP.map(
                                                        (item,i) => {
                                                            return (
                                                                    <div key={item.pair_0.word+i}><DisplayTrackMPEnglish  currentTrack={item} /> </div> 
                                                                
                                                                    )                                                                                                                                                                                                    
                                                        }
                                                    )}
                                                </div>
                                            )
                                    })()}                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
                                </div>

                                <Paginator maxpage={Math.ceil(PlayList.length/NumberPairs)} page={pageMP}
                                            clickHandler={(page) => {
                                                setPageMP(page)
                                            }}
                                />
                
                        </div>
                    </div>     
                
            :
                <div>loading...</div>
  
  
    )
}

export default CardPlayerEnglish;
