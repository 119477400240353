import {useParams} from "react-router-dom"
import Layout from '../components/Layout';
import useSWR from "swr";
import useAuth from "../../Users/hooks/useAuth";
import { useEffect } from "react";

const Review = () => {
    const fetcher = (...args) => fetch(...args).then((res) => res.json());
    const { setAuth } = useAuth();

    
    const checkToken = async () => {
        const token = localStorage.getItem("token");
        if (token) {
            const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/users/me`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              });
            if (response.ok) {
                let userData = await response.json();
                console.log(userData);
                userData["token"] = token;
                setAuth(userData);
            }
        }
    }
    
    useEffect(() => {
        checkToken();
    }, []);

    let {l2, id} = useParams()
    let data_source = ''
    
    
    if (l2 === 'Norwegian') 
    {
      data_source = `${process.env.REACT_APP_API_URL}/norwegian/exercise/${id}`

    }
    else
    {
      data_source = `${process.env.REACT_APP_API_URL}/exercise/${id}?course=${l2}`
    }
    const { data, error } = useSWR(
        data_source,
        fetcher
      ); 
    
    
    /* useEffect(()=>{
                
        fetch(`${BASE_URL}${id}?course=${l2}`)
            .then(response=>response.json())
                .then(json=>{
                    setExercise(json)
                    setIsPending(false)
                })          
       },[id,l2]) */
    if (error) return <div>`failed to fully load exercise, check a missing require field : {process.env.REACT_APP_API_URL}exercise/{id}?course={l2}`</div>;
    if (!data) return <div>loading...</div>;
  return (
        <Layout>
        {/* isPending && <div className="bg-red-500 w-full text-white h-10 text-lg">
            <h2>Loading exercise...</h2>
        </div>*/}


        {data &&<div className="shadow-lg p-5 flex flex-col bg-FarmWhite rounded-lg transition  items-center ease-in-out">
                <div className="font-bold text-xl text-gray-600 my-3">{data.ExerciseName}</div>                      
                <div>Sound group: <span className="font-semibold text-gray-600 text-xl">{data.Group_lesson}</span></div>
                <div>Type: <span className="font-semibold text-gray-600 text-xl">{data.Type_description}</span></div>
                    
                <div className="flex grid grid-cols-1 gap-3 lg:grid-cols-2">    
                        {(() => {
                            if (data.MP_wordpairs.length > 0){
                                return (
                                    <div className="shadow-lg p-10 flex flex-col bg-FarmWhite rounded-lg transition  items-center  ease-in-out">
                                        <div><div className="font-bold text-center text-lg text-FarmNavy"><span className="text-FarmLime"> MP_wordpairs</span></div>

                                            <ol className="list-decimal">
                                                {data.MP_wordpairs.map((item, i) => {
                                                    return <li key={i}>{item[0].word}  <span>&#8212;</span> {item[1].word} </li>;
                                                })}
                                            </ol>
                                        </div>
                                    </div>
                                    
                                )
                            }
                            
                            return null;
                        })()}

                        {data.MP_nonwords ?
                            (() => {
                                
                                if (data.MP_nonwords.length > 0){
                                    return (
                                        <div className="shadow-lg p-10  flex flex-col bg-FarmWhite rounded-lg transition  items-center ease-in-out">
                                            <div><div className="font-bold text-center text-lg text-FarmNavy"><span className="text-FarmLime"> MP_nonwords</span></div>

                                                <ol className="list-decimal">
                                                    {data.MP_nonwords.map((item, i) => {
                                                        return <li key={i}>{item.word}  <span>&#8212;</span> *{item.nonword} </li>;
                                                    })}
                                                </ol>
                                            </div>
                                        </div>
                                        
                                    )
                                }
                                
                                return null;

                                
                            })()    
                        :
                            <div></div>
                        }
                        
                    
            
                    

                </div>



                    
        </div>
        }        


  
    </Layout>
   
    )
}


export default Review