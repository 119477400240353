import { useEffect, useState } from "react";
import useAuth from "./hooks/useAuth";
import ClassInterface from "./ClassInterface";

const SelectClass = ({successHandler}) => {
    const {auth, setAuth} = useAuth();
    const [classes, setClasses] = useState([]);
    const [course, setCourse] = useState("");

    const getClasses = async () => {
        const token = localStorage.getItem("token");
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/users/classes`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });
        if (response.ok) {
            const json = await response.json();
            setClasses(json);
        }
        if (response.status === 401) {
            localStorage.removeItem("token");
            setAuth({});
        }
    }

    useEffect(() => {getClasses()}, [course]);

    if (course !== "") {
        return <ClassInterface successHandler={(state) => setCourse(state)} course={course}/>
    }

    return <div>
        <button className="btn btn-circle btn-outline"
            onClick={()=> {successHandler(false)}}      
        ><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" /></svg>
        </button>
        <div className="flex flex-col">
            <b>Select a class</b>
            <div className="flex flex-col border border-4">
                {classes.map(course => {
                    return <button onClick={() => setCourse(course)}>{course}</button>
                })}
            </div>
        </div>
    </div>
}

export default SelectClass;