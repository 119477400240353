const PrivacyPolicy = ()=>{

return (

<div className="flex flex-col m-10 p-10 border-2 border-gray-200 shadow-md">
			<div>
				<p class="text-2xl font-bold text-blue-600 p-10 border-2 border-blue-300 rounded-xl">
					The CALST platform (Computer-Assisted Listening and Speaking Tutor) is an online resource for second and foreign language pronunciation training developed by the Norwegian University of Science and Technology (NTNU). This privacy policy will explain how we use the personal data we collect from you when you use our website.
				</p>

				<div className="text-2xl mt-10 font-semibold text-blue-700">Topics:</div>
				<ul className="list-outside list-disc p-10">

					<li>
						<p class="text-xl">
							<p className="text-xl font-bold text-blue-700">What data do we collect?</p>
							<div className="border-2 border-gray-400 rounded-2xl p-5">
							<p>Three types of data are collected in CALST:</p>
							<ul className="list-decimal list-inside">
								<li>
									User tailoring data: The user’s first and last name, e-mail address, a self-selected password, native language (L1) and target language (L2) are obligatory for registered users (but see below). First and last name can be fictitious and need not be the user’s real name.
									These data are used to tailor the exercise selection to the user’s needs and to present exercise results to the user.
								</li>
								<li>
									Language use data: Additionally, some optional data are requested: year of birth, gender, date of arrival in L2 country, frequency of L2 use, other languages the user speaks, start of language learning, and frequency of language use. The registration form clearly states which information is obligatory and which is optional.
									These data are anonymized and used for research purposes.

								</li>
								<li>
									Language performance data: In addition to the above personal data, performance data collected. All exercise in CALST use words or short phrases. The performance data are the results from exercises in CALST. They consist of responses in listening exercises (multiple choice identification of an audio cue), pronunciation exercises (cue word spoken by the user) and spelling exercises (written form of the audio cue typed by the user). Of these, only the user’s voice (spoken words) can identify him/her, although this probability is very low. The user can skip pronunciation exercises by denying access the microphone. None of the data are sensitive in the sense of data protection.
									Performance data are collected both for feedback to the user about his/her achievements and for research purposes. Learners who want to use CALST completely anonymously can do so by selecting guest user from the login screen. In this case, no data are collected. You have access to the same pronunciation training exercises and language content in CALST.
								</li>
							</ul>
							<p class="text-md text-blue-400">
							All personal data are stored separately from the user’s performance data, which are pseudonymised. Any data will only be available to researchers. Your identity will never be disclosed. 
							Learners who want to use CALST completely anonymously can do so by selecting guest user from the login screen. In this case, no data are collected. You have access to the same pronunciation training exercises and language content in CALST.
						
						</p>
							</div>
						</p>
                        
						
					
					</li>
					<li>
						
							<p className="text-xl font-bold text-blue-700">
								How do we collect your data?
							</p>
							<div className="border-2 border-gray-400 rounded-2xl p-5">
							<p className="text-xl ">You directly provide CALST with any of the data we collect. We collect data and process data when you:
							<ul className="list-decimal list-inside">
								<li>register when logging into CALST for the first time. In the registration form you supply user tailoring data and language use data – unless you have selected guest login (see above).</li>
								<li>carry out exercises. In this case, language performance data are automatically collected – unless you have selected guest login.</li>
							</ul>
						</p>
						</div>
					</li>
					<li>
						
							<p className="text-xl font-bold text-blue-700">
								How will we use your data?
							</p>
							<div className="border-2 border-gray-400 rounded-2xl p-5">
							<p className="text-xl">Your data are used for research purposes and will lead to further improvements in CALST. By contributing your data, you help to answer many open questions in second and foreign language acquisition research.
                            
							</p>
							</div>
					</li>
					<li>
                        <p className="text-xl font-bold text-blue-700">
							
								How do we store your data?
						</p> 
						<div className="border-2 border-gray-400 rounded-2xl p-5">
							<p className="text-xl">Your name and e-mail address are stored separately from all other data. They are connected through a unique identifier which is used solely for the purpose of feedback to you about your achievements, and to remember which exercises you have finished.
						</p><p class="text-xl">
							CALST securely stores your data on private servers at the Norwegian University of Science and Technology (NTNU).
						</p><p class="text-xl">
							NTNU will keep your data until you delete it. If you delete your data, your user account and all other data will be immediately removed from our servers. Backups will also be deleted after the backup lifetime of 3 months. In case of major failure and data loss, we will notify you to revisit CALST and delete your account if necessary.
						</p>
						</div>
					</li>
					<li>
                    <p className="text-xl font-bold text-blue-700">
							
								Marketing
                    </p>	
					<div className="border-2 border-gray-400 rounded-2xl p-5">
							<p className="text-xl">
							Your data are not used for marketing purposes.
						</p>
						</div>
					</li>
					<li>
                    <p className="text-xl font-bold text-blue-700">
							
								What are your data protection rights?
							</p>
							<div className="border-2 border-gray-400 rounded-2xl p-5">
                            <p className="text-xl">
							You can at any time revoke your consent to use your data. You can choose one of two options: you either delete the data that can identify you (first and last name and e-mail address), leaving your pseudonymized data available for research purposes, or you erase all your data (user tailoring data, language use data and language performance data, see above). This can be done by selecting the Revoke consent option in the CALST settings.
						</p><p className="text-xl">
							CALST would like to make sure you are fully aware of your data protection rights. Every user is entitled to the following:
							<ul class="list-decimal list-inside">
								<li>The right to access: You have the right to request CALST for copies of your personal data.</li>
								<li>The right to rectification: You have the right to request that CALST correct or complete any personal information you believe is inaccurate.</li>
								<li>The right to erasure: You have the right to request that CALST erase your personal data.</li>

							</ul>
						</p><p className="text-xl text-blue-400">
							If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email: calst@hf.ntnu.no. Please write “Data protection rights” in the subject line of the e-mail.
						</p>
						</div>
					</li>
					<li>
                    <p className="text-xl font-bold text-blue-700">
							
								What are cookies?</p>
								<div className="border-2 border-gray-400 rounded-2xl p-5">
							<p className="text-xl">
							Cookies are small chunks of data placed on your browser to provide a better user experience. We do not collect any information from you through cookies or similar technology. CALST cookies contain the ID of your native language and ID of the language you are learning now. These cookies expire as soon as you logout from CALST.
						</p>
						</div>
					</li>
					<li>
                    <p className="text-xl font-bold text-blue-700">
							
								How do we use cookies?
							</p>
							<div className="border-2 border-gray-400 rounded-2xl p-5">
							<p className="text-xl">
							CALST uses cookies in a range of ways to improve your experience on our website, including:
							<ul className="list-decimal list-inside">
								<li>
									keeping you signed in
								</li>
								<li>running exercises based on your L1 and L2 choices</li>
							</ul>

						</p>
						</div>
					</li>
					<li>
                    <p className="text-xl font-bold text-blue-700">
							
								How to manage your cookies
					</p>
					<div className="border-2 border-gray-400 rounded-2xl p-5">
							<p className="text-xl">
							You can set your browser not to accept cookies, and browser help page tells you how to remove cookies from your browser. However, in a few cases, some of our website features may not function as a result.
						</p>
					</div>
					</li>
					
					<li>
                    <p className="text-xl font-bold text-blue-700">
							
								Privacy policies of other websites
							</p>
							<div className="border-2 border-gray-400 rounded-2xl p-5">
                            <p className="text-xl">
							The CALST website contains links to other websites. Our privacy policy applies only to our website, so if you click on a link to another website, you should read their privacy policy.
						</p>
						</div>
					</li>
					<li>
                    <p className="text-xl font-bold text-blue-700">
							
								Changes to our privacy policy
							</p>
							<div className="border-2 border-gray-400 rounded-2xl p-5">
                            <p className="text-xl">
							CALST keeps its privacy policy under regular review and places any updates on this web page. This privacy policy was last updated on 1 March 2020.
						</p>
						</div>
					</li>
					<li>
                    <p className="text-xl font-bold text-blue-700">
							
								How to contact us
							</p>
							<div className="border-2 border-gray-400 rounded-2xl p-5">
							<p className="text-xl">
							If you have any questions about CALST’s privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us.
							You can contact us by sending an e-mail to <p className="text-xl text-blue-600">calst@hf.ntnu.no</p>.

						</p>
						</div>
					</li>
					<li>
                    <p className="text-xl font-bold text-blue-700">
							How to contact the appropriate authorities</p>

							<div className="border-2 border-gray-400 rounded-2xl p-5">
                        <p className="text-xl">
							Should you wish to report a complaint or if you feel that we have not addressed your concern in a satisfactory manner, you may contact the data protection officer Thomas Helgesen at the Norwegian University of Science and Technology (NTNU).
						</p><p className="text-xl">
							Email:	<p className="text-xl text-blue-600">thomas.helgesen@ntnu.no</p>
						</p><p className="text-xl">
							Address: Sluppenveien 12B/C, Møllenberg 4th floor, Trondheim, Norway

						</p>
						</div>
					</li>

				</ul>

			</div>
		</div>



)




}
export default PrivacyPolicy;