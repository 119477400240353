import React from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import CardPlayer from './Player/CardPlayer';
import Selector from './Selector';
import ExercisePlayer from './Exercise/ExercisePlayer';


const CardExercise = ({exercise, l2, exerciseHandler}) => {
 
  const tabs = [  
    {label: 'Training'},
    {label: 'Test yourself'},
    
  ];
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  
    return (

            <div className='flex flex-col'>
              <div className='p-10'>
                <button className="btn btn-circle btn-outline"
                  onClick={()=> {exerciseHandler(null)}}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" /></svg>
                </button>
                </div>
              <div className="w-full md:w-1/2 xl:w-1/3 mx-auto flex flex-col border-2 rounded-md"> 
                <div className='flex flex-row'>
                 
                  
                  <div className='grow border-b-2'>

                  </div>
                  <Link   to={`/player/${l2}/${exercise.id}`} 
                          target="_blank" 
                          rel="noreferrer"
                  >
                    <button className ="bg-white hover:bg-gray-100 text-gray-800 font-semibold 
                                        text-sm py-2 px-2 border border-gray-400 rounded shadow"
                    >
                      A new tab
                    </button>
                  </Link>
                </div>
              
                {(tabs[activeTabIndex].label === 'Training')  &&
                  <CardPlayer id={exercise.id} l2={l2}  />  }

                {(tabs[activeTabIndex].label === 'Test yourself')  &&
                  <ExercisePlayer id={exercise.id} l2={l2} />
                }

                <Selector 
                    clickHandler={(tab_index) =>
                            {
                                setActiveTabIndex(tab_index)
                            }
                    } 
                    tabsData={tabs} 
                />      
              </div>
            </div>                    
    
    ) 
}
export default CardExercise;