import React from 'react'
import { Link } from "react-router-dom"
import useSWR from "swr";

const Card = ({exercise, l2}) => {
    let {Lesson, Level, Group_lesson, Exercise_name, id, MP_wordpair, MP_nonword} = exercise    
    console.log(Lesson, Level, Group_lesson, Exercise_name, id, MP_wordpair, MP_nonword, "here")
    const fetcher = (...args) => fetch(...args).then((res) => res.json());
    let data_source = ''
    
    if (l2 === 'Norwegian') 
    {
      data_source = `${process.env.REACT_APP_API_URL}/norwegian/exercise/${id}`

    }
    else
    {
      data_source = `${process.env.REACT_APP_API_URL}/exercise/${id}?course=${l2}`
    }
    console.log(data_source, "data_source", )
    const { data, error } = useSWR(
      data_source,
      fetcher
    ); 
    if (error) {
      console.log(error)
      return (
        <Link to={`${process.env.REACT_APP_API_URL}/${l2}/exercise_raw/${id}`} target="_blank">
    
    
          <div className="shadow-lg p-5 flex flex-col bg-FarmWhite rounded-lg transition ease-in-out hover:scale-105 duration-300 font-mono">
          <div className="font-bold text-center text-lg text-FarmNavy"><span className="text-FarmLime"> {Exercise_name}</span></div>
              
              <p className="text-red-600">failed to load exercise, look for missing require field :</p>
              <p className="text-red-600"> exercise id: {id}</p>
              <div>Group: {Group_lesson}</div>
              <div>Level: {Level}</div>
              <div>Lesson: {Lesson}</div>
              
              {(() => {
                    if (MP_wordpair){
                        return (
                          <div>{MP_wordpair[0]["word"]}  <span>&#8212;</span> {MP_wordpair[1]["word"]} </div>
                        )
                    }
                    
                    return null;
                })()}
              {(() => {
                    if (MP_nonword){
                        return (
                          <div>{MP_nonword["word"]}  <span>&#8212;</span> *{MP_nonword["nonword"]} </div>
                        )
                    }
                    
                    return null;
                })()}

              
          </div>
          </Link>


      
      )
    }
    if (!data) return <div>loading...</div>;

    return (
    <Link to={`/review/${l2}/${id}`} target="_blank">
    
    
    <div className="shadow-lg p-5 flex flex-col bg-FarmWhite rounded-lg transition ease-in-out hover:scale-105 duration-300 font-mono">
    <div className="font-bold text-center text-lg text-FarmNavy"><span className="text-FarmLime"> {Exercise_name}</span></div>
        <div>Group: {Group_lesson}</div>
        <div>Level: {Level}</div>
        <div>Lesson: {Lesson}</div>
        
        {(() => {
              if (MP_wordpair){
                  return (
                    <div>{MP_wordpair[0]["word"]}  <span>&#8212;</span> {MP_wordpair[1]["word"]} </div>
                  )
              }
              
              return null;
          })()}
        {(() => {
              if (MP_nonword){
                  return (
                    <div>{MP_nonword["word"]}  <span>&#8212;</span> *{MP_nonword["nonword"]} </div>
                  )
              }
              
              return null;
          })()}

        
    </div>
    </Link>
  )
}
export default Card