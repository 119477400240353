import DisplayRecorder from "../Display/DisplayRecorder";
import { useEffect, useState } from "react";



const ListenAndSpeak = ({inputtracks}) => {
    const [isgranted, setIsGranted] = useState(false)
    const [media, setMedia] = useState()
    const getMicrophonePermission = async () => {
        if ("MediaRecorder" in window) {
            try {
                const streamData = await navigator.mediaDevices.getUserMedia({
                    audio: true,
                    video: false,
                });
                setIsGranted(true);
                setMedia(streamData)
                
                
            } catch (err) {
                alert(err.message);
            }
        } else {
            alert("The MediaRecorder API is not supported in your browser.");
        }
    };
   

    useEffect(() => {
        if (navigator) {
        navigator.permissions
            .query({ name: "microphone" })
            .then(async function (result) {
            if (result.state === "granted") {

                //If granted then you can directly call your function here
            
                if ("MediaRecorder" in window) {
                    try {
                        const streamData = await navigator.mediaDevices.getUserMedia({
                            audio: true,
                            video: false,
                        });
                        setIsGranted(true);
                        setMedia(streamData)
                        
                        
                    } catch (err) {
                        alert(err.message);
                    }
                } else {
                    alert("The MediaRecorder API is not supported in your browser.");
                }
            } else if (result.state === "prompt") {
                //If prompt then the user will be asked to give permission
                console.log(prompt)
               
            } else if (result.state === "denied") {
                //If denied then you have to show instructions to enable location
            }
            });
        } else {
        console.log("navigator is not supported by this browser.");
        }
    }, []);

    
    
    return (
        
        <>
        { isgranted ?
        <DisplayRecorder  stream={media} tracks={inputtracks} />
        :
        <div className="flex flex-col m-auto">        <p>Enable your micropohone for this exercise</p>
        <button className="border-gray-300 border-2 m-auto px-1" onClick={getMicrophonePermission} type="button">
        Get Microphone
         </button>
         </div>

        }
        </>
    )

}

export default ListenAndSpeak;