import ThreeStressOne from "./ThreeStressOne";
import ThreeStressThree from "./ThreeStressThree";
import ThreeStressTwo from "./ThreeStressTwo";

const ThreeStressFull = () => {

return (

    <div className='flex flex-col md:flex-row'>
        <ThreeStressOne />
        <div className='flex h-16 items-center justify-center'><p className='text-center font-bold text-xl px-3'>or</p></div>
        <ThreeStressTwo />
        <div className='flex h-16 items-center justify-center'><p className='text-center font-bold text-xl px-3'>or</p></div>
        <ThreeStressThree />
    </div>   
    
    )
}
export default ThreeStressFull;